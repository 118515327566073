import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material/Alert'

const initialState: SnackbarState = {
  message: '',
  variant: 'info',
  open: false
}

interface SnackbarState {
  message: string
  variant: AlertColor
  open: boolean
}

type UpdateSnackbarPayload = {
  message: string
  variant: AlertColor
}

export const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    updateSnackbar: (state, action: PayloadAction<UpdateSnackbarPayload>) => {
      const payload = action.payload
      state.message = payload.message
      state.variant = payload.variant
      state.open = true
    },
    cleanSnackbar: (state) => {
      state.open = false
    }
  }
})

export const snackbarSelector = createSelector(
  (state: any) => state.snackbar as SnackbarState,
  (state) => {
    return {
      message: state.message,
      variant: state.variant as AlertColor,
      open: state.open
    }
  })

export const { updateSnackbar, cleanSnackbar } = snackbar.actions
export default snackbar.reducer