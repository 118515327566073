import React, { FunctionComponent } from 'react'
import { Grid } from '@mui/material'
import CardSummary from '../CardSummary'
import Typography from '@mui/material/Typography'
import StateButton from '../StateButton'
import { useQuery } from 'react-query'
import { getCard, getPic } from '../../usecases/card'
import { updateSnackbar } from '../../../../redux/snackbarStore'
import useAppDispatch from '../../../../hooks/useAppDispatch'

const CardData: FunctionComponent<{
  pan: string
}> = ({ pan }) => {

  const snackbarDispatch = useAppDispatch()
  const { data } = useQuery(['card', pan], () => getCard(pan), {
    onError: _ => {
      snackbarDispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    }
  })

  const {data: pic} = useQuery(['pic', pan], () => getPic(pan), {
    onError: _ => {
      snackbarDispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    }
  })

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item> <Typography variant={'h4'}>
          {`Card ${pan === undefined || pan === '' ? '-' : pan}`}
        </Typography></Grid>
        <Grid item>
          <StateButton pan={pan} state={data?.state} isActive={data?.isActive}/>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <CardSummary cardState={data} pic={pic} />
      </Grid>
    </Grid>)

}

export default CardData