import { styled } from '@mui/material/styles'
import { FunctionComponent } from 'react'

const DeviceOnlineStatus = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{
  isActive: boolean
}>(({ isActive }) => ({
  borderRadius: '5px',
  display: 'inline-block',
  padding: '0px 10px',
  fontWeight: 600,
  textTransform: 'uppercase',
  fontSize: '0.88rem',
  ...(!isActive && {
    color: '#D3000C',
    border: '1px solid #D3000C'
  }),
  ...(isActive && {
    color: '#019938',
    border: '1px solid #019938'
  })
}))

export const DeviceOnlineMobileStatus: FunctionComponent<{
  isActive: boolean
}> = ({ isActive }) => {

  return (<DeviceOnlineStatus isActive={isActive}>{isActive ? 'VALIDO' : 'NON VALIDO'}</DeviceOnlineStatus>)
}