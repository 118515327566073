import React, { FunctionComponent, useCallback, useState } from 'react'
import { CreateTask } from '../models/CreateTask'
import useAppDispatch from '../../../hooks/useAppDispatch'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField, Typography
} from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { updateSnackbar } from '../../../redux/snackbarStore'
import Customer from '../models/Customer'
import { ActionButton } from '../../../components/Table/actions/ActionButton'
import AddIcon from '@mui/icons-material/Add'
import { StepLabelProps } from '@mui/material/StepLabel/StepLabel'
import { AxiosError } from 'axios'
import { createTask } from '../usecases/tasks'
import { getCustomers } from '../usecases/customer'

interface DialogProps {
  open: boolean
  handleClose: () => void
  onRefresh: () => void
}

const steps = ['Scegli la ragione sociale e la frequenza', 'Scegli tipo di file e inoltro','Inserisci una descrizione', 'Riepilogo']

const initialState: CreateTask = {
  customerCode: 0,
  frequency: 'day',
  sending: {
    isEnabled: false,
    optEmail: ''
  },
  returnType: 0
}

const MyStepLabel = styled(StepLabel)<StepLabelProps>(({ theme: { palette } }) => ({
  '&.MuiStepLabel-root': {
    '.MuiStepIcon-root.Mui-active': {
      color: palette.secondary.light
    },
    '.MuiStepIcon-root.Mui-completed': {
      color: palette.secondary.light
    },
    '.MuiStepLabel-iconContainer.Mui-active .MuiStepIcon-text': {
      color: '#ffffff',
      fill: 'rgba(255, 255, 255, 0.87)',
      fontWeight: 'bold'
    }
  }
}))

const CreateOrderDialog: FunctionComponent<DialogProps> = (
  {
    open,
    handleClose,
    onRefresh
  }) => {
  const [state, setState] = useState<CreateTask>(initialState)
  const [activeStep, setActiveStep] = useState(0)
  const [openSelect, setOpenSelect] = useState(false)
  const [isChoiceDid, setIsChoiceDid] = useState(false)
  const [customer, setCustomer] = useState<Customer | null>(null)
  const dispatch = useAppDispatch()

  const {
    data: customers
  } = useQuery('liteOrganizationCustomers', () => getCustomers(), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  const createMutation = useMutation<void, AxiosError, CreateTask>(createTask, {
    onError: () => {
      dispatch(updateSnackbar({
        variant: 'error',
        message: 'Errore di rete'
      }))
    },
    onSuccess: async () => {
      closeAndClean()
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Regola creata`
      }))
      onRefresh()
    }
  })

  const handleNext = () => {
    if (isChoiceDid) {
      if (activeStep === steps.length - 1) {
        createMutation.mutate(state)
        return
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const closeAndClean = () => {
    setState(initialState)
    setActiveStep(0)
    setCustomer(null)
    handleClose()
    return
  }

  const handleSelectClose = () => {
    setOpenSelect(false)
  }

  const handleSelectOpen = () => {
    setOpenSelect(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeAndClean}
        sx={{
          overflowY: 'visible'
        }}
        maxWidth='xs' fullWidth={true}
      >
        <DialogTitle>Crea regola</DialogTitle>
        <DialogContent sx={{
          overflowY: 'visible'
        }}>
          <div style={{ width: '100%' }}>
            <Stepper activeStep={activeStep} orientation='vertical'>
              <Step key={steps[0]}>
                <MyStepLabel>{steps[0]}</MyStepLabel>
                <StepContent>
                  <Autocomplete
                    options={customers ? customers as Customer[] : []}
                    getOptionLabel={option => option.businessName}
                    sx={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}
                    onChange={(event, value) => {
                      if (value !== null && value !== undefined) {
                        setState({
                          ...state,
                          customerCode: value.customerCode
                        })
                        setIsChoiceDid(true)
                        setCustomer(value)
                      }
                    }}
                    value={customer}
                    renderInput={(params) => <TextField {...params} color={'secondary'} label={'Ragione sociale'}
                                                        variant='outlined' />}
                  />
                  <FormControl color={'secondary'} variant='outlined' fullWidth sx={{
                    marginTop: 2
                  }}>
                    <InputLabel>Frequenza</InputLabel>
                    <Select
                      open={openSelect}
                      onClose={handleSelectClose}
                      onOpen={handleSelectOpen}
                      value={state.frequency}
                      label='Frequenza'
                      onChange={event => {
                        setState({
                          ...state,
                          frequency: event.target.value as string
                        })
                      }}
                    >
                      <MenuItem value={'day'}>Giornaliero</MenuItem>
                      <MenuItem value={'week'}>Settimanale</MenuItem>
                      <MenuItem value={'month'}>Mensile</MenuItem>
                    </Select>
                  </FormControl>
                </StepContent>
              </Step>
              <Step key={steps[1]}>
                <MyStepLabel>{steps[1]}</MyStepLabel>
                <StepContent sx={{ marginTop: 1 }}>
                  <FormControl color={'secondary'} variant='outlined' fullWidth sx={({ spacing }) => ({})}>
                    <InputLabel>Tipo di ritorno</InputLabel>
                    <Select
                      open={openSelect}
                      onClose={handleSelectClose}
                      onOpen={handleSelectOpen}
                      value={state.returnType}
                      label='Tipo di ritorno'
                      onChange={event => {
                        setState({
                          ...state,
                          returnType: event.target.value as number
                        })
                      }}
                    >
                      <MenuItem value={0}>Tutti i file</MenuItem>
                      <MenuItem value={1}>PDF</MenuItem>
                      <MenuItem value={2}>XLSX</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel sx={{ marginTop: 2 }} control={
                    <Switch checked={state.sending.isEnabled}
                            color={'secondary'} onChange={(event, checked) => {
                      const newState = state
                      newState.sending.isEnabled = checked
                      if (!checked) {
                        newState.sending.optEmail = ''
                      }
                      setState({
                        ...newState
                      })
                    }} />} label='Abilità invio email' />
                  <TextField sx={{ marginTop: 1 }}
                             color={'secondary'}
                             value={state.sending.optEmail}
                             label='Email'
                             onChange={event => {
                               const newState = state
                               newState.sending.optEmail = event.target.value as string
                               setState({
                                 ...newState
                               })
                             }}
                             disabled={!state.sending.isEnabled}
                             helperText={'nel caso sia omessa, verrà considerata quella di default'}
                             variant='outlined' />
                </StepContent>
              </Step>
              <Step key={steps[2]}>
                <MyStepLabel optional={<Typography variant="caption">Facoltativo</Typography>}>{steps[2]}</MyStepLabel>
                <StepContent sx={{ marginTop: 1 }}>
                  <TextField sx={{ marginTop: 1 }}
                             color={'secondary'}
                             value={state.description}
                             label='Descrizione'
                             onChange={event => {
                               if (!event.target.value || event.target.value === ''){
                                 setState({
                                   ...state,
                                   description: undefined
                                 })
                                 return
                               }
                               setState({
                                 ...state,
                                 description: event.target.value as string
                               })
                             }}
                             variant='outlined' />
                </StepContent>
              </Step>
              <Step key={steps[3]}>
                <MyStepLabel>{steps[3]}</MyStepLabel>
                <StepContent>
                  <Table size='small'>
                    <TableBody>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Ragione sociale'}
                        </TableCell>
                        <TableCell
                          align='right'>{customer?.businessName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Frequenza'}
                        </TableCell>
                        <TableCell
                          align='right'>{toFrequencyITValue(state.frequency)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Tipo di file'}
                        </TableCell>
                        <TableCell
                          align='right'>{toReturnTypeITValue(state.returnType)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Invio email'}
                        </TableCell>
                        <TableCell
                          align='right'>{ state.sending.isEnabled === true ? 'si' : 'no'}</TableCell>
                      </TableRow>
                      { state.sending.optEmail.length > 0 ?  <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Indirizzo email'}
                        </TableCell>
                        <TableCell
                          align='right'>{ state.sending.optEmail}</TableCell>
                      </TableRow>: null}
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Descrizione'}
                        </TableCell>
                        <TableCell
                          align='right'>{state.description}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color={'inherit'} onClick={() => {
            if (activeStep === 0) {
              closeAndClean()
            }
            setActiveStep((prevActiveStep) => prevActiveStep -1)
          }}>
            {activeStep === 0 ? 'Annulla' : 'Indietro'}
          </Button>
          <Button variant='contained' onClick={handleNext} color='secondary' disabled={!isChoiceDid}>
            {activeStep === steps.length - 1 ? 'Crea' : 'Avanti'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const toFrequencyITValue = (frequency: string) => {
    let value = "giornaliero"
    switch (frequency as string) {
      case "week":
        value = "settimanale"
        break
      case "month":
        value = "mensile"
        break
    }
    return value
}

const toReturnTypeITValue = (returnType: number) => {
  let value = "PDF/XLSX"
  switch (returnType) {
    case 1:
      value = "PDF"
      break
    case 2:
      value = "XLSX"
      break
  }
  return value
}

export type CreateOrderButtonProps = {
  onRefresh: () => void
}

export const CreateTaskButton: FunctionComponent<CreateOrderButtonProps> = (
  {
    onRefresh
  }) => {
  const [open, setOpen] = useState(false)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const handleClick = useCallback(
    () => {
      setOpen(true)
    },
    [setOpen]
  )
  return (<>
    <ActionButton
      icon={<AddIcon />}
      onClick={handleClick}
      label='Crea regola'
      variant='right'
    />
    <CreateOrderDialog handleClose={handleClose} open={open} onRefresh={onRefresh} />
  </>)
}

export default CreateTaskButton