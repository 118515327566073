import { ColumnDef, ColumnFilter, ColumnSort } from '@tanstack/react-table'
import { CurrencyCell, DatetimeCell, RenderedCell } from '../../../../components/Table/cells'
import React, { FunctionComponent, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ToolbarAction } from '../../../../components/Table/TableToolbar'
import RefreshButton from '../../../../components/Table/actions/RefreshButton'
import { ColumnsButton } from '../../../../components/Table/actions/ColumnsButton'
import { FiltableServerTable } from '../../../../components/Table/FiltableServerTable'
import { MobileBody } from '../../../../components/Table/MobileBody'
import { Link } from '@mui/material'
import { FilterButton } from '../../../../components/Table/actions/FilterButton'
import { getTransactions } from '../../usecases/report'
import TransactionMobileBox from '../TransactionMobileBox'
import { Transaction } from '../../models/Transaction'
import { WithHelperText } from '../../../../components/Table/filters/columns/withHelperText'

const columns: ColumnDef<Transaction>[] = [
  {
    header: 'Data e ora',
    accessorKey: 'insertDatetime',
    cell: DatetimeCell,
    enableColumnFilter: false,
    enableSorting: true
  },
  {
    header: 'Pan',
    accessorKey: 'pan',
    cell: props => {
      const to = `/cards/${props.getValue()}`
      return (<Link
        color='secondary'
        to={to}

        component={RouterLink}>
        {props.getValue() as any}
      </Link>)
    },
    meta: {
      align: 'left',
      filter: WithHelperText( 'Per filtrare più pan, inseriscili separandoli con una virgola')

    },
    enableColumnFilter: true, //True,
    enableSorting: false
  },
  {
    header: 'Numero transazione',
    accessorKey: 'transactionNumber',
    meta: {
      align: 'right'
    },
    minSize: 30,
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    header: 'Prezzo',
    meta: {
      align: 'right'
    },
    accessorKey: 'unitPrice',
    cell: CurrencyCell,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    header: 'Litri',
    meta: {
      align: 'right'
    },
    accessorKey: 'unit',
    enableSorting: false,
    enableColumnFilter: false,
    cell: RenderedCell
  },
  {
    header: 'Importo',
    accessorKey: 'amount',
    meta: {
      align: 'right'
    },
    cell: CurrencyCell,
    enableSorting: false,
    enableColumnFilter: false

  },
  {
    header: 'Prodotto',
    accessorKey: 'productDescription',
    meta: {
      align: 'left'
    },
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    header: 'Punto vendita',
    accessorKey: 'shopDescription',
    meta: {
      align: 'left'
    },
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false

  },
  {
    header: 'Km',
    accessorKey: 'km',
    meta: {
      align: 'right'
    },
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false
  }
]

const toolbarActions: ToolbarAction<Transaction>[] = [(instance, onRefresh) => {
  return <RefreshButton onRefresh={onRefresh} />
}, (instance) => {
  return <ColumnsButton table={instance} />
}, (instance) => {
  return <FilterButton table={instance} />
}]

type Props = {
  id: string
}

const Transactions: FunctionComponent<Props> = ({id}) => {

  const source = useMemo(() => {
    return getTransactions(id)
  }, [id])

  return ( <FiltableServerTable<Transaction>
    name={'Transazioni'}
    initHiddenColumns={{}}
    columns={columns}
    handler={source}
    initPageIndex={0}
    actions={toolbarActions}
    initSortBy={initSortBy}
    initFilter={initFilters}
    mobilePaginationBreakpoint={'sm'}
    id={`reportTransactions:${id}`}>
    {instance =>
      <MobileBody table={instance} hasRowActions={false} initSortBy={initSortBy} boxMode={row => {
        return (<TransactionMobileBox transactionRow={row} />)
      }} breakpoint={'sm'} />}
  </FiltableServerTable>)
}

const initSortBy: ColumnSort[] = [{ id: 'insertDatetime', desc: true }]
const initFilters: ColumnFilter[] = []

export default Transactions