import { ReactNode } from 'react'
import DefaultColumnFilter from './DefaultColumnFilter'
import { FilterProps } from '@tanstack/table-core'


export const WithHelperText = <P extends object>(
  helperText: ReactNode
) => (props: FilterProps<P>) => {
  return <DefaultColumnFilter {...props} helperText={helperText} />
}
