import { useContext, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FilterStore } from '../filterStore'
import { FilterProps } from '@tanstack/react-table'

export const BooleanFilter = <T extends object>(
  {
    column: { columnDef, id } }: FilterProps<T>) => {
  const { state, dispatch } = useContext(FilterStore)
  const [value, setValue] = useState(state.tempFilters[id] === undefined ? 'all' : state.tempFilters[id] ? 'true' : 'false')
  const label = columnDef.header as string

  return (
    <FormControl variant='filled' color={'secondary'} sx={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
      <InputLabel id={`label-${id}-filter`}>{label}</InputLabel>
      <Select
        id={`${id}-filter`}
        value={value}
        onChange={(event: SelectChangeEvent) => {
          setValue(event.target.value)
          dispatch({
            type: 'updateFilter', payload: {
              id: id,
              value: event.target.value === 'all' ? undefined : event.target.value
            }
          })
        }}
      >
        <MenuItem value={'all'}>Tutti</MenuItem>
        <MenuItem value={'true'}>
          Si
        </MenuItem>
        <MenuItem value={'false'}>
          No
        </MenuItem>
      </Select>
    </FormControl>
  )
}