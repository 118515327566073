import React, { ChangeEvent, FunctionComponent, ReactNode, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Box, Tab, Tabs, useTheme } from '@mui/material'
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import CardData from './components/CardData'
import { DateTime } from 'luxon'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { getTransactions } from './usecases/card'
import CardHistory from './components/CardHistory/CardHistory'
import { Transaction } from './models/Transaction'
import { ColumnDef, ColumnFilter } from '@tanstack/react-table'
import { DatesFilter } from '../../components/Table/filters/columns/DatesFilter'
import { MobileBody } from '../../components/Table/MobileBody'
import { FiltableServerTable } from '../../components/Table/FiltableServerTable'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import { CurrencyCell, DatetimeCell, RenderedCell } from '../../components/Table/cells'
import { getDatetimeChipContent } from '../../components/Table/filters/chipbar'
import TransactionMobileBox from './components/TransactionMobileBox'
import { ColumnSort } from '@tanstack/table-core'

const columns: ColumnDef<Transaction>[] = [
  {
    header: 'Data e ora',
    accessorKey: 'insertDatetime',
    meta: {
      align: 'left',
      filter: DatesFilter,
      getChipContent: getDatetimeChipContent
    },
    cell: DatetimeCell,
    enableColumnFilter: true, //true,
    enableSorting: true
  },
  {
    header: 'Numero transazione',
    accessorKey: 'transactionNumber',
    meta: {
      align: 'right'
    },
    minSize: 30,
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    header: 'Prezzo',
    meta: {
      align: 'right'
    },
    accessorKey: 'unitPrice',
    cell: CurrencyCell,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    header: 'Litri',
    meta: {
      align: 'right'
    },
    accessorKey: 'unit',
    enableSorting: false,
    enableColumnFilter: false,
    cell: RenderedCell
  },
  {
    header: 'Importo',
    accessorKey: 'amount',
    meta: {
      align: 'right'
    },
    cell: CurrencyCell,
    enableSorting: false,
    enableColumnFilter: false

  },
  {
    header: 'Prodotto',
    accessorKey: 'productDescription',
    meta: {
      align: 'left'
    },
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false
  },
  {
    header: 'Punto vendita',
    accessorKey: 'shopDescription',
    meta: {
      align: 'left'
    },
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false

  },
  {
    header: 'Km',
    accessorKey: 'km',
    meta: {
      align: 'right'
    },
    cell: RenderedCell,
    enableSorting: false,
    enableColumnFilter: false
  }
]

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  const theme = useTheme()

  return (
    <div
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box style={{ marginTop: theme.spacing(2) }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const useCardParams = () => {
  let { pan } = useParams() as { pan: string }
  const { search } = useLocation()

  return useMemo<{
    pan: string
    tab: string
  }>(() => {
    const uq = new URLSearchParams(search)
    const tab = uq.get('tab')

    return {
      pan: pan !== null ? pan : '',
      tab: tab === null ? 'transazioni' : tab
    }

  }, [search, pan])

}

const actions2: ToolbarAction<Transaction>[] = [(instance, onRefresh) => {
  return <RefreshButton onRefresh={onRefresh} />
}, (instance) => {
  return <ColumnsButton table={instance} />
}, (instance) => {
  return <FilterButton table={instance} />
}]

const section = {
  marginTop: 2,
  marginBottom: 3
}


const Card: FunctionComponent = () => {
  const { pan, tab } = useCardParams()
  const navigate = useNavigate()

  const source = useMemo(() => {
    return getTransactions(pan)
  }, [pan])

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    navigate(`/cards/${pan}?tab=${newValue}`)
  }

  return (
    <div>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          component={RouterLink}
          underline='hover'>
          Home
        </Link>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Cards
        </Link>
        <Typography color='textPrimary'>
          {pan}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <CardData pan={pan} />
        </Grid>
        <Grid item xs={12} sx={section}>
          <Tabs value={tab} onChange={handleTabChange} textColor={'secondary'} indicatorColor={'secondary'}>
            <Tab label='Transazioni' value={'transazioni'} />
            <Tab label='Storia' value={'storia'} />
          </Tabs>
          <TabPanel value={tab} index={'transazioni'}>
            <FiltableServerTable<Transaction>
              name={'Transazioni'}
              initHiddenColumns={{}}
              columns={columns}
              handler={source}
              initPageIndex={0}
              actions={actions2}
              initSortBy={initSortBy}
              initFilter={initFilters}
              mobilePaginationBreakpoint={'sm'}
              id={`cardTransactions:${pan}`}>
              {instance =>
                <MobileBody table={instance} hasRowActions={false} initSortBy={initSortBy} boxMode={row => {
                  return (<TransactionMobileBox transactionRow={row} />)
                }} breakpoint={'sm'} />}
            </FiltableServerTable>
          </TabPanel>
          <TabPanel value={tab} index={'storia'}>
            <CardHistory pan={pan} />
          </TabPanel>
        </Grid>
      </Grid>

    </div>
  )
}

const initFilters: ColumnFilter[] = [{
  'id': 'insertDatetime', value: [DateTime.local().minus({ month: 6 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), DateTime.local().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0
  })]
}]
const initSortBy: ColumnSort[] = [{ id: 'insertDatetime', desc: true }]

export default Card
