import { useMemo } from 'react'
import { FiltersAction, FiltersState } from '../filters/filterStore'
import { ColumnFiltersState, VisibilityState } from '@tanstack/table-core'
import { Updater } from '@tanstack/react-table'

type Prop = {
  setAllFilters: (updater: Updater<ColumnFiltersState>) => void
  setHiddenColumns: (updater: Updater<VisibilityState>) => void
}

export const useTableAfterMiddleware = (
  {
    setAllFilters, setHiddenColumns
  }: Prop) => {
  return useMemo(() => {
    const setFilterColumns = (filters: Record<string, any>, hidden: Record<string, any>) => {

      const newFilters = []
      const newHidden = []
      for (let key of Object.keys(filters)) {
        const elem = filters[key]
        if (elem != null && (elem.length > 0 || elem !== '' || elem > -1)) {
          newFilters.push({ id: key, value: elem })
        }
      }
      for (let key of Object.keys(hidden)) {
        if (hidden[key] === true) {
          newHidden.push(key)
        }
      }
      setAllFilters(newFilters)
      setHiddenColumns(hidden)
    }
    const tableAfterMiddleware = (state: FiltersState, action: FiltersAction) => {
      switch (action.type) {
        case 'confirmFilters':
          const currentFilters = state.currentFilters
          const newFilters = []
          for (let key of Object.keys(currentFilters)) {
            const elem = currentFilters[key]
            if (elem != null && (elem.length > 0 || elem !== '' || elem > -1)) {
              newFilters.push({ id: key, value: elem  })
            }
          }
          setAllFilters(newFilters)
          break
        case 'confirmColumns':
          setHiddenColumns(state.currentHideColumns)
          break

        default:

      }
    }
    return {
      setFilterColumns,
      tableAfterMiddleware
    }
  }, [setAllFilters, setHiddenColumns])
}