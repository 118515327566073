import axios from 'axios'
import { Invoice } from '../models/Invoice'
import { Row } from '../models/Row'
import { formatDate } from '../../../utils/utils'

export const getInvoice = async (id: string, customerCode: number) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/invoices/get`, {
        id: id,
        customerCode: customerCode
      })

    const invoice = response.data as Invoice
    invoice.date = formatDate(invoice.date)
    invoice.expireAt = formatDate(invoice.expireAt)
    invoice.sdi.sentAt = formatDate(invoice.sdi.sentAt)

    return response.data as Invoice
  } catch (e) {
    throw e
  }
}

export const getInvoiceRows = async (id: string, customerCode: number) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/invoices/getRows`, {
        id: id,
        customerCode: customerCode
      })

    return response.data.rows as Row[]
  } catch (e) {
    throw e
  }
}


