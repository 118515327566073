import Snackbar from '@mui/material/Snackbar'
import React, { FunctionComponent, memo } from 'react'
import { useSelector } from 'react-redux'
import { cleanSnackbar, snackbarSelector } from '../../redux/snackbarStore'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import useAppDispatch from '../../hooks/useAppDispatch'
import { Dispatch } from '@reduxjs/toolkit'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type SnackbarMemoizedProps = {
  message: string
  variant: AlertColor
  open: boolean
  dispatch: Dispatch
}

const NotifySnackbarMemoized = memo<SnackbarMemoizedProps>((
  {
    message,
    variant,
    open,
    dispatch
  }) => {

  const handleSnackbarClose = () => {
    dispatch(cleanSnackbar())
  }

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      open={open}
      autoHideDuration={1500}
      onClose={handleSnackbarClose}
    >
      <Alert severity={variant} onClose={handleSnackbarClose}>
        {message}
      </Alert>
    </Snackbar>
  )

})

const NotifySnackbar: FunctionComponent = () => {
  const snackbarState = useSelector(snackbarSelector)
  const snackbarDispatch = useAppDispatch()

  return (<NotifySnackbarMemoized
    message={snackbarState.message}
    open={snackbarState.open}
    dispatch={snackbarDispatch}
    variant={snackbarState.variant} />)
}

export default NotifySnackbar