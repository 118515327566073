import React, { FunctionComponent } from 'react'
import { Card } from './models/Card'
import { CardMobileStatus } from './CardMobileStatus'
import { Row } from '@tanstack/react-table'


const CardMobileBox: FunctionComponent<{ rowCard: Row<Card> }> = ({ rowCard }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', alignContent: 'space-between' }}>
      <div style={{ flexGrow: 1, lineHeight: '0' }}>
        <div style={{ lineHeight: '1.2' }}>
          <span style={{ fontWeight: 500 }}>{rowCard.original.pan}</span>
        </div>
        <div style={{
          color: 'rgba(0, 0, 0, 0.54)',
          transform: 'scale(0.83)',
          transformOrigin: 'top left',
          lineHeight: '0.9'
        }}>
          <span style={{
            color: 'rgba(0, 0, 0, 0.54)',
            transform: 'scale(0.83)',
            transformOrigin: 'top left'
          }}>{rowCard.original.plate}</span> {rowCard.original.user}
        </div>
        <div style={{
          color: 'rgba(0, 0, 0, 0.54)',
          transform: 'scale(0.83)',
          transformOrigin: 'top left',
          lineHeight: '0.9'
        }}>
          {rowCard.original.businessName}
        </div>
      </div>

      <div><CardMobileStatus state={rowCard.original.state} /></div>
    </div>)
}

export default CardMobileBox