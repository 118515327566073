import React, { SyntheticEvent, useCallback, useContext, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { FilterStore } from '../filterStore'
import { FilterProps } from '@tanstack/react-table'


const getValue = (option: any) => option
export const AutocompletePrefilteredColumnFilter = <T extends object>(
  {
    column: {
      columnDef,
      id,
      getFacetedRowModel
    }
  }: FilterProps<T>) => {
  const { state, dispatch } = useContext(FilterStore)
  const [value, setValue] = useState(state.tempFilters[id] !== undefined ? state.tempFilters[id] : null)

  const onChange = useCallback((event: SyntheticEvent, value: string) => {
      let safeValue = ''
      if (value !== null) {
        safeValue = value
      }
      setValue(value)
      dispatch({ type: 'updateFilter', payload: { id: id, value: safeValue } })
    }
    , [dispatch, id, setValue])

  const options = React.useMemo(() => {
    const options = new Set<any>()
    getFacetedRowModel().rows.forEach((row) => {
      options.add(row.getValue(id))
    })
    return [...Array.from(options.values())]
  }, [getFacetedRowModel, id])


  return (<Autocomplete
    options={options}
    getOptionLabel={getValue}
    value={value}
    sx={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}
    onChange={onChange}
    renderInput={(params) =>
      <TextField {...params} color={'secondary'} label={columnDef.header as string} variant='outlined' />}
  />)
}