import { FunctionComponent, MouseEvent, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import pagoco from '../../assets/images/mypagoco.svg'
import { IconButton, Menu, MenuItem, PopoverOrigin } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link as RouterLink } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useSelector } from 'react-redux'
import { guiSelector, updateMobileOpen } from '../../redux/guiStore'
import useAppDispatch from '../../hooks/useAppDispatch'

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' }

const Header: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { signOut } = useAuthenticator(context => [context.signOut])

  const dispatch = useAppDispatch()

  const { mobileOpen } = useSelector(guiSelector)

  const handleDrawerToggle = () => {
    dispatch(updateMobileOpen({ mobileOpen: !mobileOpen }))
  }

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{
      display: 'flex',
    }}>
      <AppBar position='fixed' elevation={4} sx={({zIndex}) => ( {
        zIndex: zIndex.drawer + 1
      })}>
        <Toolbar variant={'dense'}>
          <IconButton
            color='inherit'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{display: {md: 'none'}}}
            size="large">
            <MenuIcon />
          </IconButton>
          <RouterLink to={'/'} style={{
            flexGrow: 1,
            display: 'flex',
            textDecoration: 'none'
          }}>
            <img src={pagoco} style={{
              height: '1.8rem',
              margin: '0.4em'
            }} alt='logo' />
          </RouterLink>
          <div>
            <div>
              <IconButton color={'secondary'} onClick={handleMenu} size="large">
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={RouterLink}
                          to={'/profilo/informazioni-generali'}>Profilo</MenuItem>
                <MenuItem onClick={signOut}>Esci</MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default Header