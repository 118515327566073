import React, { FunctionComponent, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import InvoiceSummary from './components/InvoiceSummary'
import { Row } from './models/Row'
import { updateSnackbar } from '../../redux/snackbarStore'
import { getInvoiceRows } from './usecases/invoice'
import useAppDispatch from '../../hooks/useAppDispatch'
import { FilterStoreMode } from '../../components/Table/filters/filterStore'
import { WithHelperText } from '../../components/Table/filters/columns/withHelperText'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { useQuery } from 'react-query'
import { MobileBody } from '../../components/Table/MobileBody'
import { FiltrableClientTable } from '../../components/Table/FiltrableClientTable'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import { ColumnDef, ColumnFilter } from '@tanstack/react-table'
import {
  AutocompletePrefilteredColumnFilter
} from '../../components/Table/filters/columns/AutocompletePrefilteredColumnFilter'
import InvoiceRowMobileBox from './components/InvoiceRowMobileBox'
import { CurrencyCell, DatetimeCell, RenderedCell } from '../../components/Table/cells'
import { ColumnSort } from '@tanstack/table-core'
import { accessorFnHelper } from '../../components/Table/helpers'


export interface Transaction {
  insertDatetime: string
  transactionNumber: string
  unitPrice: number
  unit: number
  km: number
  amount: number
  productCode: string
  productDescription: string
  shopCode: string
  shopDescription: string
}

const columns: ColumnDef<Row>[] = [
  {
    header: 'Data e ora',
    accessorKey: 'madeAt',
    cell: DatetimeCell,
    enableColumnFilter: false,
    enableSorting: true
  },
  {
    header: 'Pan',
    accessorKey: 'pan',
    cell: props => {
      const to = `/cards/${props.getValue()}`
      return (<Link
        color='secondary'
        to={to}

        component={RouterLink}>
        {props.getValue() as any}
      </Link>)
    },
    meta: {
      filter: WithHelperText( 'Per filtrare più pan, inseriscili separandoli con una virgola')
    },
    enableColumnFilter: true,
    enableSorting: false
  },
  {
    header: 'Numero transazione',
    cell: RenderedCell,
    meta: {
      align: 'right'
    },
    accessorKey: 'transactionNumber',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    header: 'Prezzo',
    meta: {
      align: 'right'
    },
    accessorKey: 'unitPrice',
    cell: CurrencyCell,
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    header: 'Litri',
    meta: {
      align: 'right'
    },
    accessorKey: 'unit',
    cell: RenderedCell,
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    header: 'Importo',
    accessorKey: 'amount',
    meta: {
      align: 'right'
    },
    cell: CurrencyCell,
    enableColumnFilter: false,
    enableSorting: true
  },
  {
    header: 'Importo netto',
    accessorKey: 'netAmount',
    meta: {
      align: 'right'
    },
    cell: CurrencyCell,
    enableColumnFilter: false,
    enableSorting: true
  },
  {
    header: 'Prodotto',
    accessorFn: accessorFnHelper((originalRow: Row) => {
      return originalRow.product.description
    }),
    meta: {
      filter: AutocompletePrefilteredColumnFilter

    },
    enableColumnFilter: true,
    enableSorting: false
  },
  {
    header: 'Km',
    accessorKey: 'km',
    cell: RenderedCell,
    meta: {
      align: 'right'
    },
    enableColumnFilter: false,
    enableSorting: false
  }
]

const useInvoiceParams = () => {
  let { id } = useParams() as { id: string }
  const { search } = useLocation()

  return useMemo<{
    id: string
    number: string
    customerCode: number
  }>(() => {
    const uq = new URLSearchParams(search)
    const number = uq.get('number')
    return {
      id: id !== null ? id : '',
      number: number !== null ? number : '',
      customerCode: Number(uq.get('customerCode'))
    }

  }, [search, id])

}

const section = {
  marginTop: 2,
  marginBottom: 3
}

const Invoice: FunctionComponent = () => {
  let { id, customerCode, number } = useInvoiceParams()
  const dispatch = useAppDispatch()


  const {
    data: rows,
    isLoading,
    refetch,
    dataUpdatedAt
  } = useQuery(['rows', id, customerCode], () => getInvoiceRows(id, customerCode), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  const toolbarActions = useMemo<ToolbarAction<Row>[]>(() => {
    return [() => {
      return <RefreshButton onRefresh={() => {
        refetch()
      }} />
    }, (table) => {
      return <ColumnsButton table={table} />
    }, (table) => {
      return <FilterButton table={table} />
    }]
  }, [refetch])

  return (
    <div>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Link
          color='inherit'
          to='/fatture'
          underline='hover'
          component={RouterLink}>
          Fatture
        </Link>
        <Typography color='textPrimary'>
          {number}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <InvoiceSummary number={number} id={id}
                          customerCode={customerCode} />
        </Grid>
        <Grid item xs={12} sx={section}>
          <FiltrableClientTable<Row>
            name={tableName}
            data={rows}
            columns={columns}
            updatedAt={dataUpdatedAt}
            actions={toolbarActions}
            filterStoreMode={FilterStoreMode.BACKUP}
            forceIsLoading={isLoading}
            initPageIndex={0}
            initFilter={initFilter}
            initHiddenColumns={initHidden}
            initSortBy={initSort}
            mobilePaginationBreakpoint={'sm'}
            id={'invoiceRows'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={false} initSortBy={initSort} boxMode={row => {
                return (<InvoiceRowMobileBox rowInvoiceRow={row} />)
              }} breakpoint={'sm'} />}
          </FiltrableClientTable>
        </Grid>
      </Grid>

    </div>
  )
}
const tableName = 'Righe fattura'

const initSort: ColumnSort[] = [{ id: 'madeAt', desc: false }]
const initFilter: ColumnFilter[] = []
const initHidden: Record<string, boolean> = {}


export default Invoice
