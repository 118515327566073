import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { Event } from '../models/Event'
import { Transaction } from '../models/Transaction'
import { Card, Fronts } from '../models/Card'
import { Collection } from '../../../components/Table/config'
import { Filters, SortingRule } from '@tanstack/react-table'

const cleanRegex = /( )+/g
const toFronts = (card: Card) => {
  let front2 = ''
  let front3 = ''
  const cardLimit = 30
  if (card.user.length === 0) {
    let businessName = card.businessName.replaceAll(cleanRegex, ' ')
    let front2Full = false // need for single letter after first word of front3, that can concat in front2

    for (const tkn of businessName.split(' ')) {
      const trimmedTkn = tkn.trim()
      if (front2.length + trimmedTkn.length <= cardLimit && !front2Full) {
        front2 = `${front2} ${trimmedTkn}`.trim()
      } else if (front3.length + trimmedTkn.length <= cardLimit) {
        front3 = `${front3} ${trimmedTkn}`.trim()
      } else {
        break
      }
    }
  } else {
    front2 = card.businessName
    front3 = card.user.toUpperCase()
    if (front2.length > cardLimit) {
      front2 = front2.substring(0, cardLimit)
    }
    if (front3.length > cardLimit) {
      front3 = front3.substring(0, cardLimit)
    }
  }
  return {
    front1: card.pan,
    front2: front2,
    front3: front3,
    front4: card.plate,
    front5: card.expireAt.substring(5, 7) + '/' + card.expireAt.substring(2, 4)
  } as Fronts
}

export const getCard = async (pan: string) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/get`, {
        pan: pan
      })

    let card = response.data
    const fronts = toFronts(card as Card)
    const expireDate = DateTime.fromFormat(`${card.expireAt} 23:59:59`, 'yyyy-MM-dd HH:mm:ss', { zone: 'Europe/Rome' })
    card.expireAt = expireDate.toFormat('dd/MM/yyyy')
    if (card.state === 2 && card.isState === 1) {
      card.state = 6
    } else if (card.printStatus === 1) {
      card.state = 5
    } else if (DateTime.now() > expireDate) {
      card.state = 4
    }

    return {
      ...card,
      fronts: fronts
    } as Card
  } catch (e) {
    throw e
  }
}
export const getPic = async (pan: string) => {
    try {
        const response = await
          axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/getPicture`, {
              pan: pan
          })

        return response.data.url as string
    } catch (e) {
        throw e
    }
}
export const getTransactions = (pan: string) => async (pageIndex: number, pageSize: number, sortBy: SortingRule<Transaction>[], filters: Filters<Transaction>) => {
  try {
    const filter = filters[0]
    const gdate = DateTime.fromISO(filter.value[1]).toUTC()
    const ldate = DateTime.fromISO(filter.value[0]).toUTC()

    let q = {}

    let url = `${process.env.REACT_APP_MYPAGOCO_HOST}/cards/getTransactions`

    q = {
      ...q,
      filters: {
        'from': ldate.toISO({ suppressMilliseconds: true }),
        'to': gdate.toISO({ suppressMilliseconds: true })
      },
      fields: 'id,customerCode,insertDatetime,requestDatetime,transactionNumber,cardTypeCode,datetime,amount,unitPrice,unit,km,residualValue,cardTypeCode,extraCode,terminalId,shop,product',
      pan: pan,
      limit: pageSize,
      offset: pageIndex * pageSize
    }
    if (sortBy.length > 0) {
      q = {
        ...q,
        sort: `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`
      }
    }

    const response = await
      axios.post(url, q)
    const transactions: Array<Transaction> = []

    if (response.data.transactions == null) {
      return {
        list: [],
        size: 0
      } as Collection<Transaction>
    }

    for (let tmp of response.data.transactions) {
      const transaction: Transaction = {
        amount: tmp.amount,
        km: tmp.km,
        insertDatetime: tmp.insertDatetime,
        productCode: tmp.product.code,
        productDescription: tmp.product.description,
        shopCode: tmp.shop.code,
        shopDescription: tmp.shop.description,
        transactionNumber: tmp.transactionNumber,
        unit: tmp.unit,
        unitPrice: tmp.unitPrice
      }
      transactions.push(transaction)
    }

    return {
      list: transactions,
      size: response.data.size as number
    } as Collection<Transaction>
  } catch (e) {
    let axiosError = e as AxiosError
    if (axiosError.response?.status === 404) {
      return {
        list: [],
        size: 0
      } as Collection<Transaction>
    }
    throw e
  }
}

export const getEvents = async (pan: string) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/getEvents`, {
        pan: pan,
        sort: "-timestamp"
      })

    return response.data.events as Event[]
  } catch (e) {
    throw e
  }
}
export const freeze = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/freeze`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}
export const unfreeze = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/unfreeze`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}
export const activate = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/activate`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}
export const lock = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/lock`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}