import React, { FunctionComponent, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Link as RouterLink, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import TaskSummary from './components/TaskSummary'
import Reports from './components/Reports'


const useTaskParams = () => {
  let { id } = useParams() as { id: string }
  return useMemo<{
    id: number
  }>(() => {
    return {
      id: Number(id)
    }
  }, [id])
}

const section = {
  marginTop: 2,
  marginBottom: 3
}

const Task: FunctionComponent = () => {
  let { id } = useTaskParams()

  return (
    <div>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Link
          color='inherit'
          to='/reportistica-regole'
          underline='hover'
          component={RouterLink}>
          Regole
        </Link>
        <Typography color='textPrimary'>
          {id}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <TaskSummary id={id} />
        </Grid>
        <Grid item xs={12} sx={section}>
          <Reports id={id} />
        </Grid>
      </Grid>

    </div>
  )
}


export default Task
