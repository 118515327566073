import { useContext, useState } from 'react'
import { Grid, InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField'
import { DateTime } from 'luxon'
import { FilterStore } from '../filterStore'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { FilterProps } from '@tanstack/react-table'


//const datepickerSX = {marginRight: '1em', width: '90%'}

type DatesFilterProps<T extends object> = FilterProps<T> & { month?: number }

export const DatesFilter = <T extends object>(
  {
    column: {
      id,
      columnDef
    },
    month = 6
  }: DatesFilterProps<T>) => {
  const { state, dispatch } = useContext(FilterStore)
  const [date1, setDate1] = useState<DateTime>(() => {
    const pre = state.tempFilters[id][0]
    if (typeof pre === 'string') {
      return DateTime.fromISO(pre)
    }
    return pre as DateTime
  })
  const [date2, setDate2] = useState<DateTime>(() => {
    const post = state.tempFilters[id][1]
    if (typeof post === 'string') {
      return DateTime.fromISO(post)
    }
    return post as DateTime
  })
  const label = columnDef.header as string


  return (
    <div style={{ marginTop: '0.4rem', marginBottom: '0.6rem' }}>
      <InputLabel htmlFor={id} shrink>
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
        <Grid container justifyContent={'space-between'} alignItems={'baseline'} spacing={2}>
          <Grid item sm={6} xs={12}>
            <DatePicker
              renderInput={(params) => <TextField {...params} color={'secondary'} />}
              label='Da'
              inputFormat='dd/MM/yyyy'
              value={date1}
              minDate={date2.minus({ month: month })}
              maxDate={date2}
              onChange={(date) => {
                const convDate = date as DateTime | null
                setDate1(date as DateTime)
                dispatch({ type: 'updateFilter', payload: { id: id, value: [convDate, date2] } })
              }} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DatePicker
              renderInput={(params) => <TextField {...params} />}
              label='a'
              inputFormat='dd/MM/yyyy'
              value={date2}
              maxDate={date1.plus({ month: month })}
              minDate={date1}
              onChange={(date) => {
                const convDate = date as DateTime | null
                setDate2(date as DateTime)

                dispatch({ type: 'updateFilter', payload: { id: id, value: [date1, convDate] } })
              }} />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  )
}