import axios, { AxiosError } from 'axios'
import { Collection } from '../../../components/Table/config'
import { Report } from '../models/Report'
import { DateTime } from 'luxon'
import { Filters, SortingRule } from '@tanstack/react-table'
import { CreateReport } from '../models/CreateReport'
import { getCustomers } from './customer'

export const getReports = async (pageIndex: number, pageSize: number, sortBy: SortingRule<Report>[], filters: Filters<Report>) => {
  try {
    let q = {}

    let url = `${process.env.REACT_APP_MYPAGOCO_HOST}/reports/list`

    q = {
      ...q,
      fields: 'id,customerCode,returnType,taskID,createdAt,from,to,reportStateID,updatedAt,sending',
      limit: pageSize,
      offset: pageIndex * pageSize
    }

    let bodyFilters = {}
    for (let filter of filters) {
      switch (filter.id) {
        case 'createdAt': {
          const gdate = DateTime.fromISO(filter.value[1]).toUTC()
          const ldate = DateTime.fromISO(filter.value[0]).toUTC()

          bodyFilters = {
            ...bodyFilters,
            'from': ldate.toISO({ suppressMilliseconds: true }),
            'to': gdate.toISO({ suppressMilliseconds: true })
          }
          break
        }
      }
    }

    q = {
      ...q,
      filters: bodyFilters
    }
    if (sortBy.length > 0) {
      q = {
        ...q,
        sort: `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`
      }
    }

    const response = await
      axios.post(url, q)
    const newList: Array<Report> = []

    const reports = response.data.reports
    if (reports == null) {
      return {
        list: [],
        size: 0
      } as Collection<Report>
    }

    const customers = await getCustomers()

    const customerMap = new Map<number, string>()

    for (let customer of customers) {
      customerMap.set(customer.customerCode, customer.businessName)
    }

    for (let tmp of reports) {
      const report: Report = {
        id: tmp.id,
        customerCode: tmp.customerCode,
        businessName: customerMap.get(tmp.customerCode) || '',
        returnType: tmp.returnType,
        createdAt: tmp.createdAt,
        taskID: tmp.taskID || 0,
        from: tmp.from,
        to: tmp.to,
        reportStateID: tmp.reportStateID,
        updatedAt: tmp.updatedAt,
        sendingIsEnabled: tmp.sending.isEnabled,
        sendingEmail: tmp.sending.email
      }
      newList.push(report)
    }

    return {
      list: newList,
      size: response.data.size as number
    } as Collection<Report>
  } catch (e) {
    let axiosError = e as AxiosError
    if (axiosError.response?.status === 404) {
      return {
        list: [],
        size: 0
      } as Collection<Report>
    }
    throw e
  }
}

export const createReport = async (req: CreateReport) => {
  try {
    const body = {
      customerCode: req.customerCode,
      from: req.from.toUTC().toISO({ suppressMilliseconds: true }),
      to: req.to.toUTC().toISO({ suppressMilliseconds: true }),
      returnType: req.returnType,
      sending: req.sending
    }
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/reports/create`, body)
  } catch (e) {
    throw e
  }
}

export const downloadReport = async (id: string) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/reports/download`, {
        id: id
      }, {
        responseType: 'blob',
        timeout: 60000
      })
    return response.data as Blob
  } catch (e) {
    throw e
  }
}