import React, { FunctionComponent, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import useAppDispatch from '../../../../hooks/useAppDispatch'
import { updateSnackbar } from '../../../../redux/snackbarStore'
import ConfirmChangeStatusDialog from '../../../../components/ConfirmChangeStatusDialog'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { Card } from '../../models/Card'
import { activate, freeze, lock, unfreeze } from '../../usecases/card'

type Props = {
  pan: string
  state?: number
  isActive?: boolean
}

type CardAction = {
  open: boolean,
  pan: string
}

const getErrorLabel = (response: any) => {
  const errorCode = response.errorCode
  let message = 'Errore di rete'
  switch (errorCode) {
    case 100 :
      message = 'Carta già congelata'
      break
    case 101 :
      message = 'Carta già abilitata'
      break
    case 102 :
      message = 'Carta già attiva'
      break
    case 103 :
      message = 'Carta non configurata. Contattare l\'assistenza'
      break
    case 104 :
      message = 'Carta non bloccabile'
      break
    case 1 :
      message = 'Carta non trovata'
      break
    default:
      break
  }

  return message

}


const StateButton: FunctionComponent<Props> = ({ pan, state, isActive }) => {
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const queryClient = useQueryClient()
  const ITEM_HEIGHT = 48

  const [openFreeze, setOpenFreeze] = useState<CardAction>({
    open: false,
    pan: ''
  })
  const [openUnfreeze, setOpenUnfreeze] = useState<CardAction>({
    open: false,
    pan: ''
  })
  const [openActivate, setOpenActivate] = useState<CardAction>({
    open: false,
    pan: ''
  })
  const [openLock, setOpenLock] = useState<CardAction>({
    open: false,
    pan: ''
  })

  const freezeMutation = useMutation<void, AxiosError, {
    pan: string
  }>(freeze, {
    onError: (error) => {
      let message = 'Errore di rete'
      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan }) => {
      await queryClient.cancelQueries(['card', pan])
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} congelata`
      }))
      queryClient.setQueryData(['card', pan], old => {
        const card = old as Card
        return {
          ...card,
          state: 3
        }
      })
      await queryClient.invalidateQueries(['cardEvents', pan])
    }
  })

  const unfreezeMutation = useMutation<void, AxiosError, {
    pan: string
  }>(unfreeze, {
    onError: (error, { pan }, context) => {
      let message = 'Errore di rete'
      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan }) => {
      await queryClient.cancelQueries(['card', pan])
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} scongelata`
      }))
      queryClient.setQueryData(['card', pan], old => {
        const card = old as Card
        return {
          ...card,
          state: 2
        }
      })
      await queryClient.invalidateQueries(['cardEvents', pan])
    }
  })

  const activateMutation = useMutation<void, AxiosError, {
    pan: string
  }>(activate, {
    onError: (error) => {
      let message = 'Errore di rete'
      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan }) => {
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} attivata`
      }))

      await queryClient.invalidateQueries(['card', pan])
      await queryClient.invalidateQueries(['cardEvents', pan])
    }
  })

  const lockMutation = useMutation<void, AxiosError, {
    pan: string
  }>(lock, {
    onError: (error) => {
      let message = 'Errore di rete'
      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan }) => {
      await queryClient.cancelQueries(['card', pan])
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} bloccata`
      }))
      queryClient.setQueryData(['card', pan], old => {
        const card = old as Card
        return {
          ...card,
          state: 0
        }
      })
      await queryClient.invalidateQueries(['cardEvents', pan])
    }
  })

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Button
        variant={'outlined'}
        color={'inherit'}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }} endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}>Azioni</Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6.5
          }
        }}
      >
        <MenuItem disabled={state === undefined || state !== 1 || isActive} onClick={async () => {
          setAnchorEl(null)
          setOpenActivate({
            pan: pan,
            open: true
          })
        }}>Attiva</MenuItem>
        <MenuItem disabled={state === undefined || state !== 3} onClick={async () => {
          setAnchorEl(null)
          setOpenUnfreeze({
            pan: pan,
            open: true
          })
        }}>Scongela</MenuItem>
        <MenuItem disabled={state === undefined || state !== 2} onClick={async () => {
          setAnchorEl(null)
          setOpenFreeze({
            pan: pan,
            open: true
          })
        }}>Congela</MenuItem>
        <MenuItem disabled={state === undefined || state === 0 || state > 3} onClick={async () => {
          setAnchorEl(null)
          setOpenLock({
            pan: pan,
            open: true
          })
        }}>Blocca</MenuItem>
      </Menu>
      <ConfirmChangeStatusDialog
        title={`Congela carta ${openFreeze.pan}`}
        open={openFreeze.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Congela'}
        handleLaunch={() => {
          freezeMutation.mutate({
            pan: openFreeze.pan
          })
          setOpenFreeze({
            open: false,
            pan: ''
          })
        }}
        handleClose={() => {
          setOpenFreeze({
            open: false,
            pan: ''
          })
        }}>
        Se non riesci a trovare la tua Carta oppure vuoi renderla inutilizzabile senza bloccarla, puoi congelarla per
        impedire che venga utilizzata per ulteriori acquisti. <br />
        Quando è congelata, la tua Carta non può essere utilizzata per transazioni nei punti vendita aderenti al
        circuito pagoCO. <br />
        Se ritieni che la tua Carta possa essere stata rubata o sospetti eventuali addebiti non autorizzati, ti
        preghiamo di procedere al blocco.
      </ConfirmChangeStatusDialog>
      <ConfirmChangeStatusDialog
        title={`Scongela carta ${openUnfreeze.pan}`}
        open={openUnfreeze.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Scongela'}
        handleLaunch={() => {
          unfreezeMutation.mutate({
            pan: openUnfreeze.pan
          })
          setOpenUnfreeze({
            open: false,
            pan: ''
          })
        }}
        handleClose={() => {
          setOpenUnfreeze({
            open: false,
            pan: ''
          })
        }}>
        Per poter tornare ad utilizzare la tua Carta precedemente congelata, puoi procedere a riabilitarla. <br />
        Quando abilitata, la tua Carta può essere utilizzata per transazioni nei punti vendita aderenti al
        circuito pagoCO.
      </ConfirmChangeStatusDialog>
      <ConfirmChangeStatusDialog
        title={`Attiva carta ${openActivate.pan}`}
        open={openActivate.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Attiva'}
        handleLaunch={() => {
          activateMutation.mutate({
            pan: openActivate.pan
          })
          setOpenActivate({
            open: false,
            pan: ''
          })
        }}
        handleClose={() => {
          setOpenActivate({
            open: false,
            pan: ''
          })
        }}>
        La tua Carta è in attesa di essere attivata, se ti è già stata consegnata puoi procedere ad attivarla;
        invieremo
        il codice PIN via SMS o EMAIL ai tuoi recapiti precedentemente registrati.
      </ConfirmChangeStatusDialog>
      <ConfirmChangeStatusDialog
        title={`Blocca carta ${openLock.pan}`}
        open={openLock.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Blocca'}
        handleLaunch={async () => {
          lockMutation.mutate({
            pan: openLock.pan
          })
          setOpenLock({
            open: false,
            pan: ''
          })
        }}
        handleClose={() => {
          setOpenLock({
            open: false,
            pan: ''
          })
        }}>
        Se vuoi rendere permanentemente inutilizzabile la tua Carta, puoi procedere a bloccarla. <br />
        Quando è bloccata, la tua Carta non potrà essere utilizzata per transazioni nei punti vendita aderenti al
        circuito pagoCO, ne successivamente riattivata.
      </ConfirmChangeStatusDialog>
    </div>
  )
}

export default StateButton