import React, { FunctionComponent } from 'react'
import { Credit } from '../models/credit'
import { formatCurrency, normalizeCurrency } from '../../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import './CreditMobileBox.css'
import { Row } from '@tanstack/react-table'


const CreditMobileBox: FunctionComponent<{ creditRow: Row<Credit> }> = ({ creditRow }) => {

  return (
    <div className='mobileBox'>
      <div className='mobileDescriptions'>
        <div className='mobileTitle'>
          {creditRow.original.pan}
        </div>
        <div className='subRow'>
          <span className='space'>
          {creditRow.original.plate}</span> {creditRow.original.user}
        </div>
        <div className='subRow'>
          {creditRow.original.vehicle}
        </div>
        <div className='subRow'>
          {creditRow.original.businessName}
        </div>
        <div className='subRow'>

        </div>
      </div>
      <div className='amount'>
        <Interval left={creditRow.original.dailyTotalAmount} right={creditRow.original.dailyMax} />
        <Interval left={creditRow.original.periodTotalAmount} right={creditRow.original.periodMax} />
      </div>
    </div>
  )
}

const Interval: FunctionComponent<{ left: number, right: number }> = ({ left, right }) => {
  const alert = right > 0
    && left > 0
    && (left / right) >= 0.75
  if (!alert) {
    return (<div>{formatCurrency(normalizeCurrency(left))}</div>)
  }

  return (
    <div className='creditValue'>
      <FontAwesomeIcon icon={faTriangleExclamation} style={{
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.0rem',
        flexShrink: 0,
        userSelect: 'none',
        marginRight: '0.24rem'
      }} /> {formatCurrency(normalizeCurrency(left))}
    </div>)
}

export default CreditMobileBox