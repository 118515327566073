import React, { FunctionComponent } from 'react'
import { Task } from './models/Task'
import { DateTime } from 'luxon'
import { TaskActiveMobileStatus } from './TaskActiveMobileStatus'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from '@tanstack/react-table'


const textifyFrequency = (frequency: string) => {
  let value = 'giornaliero'
  switch (frequency) {
    case 'week':
      value = 'settimanale'
      break
    case 'month':
      value = 'mensile'
      break
  }
  return value
}

const textifyReturnType = (returnType: number) => {
  let value = 'PDF/XLSX'
  switch (returnType) {
    case 1:
      value = 'PDF'
      break
    case 2:
      value = 'XLSX'
      break
  }
  return value
}
const TaskMobileBox: FunctionComponent<{ taskRow: Row<Task> }> = ({ taskRow }) => {
  return (
    <div className='mobileBox'>
      <div className='mobileDescriptions'>
        <div className={'mobileTitle'}>
          <span style={{ marginRight: '0.3rem' }}>{textifyFrequency(taskRow.original.frequency)}</span>
          <span style={{ marginRight: '0.3rem' }}>{textifyReturnType(taskRow.original.returnType)}</span>
          {taskRow.original.sendingIsEnabled && taskRow.original.sendingIsOptEmail ?
            <span style={ { marginLeft: '0.3rem' }}>
              <FontAwesomeIcon icon={faEnvelope} className={'imgFa'}  style={{
              fontSize: '1rem'
            }} /></span>
            : null}
        </div>
        <div className='subRow'>
          <span style={{ marginRight: '0.3rem' }}>{DateTime.fromISO(taskRow.original.createdAt).setZone('Europe/Rome')
            .toFormat('dd/MM/yyyy, HH:mm:ss')} </span>
          {taskRow.original.description}
        </div>
        <div className='subRow'>
          {taskRow.original.customerCode}
        </div>
        {taskRow.original.sendingIsEnabled && taskRow.original.sendingIsOptEmail ?
          <div className='subRow'>
              <span style={{ marginRight: '0.3rem' }}>
                {taskRow.original.sendingIsOptEmail}
              </span>
          </div>
          : null}
      </div>
      <TaskActiveMobileStatus isActive={taskRow.original.isActive} />
    </div>)
}

export default TaskMobileBox