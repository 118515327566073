import { memo } from 'react'
import { styled } from '@mui/material/styles'
import { Checkbox, IconButton } from '@mui/material'


const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked && prevProps.indeterminate === nextProps.indeterminate

export const HeaderCheckbox = memo(
  styled(Checkbox)({
    fontSize: '0.875rem',
    margin: '-9px 0 -8px -10px',
    padding: '8px 9px 9px 9px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }),
  areEqual
)

export const RowCheckbox = memo(
  styled(Checkbox)({
    fontSize: '0.875rem',
    margin: '-9px 0 -8px -10px',
    padding: '8px 9px 9px 9px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }),
  areEqual
)

export const RowActionButton = styled(IconButton)({
  padding: '8px 0',
  margin: '-10px 0'
})