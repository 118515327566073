import { TableBody, TableContainer, TableHead, useMediaQuery, useTheme } from '@mui/material'
import { ReactElement, useEffect } from 'react'
import MUITable from '@mui/material/Table'
import { Breakpoint } from '@mui/material/styles'
import { SortByElem } from './config'
import { HeadCell, HeaderRow, MobileCell, MobileRow, TableCell, TableRow, TableSortLabel } from './core'
import { flexRender, Row, Table } from '@tanstack/react-table'

export type OnClick<T extends object = {}> = (row: Row<T>) => void
export type BoxFunction<T extends object = {}> = (row: Row<T>) => ReactElement

const rowClickHandler = <T extends object>(row: Row<T>, onClick: (row: Row<T>) => void) => () => {
  onClick(row)
}

export const MobileBody = <T extends object>(
  {
    hasRowActions,
    breakpoint,
    onClick,
    boxMode,
    initSortBy,
    table
  }: {
    table: Table<T>,
    hasRowActions: boolean,
    breakpoint: Breakpoint | number,
    onClick?: OnClick<T>,
    boxMode: BoxFunction<T>,
    initSortBy?: Array<SortByElem>
  }): ReactElement => {

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up(breakpoint))


  const {setSorting} = table

  const {getHeaderGroups, getRowModel, } = table

  useEffect(() => {
    if (initSortBy !== undefined && !matches) {
      setSorting(initSortBy)
    }

  }, [initSortBy, matches,setSorting])

  if (matches) {
    return (
      <TableContainer component={'div'} sx={{
        display: 'block',
        maxWidth: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        scrollbarColor: 'rgba(0,50,96,.7)',
        '&::-webkit-scrollbar': {
          WebkitAppearance: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,50,96,.7)',
          border: '3px solid white',
          borderRadius: '10px'
        }
      }}>
        <MUITable size={'small'} sx={{
          width: '100%'
        }}>
          <TableHead>
            {getHeaderGroups().map((headerGroup) => (
              <HeaderRow key={`header_${headerGroup.id}`} sx={{  display: 'flex',
                flex: '1 0 auto',
                minWidth: '315px'}}>
                {
                  headerGroup.headers.map((header,index) => {
                    return (
                      <HeadCell
                        align={header.column.columnDef.meta?.align}
                        isActions={header.id === '_actions'}
                        hasRowActions={matches && hasRowActions}
                        key={`header_cell_${header.id}`}
                        sx={{boxSizing: 'border-box',
                          flex: index === -1? '0 0 auto': `${header.column.columnDef.size} 0 auto`,
                          minWidth: header.column.columnDef.minSize,
                          width: header.column.columnDef.size,
                        }}
                      >
                        {header.column.getCanSort() ? (
                          <TableSortLabel
                            active={header.column.getIsSorted() !== false}
                            onClick={header.column.getToggleSortingHandler()}
                            direction={ header.column.getIsSorted()
                              ? (header.column.getIsSorted() as 'asc' | 'desc')
                              : undefined}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </TableSortLabel>
                        ) : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </HeadCell>
                    )
                  })}
              </HeaderRow>
            ))}
          </TableHead>
          <TableBody>
            {getRowModel().rows.map((row, index) => {
              return(
                <TableRow key={`row_${row.id}`}
                          isStriped={index % 2 !== 0}
                          className={row.getIsSelected() ? 'rowSelected' : undefined}
                sx={{    display: 'flex',
                  flex: '1 0 auto',
                  minWidth: '315px'}}>
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    return (
                      <TableCell
                        key={cell.id}
                        isActions={cell.column.id === '_actions'}
                        isStriped={index % 2 !== 0}
                        sx={{
                          flex: cellIndex === -1? '0 0 auto': `${cell.column.columnDef.size} 0 auto`,
                          minWidth: cell.column.columnDef.minSize,
                          width: cell.column.columnDef.size,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>)
            })}
          </TableBody>
        </MUITable>
      </TableContainer>)
  }

  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <div style={{ borderTop: '1px solid  rgba(224, 224, 224, 1)' }}>
        {getRowModel().rows.map((row, index) => {
          if (onClick) {
            return (
              <MobileRow variant={'clickable'} isStriped={index % 2 !== 0}
                         key={row.id} onClick={rowClickHandler(row, onClick)}>
                <MobileCell>
                  {boxMode(row)}
                </MobileCell>
              </MobileRow>
            )
          }
          return (
            <MobileRow variant={'standard'} isStriped={index % 2 !== 0} key={index}>
              <MobileCell>
                {boxMode(row)}
              </MobileCell>
            </MobileRow>
          )
        })}
      </div>
    </div>)
}