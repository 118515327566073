import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon'
import { styled } from '@mui/material/styles'
import { Transaction } from '../../models/Transaction'
import { formatCurrency } from '../../../../utils/utils'
import { Row } from '@tanstack/react-table'

const TransactionMobileBox: FunctionComponent<{ transactionRow: Row<Transaction> }> = ({ transactionRow }) => {
  return (
    <div className='mobileBox'>
      <div className='mobileDescriptions'>
        <div className={'mobileTitle'}>
          {transactionRow.original.productDescription}
        </div>
        <div className={'subRow'}>
          <span style={{marginRight:'0.3rem'}}>{DateTime.fromISO(transactionRow.original.insertDatetime).setZone("Europe/Rome")
            .toFormat("dd/MM/yyyy, HH:mm:ss")} </span>
          {transactionRow.original.shopDescription}
        </div>
        <div className={'subRow'}>
          <span style={{marginRight:'0.3rem'}}>{transactionRow.original.km} KM</span>
          <span style={{marginRight:'0.3rem'}}>{transactionRow.original.unit} LT</span>
          {formatCurrency(transactionRow.original.unitPrice)}
        </div>
      </div>

      <Amount value={transactionRow.original.amount}>
        {formatCurrency( transactionRow.original.amount)}
      </Amount>
    </div>)
}

const Amount = styled('div', {
  shouldForwardProp: (prop) => prop !== 'amount',
})<{
  value: number
}>(({ value }) => ({
  fontWeight: 600, fontSize: '1rem',
  ...(value <= 0 && {
    color: '#C67943'
  })
}))

export default TransactionMobileBox