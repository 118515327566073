import { FunctionComponent } from 'react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import MuiCard from '@mui/material/Card'
import InputLabel from '@mui/material/InputLabel'
import { fillEmpty } from '../../../../utils/utils'
import cardGenerica from '../../../../assets/images/CRD-GENERICA.png'
import cx from 'classnames'
import { Card, Fronts } from '../../models/Card'
import CardState from '../CardState'
import './CardSummary.css'

const CardBox: FunctionComponent<{
  fakeUrl: string | undefined,
  full: boolean,
  fronts: Fronts | undefined
}> = ({ fakeUrl, full, fronts }) => {
  return (
    <div className={cx({
      'sm': !full
    })}>
      <div className={
        cx({
          'front': fronts?.front1.length !== 0,
          'hiddenFront': fronts?.front1.length === 0
        })}>
        <div className={'frontPan'}>
          <span className={'panToken'}>{fronts?.front1.substring(0, 4)}</span>
          <span className={'panToken'}>{fronts?.front1.substring(4, 8)}</span>
          <span className={'panToken'}>{fronts?.front1.substring(8, 12)}</span>
          <span>{fronts?.front1.substring(12, 17)}</span>
        </div>
        <div className={'frontUser'}>
          {fronts?.front3 !== undefined && fronts?.front3.length > 0 ?
            <span>{fronts?.front2}<br />{fronts?.front3}</span> :
            <span>{fronts?.front2} </span>}
        </div>
        <div className={'frontPlateExpire'}>{fronts?.front4}<span
          style={{ marginLeft: '14px' }}>{fronts?.front5}</span></div>
      </div>
      <Box boxShadow={8} borderRadius="10px" height={'190px'}>
        <img
          src={fakeUrl === undefined || fakeUrl === '' ? cardGenerica : fakeUrl}
          style={{
            height: '100%'
          }}
          alt='foto card'
        />
      </Box>
    </div>
  );
}
const CardSummary: FunctionComponent<{
  cardState: Card | undefined
  pic: string | undefined
}> = ({ pic, cardState }) => {
  const theme = useTheme()
  const hidePaddingBlock = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <MuiCard sx={{width: '100%', height: '100%' }}>
      <CardHeader title={'Riepilogo'} />
      <CardContent>
        <Grid container spacing={2} alignItems={'flex-start'}>
          <Grid item>
            <CardBox fakeUrl={pic} full={hidePaddingBlock} fronts={cardState?.fronts} />
          </Grid>
          <Grid item xs={12} sm spacing={2} container>
            <Grid item xs={6}>
              <InputLabel shrink>
                Ragione sociale
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {fillEmpty(cardState?.businessName)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Centro di costo
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {cardState?.destinID}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Descrizione
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {fillEmpty(cardState?.description)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Stato
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                <CardState state={cardState?.state === undefined ? 0 : cardState?.state} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Targa
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {fillEmpty(cardState?.plate)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Veicolo
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {fillEmpty(cardState?.vehicle)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Max giornaliero
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {cardState?.dailyMax}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Max periodo
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {cardState?.periodMax === 0 ? 'illimitato' : cardState?.periodMax}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Utilizzatore
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {fillEmpty(cardState?.user)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink>
                Scadenza
              </InputLabel>
              <Typography variant='body1' color='textPrimary' component='span'>
                {fillEmpty(cardState?.expireAt)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </MuiCard>
  )
}

export default CardSummary