import axios, { AxiosError } from 'axios'
import { Report } from '../models/Report'
import { Transaction } from '../models/Transaction'
import { Filters, SortingRule } from '@tanstack/react-table'
import { Collection } from '../../../components/Table/config'

const getCustomer = async (customerCode: number) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/me/customers/get`, {
        full:false,
        customerCode: customerCode
      })
    return response.data.businessName as string
  } catch (e) {
    throw e
  }
}

export const downloadReport = async (id: string) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/reports/download`, {
        id: id
      }, {
        responseType: 'blob',
        timeout: 60000
      })
    return response.data as Blob
  } catch (e) {
    throw e
  }
}

export const getReport = async (id: string) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/reports/get`, {
        id: id
      })

    const businessName = await getCustomer(response.data.customerCode as number)

    const task: Report = {
      id: response.data.id,
      createdAt : response.data.createdAt,
      customerCode: response.data.customerCode,
      businessName: businessName,
      returnType: response.data.returnType,
      sendingIsEnabled: response.data.sending.isEnabled,
      sendingEmail: response.data.sending.email,
      taskID: response.data.taskID,
      from: response.data.from,
      to: response.data.to,
      reportStateID: response.data.reportStateID,
      updatedAt: response.data.updatedAt
    }

    return task
  } catch (e) {
    throw e
  }
}

export const getTransactions = (id: string) => async (pageIndex: number, pageSize: number, sortBy: SortingRule<Transaction>[], filters: Filters<Transaction>) => {
  try {

    let q = {}
    let url = `${process.env.REACT_APP_MYPAGOCO_HOST}/reports/getTransactions`

    let bodyFilters = {}
    for (let filter of filters) {
      switch (filter.id) {
        case 'pan': {
          let pans = []
          for (const toTrimPan of (filter.value as string).split(',')) {
            pans.push(toTrimPan.trim())
          }
          bodyFilters = {
            ...bodyFilters,
            'pans': pans
          }
          break
        }
      }
    }

    q = {
      ...q,
      fields: 'id,customerCode,pan,insertDatetime,requestDatetime,transactionNumber,cardTypeCode,datetime,amount,unitPrice,unit,km,residualValue,cardTypeCode,extraCode,terminalId,shop,product',
      id: id,
      filters: bodyFilters,
      limit: pageSize,
      offset: pageIndex * pageSize
    }
    if (sortBy.length > 0) {
      q = {
        ...q,
        sort: `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`
      }
    }

    const response = await
      axios.post(url, q)
    const transactions: Array<Transaction> = []

    if (response.data.transactions == null) {
      return {
        list: [],
        size: 0
      } as Collection<Transaction>
    }

    for (let tmp of response.data.transactions) {
      const transaction: Transaction = {
        amount: tmp.amount,
        km: tmp.km,
        pan: tmp.pan,
        insertDatetime: tmp.insertDatetime,
        productCode: tmp.product.code,
        productDescription: tmp.product.description,
        shopCode: tmp.shop.code,
        shopDescription: tmp.shop.description,
        transactionNumber: tmp.transactionNumber,
        unit: tmp.unit,
        unitPrice: tmp.unitPrice
      }
      transactions.push(transaction)
    }

    return {
      list: transactions,
      size: response.data.size as number
    } as Collection<Transaction>
  } catch (e) {
    let axiosError = e as AxiosError
    if (axiosError.response?.status === 404) {
      return {
        list: [],
        size: 0
      } as Collection<Transaction>
    }
    throw e
  }
}