import { LinearProgress, Paper } from '@mui/material'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { FilterStoreMode, FilterStoreProvider } from './filters/filterStore'
import { useTableAfterMiddleware } from './hooks/useTableAfterMiddleware'
import { Collection } from './config'
import { Breakpoint } from '@mui/material/styles'
import {
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  Filters,
  getCoreRowModel,
  getFilteredRowModel,
  PaginationState,
  Row,
  RowSelectionState,
  SortingRule,
  SortingState,
  Table,
  useReactTable
} from '@tanstack/react-table'
import { useQuery } from 'react-query'
import { TableToolbar, ToolbarAction } from './TableToolbar'
import { ColumnSort, VisibilityState } from '@tanstack/table-core'
import { MobileTablePagination, TablePagination } from './paginations'
import { FilterChipBar } from './filters/chipbar'

export type FetchFunction<T extends object> = (pageIndex: number, pageSize: number, sortBy: SortingRule<T>[], filters: Filters<T>) => Promise<Collection<T>>

interface Props<T extends object = {}> {
  id: string
  name?: string
  handler: FetchFunction<T>
  columns: ColumnDef<T>[]
  initPageIndex: number
  filterStoreMode?: FilterStoreMode
  initFilter?: ColumnFilter[]
  initSortBy?: ColumnSort[]
  initHiddenColumns?: Record<string, boolean>
  actions?: Array<ToolbarAction<T>>
  onDelete?: (rows: Row<T>[]) => void
  mobilePaginationBreakpoint?: Breakpoint | number,
  children: (table: Table<T>) => ReactElement
}


export const FiltableServerTable = <T extends object>(
  {
    id,
    columns,
    handler,
    onDelete,
    name,
    actions,
    initSortBy = [],
    children,
    initFilter = [],
    initHiddenColumns = {},
    filterStoreMode = FilterStoreMode.DEFAULT,
    initPageIndex,
    mobilePaginationBreakpoint
  }: Props<T>): ReactElement => {

  const [initializedFilters, setInitializedFilters] = useState(false)
  const [filters, setFilters] = useState<ColumnFiltersState>(initFilter)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initHiddenColumns)

  const [{ pageIndex, pageSize }, setPagination] =
    useState<PaginationState>({
      pageIndex: initPageIndex,
      pageSize: 10
    })

  const [sorting, setSorting] = useState<SortingState>(initSortBy)
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  )

  const { data, isLoading, refetch, isRefetching, isFetching } = useQuery(
    [id, pageIndex, pageSize, sorting, filters],
    () => handler(pageIndex, pageSize, sorting, filters),
    { refetchOnMount: true, keepPreviousData: true }
  )

  const onRefresh = useCallback(() => {
    refetch()
  }, [refetch])

  const defaultData = useMemo(() => [], [])

  const table = useReactTable({
    data: data?.list ?? defaultData,
    columns: columns,
    pageCount: Math.ceil(data?.size ? data.size / pageSize : 0),
    manualPagination: true,
    state: {
      pagination,
      sorting,
      columnFilters: filters,
      columnVisibility,
      rowSelection
    },
    //selection
    onRowSelectionChange: setRowSelection,
    //pagination
    onPaginationChange: setPagination,
    // pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    //filtering
    //enableFilters: true,
    manualFiltering: true,
    onColumnFiltersChange: setFilters,
    //sorting
    enableSorting: true,
    manualSorting: true,
    onSortingChange: setSorting,
    meta: {
      onRefresh
    },
    //hiding
    onColumnVisibilityChange: setColumnVisibility
  })

  const { tableAfterMiddleware, setFilterColumns } = useTableAfterMiddleware({
    setHiddenColumns: table.setColumnVisibility,
    setAllFilters: table.setColumnFilters
  })

  return (
    <Paper style={{ maxWidth: '100%' }}>
      <FilterStoreProvider storeKey={id}
                           initFilters={initFilter}
                           filterStoreMode={filterStoreMode}
                           initHide={initHiddenColumns}
                           initializedFilters={initializedFilters}
                           setFilterColumn={setFilterColumns}
                           setInitializedFilters={setInitializedFilters}
                           tableAfterMiddleware={tableAfterMiddleware}>
        <TableToolbar table={table} name={name} {...{ onDelete }} actions={actions} onRefresh={onRefresh} />
        <FilterChipBar<T> table={table} />
      </FilterStoreProvider>
      {children(table)}
      {(isLoading || isRefetching || isFetching) && <LinearProgress color='secondary' />}
      {mobilePaginationBreakpoint === undefined ?
        <TablePagination table={table} rowCount={data?.size ?? 0} /> :
        <MobileTablePagination id={id} table={table} breakpoint={mobilePaginationBreakpoint}
                               rowCount={data?.size ?? 0} />}
    </Paper>)
}