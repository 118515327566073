import React, { FunctionComponent } from 'react'
import { Row as InvoiceRow } from '../../models/Row'
import { formatCurrency } from '../../../../utils/utils'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import './InvoiceRowMobileBox.css'
import { Row } from '@tanstack/react-table'

const InvoiceRowMobileBox: FunctionComponent<{ rowInvoiceRow: Row<InvoiceRow> }> = ({ rowInvoiceRow }) => {

  return (
    <div className='mobileBox'>
      <div className='mobileDescriptions'>
        <div className={'mobileTitle'}>
          {rowInvoiceRow.original.product.description}
        </div>
        <div className={'subRow'}>
          <span className={'space'}>{DateTime.fromISO(rowInvoiceRow.original.madeAt).setZone("Europe/Rome")
            .toFormat("dd/MM/yyyy, HH:mm:ss")} </span>
          {rowInvoiceRow.original.transactionNumber}
        </div>
        <div className={'subRow'}>
          <span className={'space'}>{rowInvoiceRow.original.km} KM</span>
          <span className={'space'}>{rowInvoiceRow.original.unit} LT</span>
          {formatCurrency(rowInvoiceRow.original.unitPrice)}
        </div>
        <div className={'subRow'}>
          {rowInvoiceRow.original.pan}
        </div>
      </div>
      <div className={clsx('amount', {
        'off': rowInvoiceRow.original.netAmount <= 0 ,
      })}>{formatCurrency( rowInvoiceRow.original.netAmount)}</div>
    </div>)
}

export default InvoiceRowMobileBox