import { FunctionComponent } from 'react'
import clsx from 'clsx'


const getStateLabel = (state: number | undefined) => {
  if (state === undefined) {
    return '-'
  }
  let label = 'bloccata'
  switch (state) {
    case  1:
      label = 'da attivare'
      break
    case  2:
      label = 'attiva'
      break
    case 3:
      label = 'congelata'
      break
    case 4:
      label = 'scaduta'
      break
    case 5:
      label = 'in lavorazione'
      break
    case 6:
      label = 'in aggiornamento'
      break
  }
  return label
}
export const CardMobileStatus: FunctionComponent<{
  state: number | undefined
}> = ({ state }) => {

  return (
    <div className={clsx('root', {
      'off': state === undefined || state === 0 || state === 4,
      'on': state === 2,
      'onRun': state === 5 || state === 6,
      'freeze': state === 3 || state === 1
    })}>{getStateLabel(state)}</div>)
}