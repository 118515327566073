import React, { useCallback, useContext, useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FilterStore } from '../filterStore'
import { FilterProps } from '@tanstack/react-table'

type KeyValue = {
  key: any
  value: any
}

export const SelectKeyValueFilter = <T extends object>(
  {
    column: {
      columnDef,
      id
    }
  }: FilterProps<T>) => {
  const { state, dispatch } = useContext(FilterStore)
  //all value empty for string key value, -1 for number one
  const [value, setValue] = useState(
    state.tempFilters[id] !== undefined ?
      state.tempFilters[id] : columnDef.meta?.isKeyIndexNumeric === true ? -1 : '')

  const options: Array<KeyValue> = useMemo(() => {
    const tmpOptions: Array<KeyValue> = []
    const keyValues = columnDef.meta?.keyValues
    if (keyValues !== undefined) {
      for (let key of Object.keys(keyValues)) {
        tmpOptions.push({ key: columnDef.meta?.isKeyIndexNumeric === true ? Number(key) : key, value: keyValues[key] })
      }
    }
    return tmpOptions
  }, [columnDef.meta?.isKeyIndexNumeric, columnDef.meta?.keyValues])

  const onChange = useCallback((event: SelectChangeEvent<{ value: any }>) => {
    setValue(event.target.value)
    let value = undefined
    const targetValue = event.target.value as any
    if (targetValue !== '' && targetValue !== -1) {
      value = event.target.value
    }
    dispatch({ type: 'updateFilter', payload: { id: id, value: value} })
  }, [dispatch, id])

  const label  = columnDef.header as string

  return (
    <FormControl variant='filled' color={'secondary'} sx={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
      <InputLabel id={`label-${id}-filter`}>{label}</InputLabel>
      <Select
        id={`${id}-filter`}
        value={value}

        onChange={onChange}
      >
        <MenuItem key={'all'} value={columnDef.meta?.isKeyIndexNumeric === true ? -1 : ''}>Tutti</MenuItem>
        {options.length > 0 ? options.map((option, i) => (
          <MenuItem key={i} value={option.key}>
            {option.value}
          </MenuItem>
        )) : null}
      </Select>
    </FormControl>
  )
}