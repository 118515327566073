import { Table } from '@tanstack/react-table'
import { Breakpoint, styled, useTheme } from '@mui/material/styles'
import React, { ReactElement, useEffect } from 'react'
import {
  FormControl,
  IconButton,
  IconButtonProps,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  useMediaQuery
} from '@mui/material'
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@mui/icons-material'

const rowsPerPageOptions = [5, 10, 25, 50]

const PaginationButton = styled(IconButton)<IconButtonProps>({
  padding: '0.4rem'
})

export const MobileTablePagination = <T extends object>(
  {
    rowCount = 0,
    breakpoint,
    table,
    id
  }: { table: Table<T>, rowCount: number, breakpoint: Breakpoint | number, id: string }): ReactElement | null => {

  const { pageSize, pageIndex } = table.getState().pagination

  const { setPageSize, setPageIndex, previousPage, nextPage, getCanNextPage, getCanPreviousPage, getPageCount } = table

  useEffect(() => {
    window.sessionStorage.setItem(`${id}Index`, String(pageIndex))
  }, [id, pageIndex])

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up(breakpoint))

  return rowCount ? (
    <Toolbar variant={'dense'} sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '8px 2px',
      paddingRight: '2px',
      backgroundColor: '#FAFAFA',
      borderRadius: '0 0 10px 10px'
    }}>
      {matches && <>
        <TextField
          type='number'
          label={'Vai alla pagina'}
          defaultValue={pageIndex + 1}
          color={'secondary'}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            setPageIndex(page)
          }}
          sx={{ width: '96px' }}
        />
        <FormControl color={'secondary'} variant={'standard'} sx={{
          width: '10rem',
          marginLeft: '0.8rem',
          marginRight: '0.8rem'
        }}>
          <InputLabel>Righe per pagina</InputLabel>
          <Select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {rowsPerPageOptions.map(pageSize => (
              <MenuItem key={pageSize} value={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>}
      <div style={{
        marginLeft: '0.6rem',
        marginRight: '0.6rem'
      }}>
        {pageIndex * pageSize + 1}-{(pageIndex + 1) * pageSize > rowCount ? rowCount : (pageIndex + 1) * pageSize} di {rowCount}
      </div>
      <PaginationButton
        onClick={() => setPageIndex(0)}
        // disabled={!getCanNextPage()}
        size='large'>
        <FirstPage />
      </PaginationButton>
      <PaginationButton
        onClick={previousPage}
        disabled={!getCanPreviousPage()}
        size='large'>
        <ChevronLeft />
      </PaginationButton>
      <PaginationButton
        onClick={nextPage}
        disabled={!getCanNextPage()}
        size='large'>
        <ChevronRight />
      </PaginationButton>
      <PaginationButton
        size='large'
        onClick={() => setPageIndex(getPageCount() - 1)}
      >
        <LastPage />
      </PaginationButton>
    </Toolbar>
  ) : null
}

export const TablePagination = <T extends object>(
  {
    table,
    rowCount
  }: { table: Table<T>, rowCount: number }): ReactElement | null => {

  const { pageSize, pageIndex } = table.getState().pagination

  const { setPageSize, setPageIndex, previousPage, nextPage, getCanNextPage, getCanPreviousPage, getPageCount } = table

  return rowCount ? (
    <Toolbar variant={'dense'} sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '8px 2px',
      paddingRight: '2px',
      backgroundColor: '#FAFAFA',
      borderRadius: '0 0 10px 10px'
    }}>
      <TextField
        type='number'
        label={'Vai alla pagina'}
        defaultValue={pageIndex + 1}
        color={'secondary'}
        onChange={e => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          setPageIndex(page)
        }}
        style={{ width: '96px' }}
      />
      <FormControl variant={'standard'}
                   color={'secondary'}
                   sx={{ width: '10rem', marginLeft: '0.8rem', marginRight: '0.8rem' }}>
        <InputLabel>Righe per pagina</InputLabel>
        <Select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {rowsPerPageOptions.map(pageSize => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{
        marginLeft: '0.6rem',
        marginRight: '0.6rem'
      }}>
        {pageIndex * pageSize + 1}-{(pageIndex + 1) * pageSize > rowCount ? rowCount : (pageIndex + 1) * pageSize} di {rowCount}
      </div>
      <PaginationButton
        onClick={() => setPageIndex(0)}
        size='large'>
        <FirstPage />
      </PaginationButton>
      <PaginationButton
        onClick={previousPage}
        disabled={!getCanPreviousPage()}
        size='large'>
        <ChevronLeft />
      </PaginationButton>
      <PaginationButton
        onClick={nextPage}
        disabled={!getCanNextPage()}
        size='large'>
        <ChevronRight />
      </PaginationButton>
      <PaginationButton
        onClick={() => setPageIndex(getPageCount() - 1)}
      >
        <LastPage />
      </PaginationButton>
    </Toolbar>
  ) : null
}