import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon'
import Device from '../models/Device'
import { DeviceOnlineMobileStatus } from './DeviceOnlineMobileStatus'
import { Row } from '@tanstack/react-table'

const DeviceMobileBox: FunctionComponent<{ deviceRow: Row<Device> }> = ({ deviceRow }) => {
  return (
    <div className={'mobileBox'}>
      <div className={'mobileDescriptions'}>
        <div className={'mobileTitle'}>
          {deviceRow.original.name}
        </div>
        <div className={'subRow'}>
          <span style={{ marginRight: '0.3rem' }}>{deviceRow.original.lastIp} </span>
          <span style={{ marginRight: '0.3rem' }}>{DateTime.fromISO(deviceRow.original.lastAuthenticatedDate).setZone('Europe/Rome')
            .toFormat('dd/MM/yyyy, HH:mm:ss')} </span>
        </div>
      </div>
      <DeviceOnlineMobileStatus isActive={deviceRow.original.status} />
    </div>)
}

export default DeviceMobileBox