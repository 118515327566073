import { CognitoUserAmplify } from '../../../custom'
import UserAttributes from '../models/UserAttributes'

export const globalSignout = async (cognito: CognitoUserAmplify): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    cognito.globalSignOut({
      onSuccess: msg => {
        resolve()
      },
      onFailure: err => {
        reject(err)
      }
    })
  })
}
export const getUserAttributes = async (cognitoUserAmplify: CognitoUserAmplify): Promise<UserAttributes> => {
  return new Promise<UserAttributes>((resolve, reject) => {
    cognitoUserAmplify.getUserAttributes((err, result) => {
      if (err !== null) {
        reject(err)
        return
      }
      if (result === undefined) {
        reject(new Error('empty attributes'))
      } else {
        resolve({
          sub: result[0].Value,
          emailVerified: Boolean(result[1].Value),
          name: result[2].Value,
          familyName: result[3].Value,
          email: result[4].Value
        })
      }
    })
  })
}