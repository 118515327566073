import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import headerTriangles from './assets/images/headertriangles.png'

const theme = responsiveFontSizes(createTheme({
  palette: {
    background: {
      default: '#F2F1F6'
    },
    primary: { main: '#fff14b' },
    secondary: { main: '#003260' }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: `url(${headerTriangles})`
        }
      }

    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '10px 16px',
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #eaeded',
          borderRadius: '10px 10px 0'
        },
        title: {
          fontWeight: 700,
          color: '#003260'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff14b',
          color: '#003260'
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
      rounded: {
        borderRadius: '10px'
      }}
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #eaeded',
          padding: '0.68rem 1.4rem',
          color: '#003260'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #eaeded'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#003260'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: '#003260',
          fontWeight: 'bold'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: '#ffffff'
        }
      }
    }
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont,\n' +
      '    "Ubuntu", "Cantarell", "Oxygen", "Segoe UI","Fira Sans", "Droid Sans", "Helvetica Neue",\n' +
      '    sans-serif !important'
  }
}))
export default theme