import React, { FunctionComponent } from 'react'
import Customer from '../models/Customer'
import { Row } from '@tanstack/react-table'


const CustomerMobileBox: FunctionComponent<{ customerRow: Row<Customer> }> = ({ customerRow }) => {
  return (
    <div className={'mobileBox'}>
      <div className='mobileDescriptions'>
        <div className={'mobileTitle'}>
          <span style={{ marginRight: '0.3rem' }}>{customerRow.original.customerCode}</span>
          <span style={{ marginRight: '0.3rem' }}>{customerRow.original.businessName}</span>
        </div>
        <div className={'subRow'}>
          <span style={{ marginRight: '0.3rem' }}>{customerRow.original.address} </span>
          <span style={{ marginRight: '0.3rem' }}>{customerRow.original.city} </span>
          <span style={{ marginRight: '0.3rem' }}>{customerRow.original.provincia} </span>
        </div>
        <div className={'subRow'}>
          {customerRow.original.email.split(",").map(row => <span key={row} style={{ marginRight: '0.3rem' }}>{row.toLowerCase()} </span>)}
        </div>
      </div>
    </div>)
}

export default CustomerMobileBox