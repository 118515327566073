import {DateTime} from "luxon";

export const formatDatetime = (date: string) => {
    if (date == null) {
        return ""
    }
    return DateTime.fromISO(date)
      .setZone("Europe/Rome")
      .toFormat("dd/MM/yyyy HH:mm:ss")
}

export const formatDate = (date: string) => {
    if (date == null || date === "") {
        return ""
    }
    return DateTime.fromISO(date)
        .setZone("Europe/Rome")
        .toFormat("dd/MM/yyyy")
}

export const formatDateTZ = (date: string | undefined, zone: string, format: string) => {
    if (!date || date === "") {
        return ""
    }
    return DateTime.fromISO(date).setZone(zone).toFormat(format)
}

export const fillEmpty = (str?: string) => {
    if (str == null || str === "") {
        return "-"
    }
    return str
}

export const formatCurrency = (value?: number) => {
    return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 3

    }).format(value != null ? value : 0).toString()
}

export const normalizeCurrency = (nmbr?: number) => {
    if (nmbr == null) {
        return 0
    }
    return nmbr /100
}