export const textifyState = (state?: number) => {
  if (state == null) {
    return 'IN LAVORAZIONE'
  }
  let value = 'IN LAVORAZIONE'
  switch (state) {
    case 3:
      value = 'INVIATO'
      break
    case 2:
      value = 'CREATO'
      break
  }
  return value
}

export const textifyReturnType = (returnType: number | undefined) => {
  if (returnType === undefined) {
    return ''
  }
  let value = 'PDF/XLSX'
  switch (returnType) {
    case 1:
      value = 'PDF'
      break
    case 2:
      value = 'XLSX'
      break
  }
  return value
}