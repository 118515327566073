import React, { FunctionComponent } from 'react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import InputLabel from '@mui/material/InputLabel'
import { fillEmpty, formatDateTZ } from '../../../utils/utils'
import { getTask } from '../usecases/task'
import { updateSnackbar } from '../../../redux/snackbarStore'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { useQuery } from 'react-query'
import { textifyFrequency, textifyReturnType } from '../utils'


type DataProps = {
  id: number
}
const TaskSummary: FunctionComponent<DataProps> =
  ({
     id
   }) => {
    const snackbarDispatch = useAppDispatch()

    const { data: task } = useQuery(['task', id], () => getTask(id), {
      onError: _ => {
        snackbarDispatch(updateSnackbar({
          message: 'Errore di rete',
          variant: 'error'
        }))
      }
    })

    return (
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant={'h4'}>
            {`Regola ${id}`}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card sx={{ width: '100%', height: '100%' }}>
            <CardHeader title={'Riepilogo'} />
            <CardContent>
              <Grid container spacing={2} alignItems={'flex-start'}>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Descrizione
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(task?.description)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Ragione sociale
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(task?.businessName)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Creata
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatDateTZ(task?.createdAt, 'Europe/Rome','dd/MM/yyyy HH:mm:ss' ))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Frequenza
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(textifyFrequency(task?.frequency))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Tipo di file
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(textifyReturnType(task?.returnType))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Attiva
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(task?.isActive === true ? 'si': 'no')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Inoltro
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(task?.sendingIsEnabled === true ? 'si': 'no')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Email opzionale
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(task?.sendingIsOptEmail)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    )
  }

export default TaskSummary