import axios from 'axios'
import Customer from '../models/Customer'


export const getCustomers = async () => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/me/customers/list`, {
        full:true
      })
    return response.data.customers as Customer[]
  } catch (e) {
    throw e
  }
}