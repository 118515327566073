import React, { FunctionComponent } from 'react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import InputLabel from '@mui/material/InputLabel'
import { fillEmpty, formatCurrency } from '../../../../utils/utils'
import { getInvoice } from '../../usecases/invoice'
import { updateSnackbar } from '../../../../redux/snackbarStore'
import useAppDispatch from '../../../../hooks/useAppDispatch'
import { useQuery } from 'react-query'


type DataProps = {
  id: string
  number: string | null
  customerCode: number
}
const InvoiceSummary: FunctionComponent<DataProps> =
  ({
     id, customerCode, number
   }) => {
    const snackbarDispatch = useAppDispatch()

    const { data: invoice } = useQuery(['invoice', id, customerCode], () => getInvoice(id, customerCode), {
      onError: _ => {
        snackbarDispatch(updateSnackbar({
          message: 'Errore di rete',
          variant: 'error'
        }))
      }
    })

    return (
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant={'h4'}>
            {`Fattura ${number === undefined || number === '' ? '-' : number}`}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card sx={{ width: '100%', height: '100%' }}>
            <CardHeader title={'Riepilogo'} />
            <CardContent>
              <Grid container spacing={2} alignItems={'flex-start'}>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Periodo contabile
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {invoice?.accountingYear}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Ragione sociale
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.businessName)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Tipo documento
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.description)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Data
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.date)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    ID Centro di costo
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {invoice?.costCenter.id}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Centro di costo descr.
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.costCenter.description)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Tipo pagamento
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.paymentType)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Scadenza
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.expireAt)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Base imponibile
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatCurrency(invoice?.taxBase))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Totale IVA
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatCurrency(invoice?.totalVATAmount))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink>
                    Totale
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatCurrency(invoice?.total))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    ID SDI
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.sdi.id)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Data trasmissione SDI
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(invoice?.sdi.sentAt)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    )
  }

export default InvoiceSummary