export const textifyFrequency = (frequency: string | undefined) => {
  if (!frequency) {
    return ''
  }
  let value = 'giornaliero'
  switch (frequency) {
    case 'week':
      value = 'settimanale'
      break
    case 'month':
      value = 'mensile'
      break
  }
  return value
}

export const textifyReturnType = (returnType: number | undefined) => {
  if (returnType === undefined) {
    return ''
  }
  let value = 'PDF/XLSX'
  switch (returnType) {
    case 1:
      value = 'PDF'
      break
    case 2:
      value = 'XLSX'
      break
  }
  return value
}