 class Cookie {
    Key: string
    Value: string

     constructor(Key: string, Value: string) {
         this.Key = Key;
         this.Value = Value;
     }

     expire()  {
         document.cookie = `${this.Key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
     }
 }

export const getCookies = (startWith: string):  Array<Cookie> => {
    let cookies: Array<Cookie> =  []
    for (let cookie of document.cookie.split(';').filter(c => c.trim().startsWith(startWith))) {
        let cookieSplit = cookie.split('=')
        cookies.push(new Cookie(cookieSplit[0], cookieSplit[1]))
    }
    return cookies
}