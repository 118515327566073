import { CellContext, RowData } from '@tanstack/table-core'
import { DivCell } from '../../components/Table/cells'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@mui/material'
import React from 'react'

export const ReportTaskIDCell = <TData extends RowData>({ getValue, column: {columnDef} }: CellContext<TData, unknown>) => {
  const value = getValue() as number
  return (<DivCell align={columnDef.meta?.align}>
    {value !== 0? <Link
      color='secondary'
      sx={{textAlign:'right'}}
      to={`/reportistica-regole/${value}`}

      component={RouterLink}>{value}</Link>: "-"}
  </DivCell>)
}