import React, { FunctionComponent, useMemo } from 'react'
import useAppDispatch from '../../hooks/useAppDispatch'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { Credit } from './models/credit'
import { getCredits } from './usecases/credits'
import { updateSnackbar } from '../../redux/snackbarStore'
import { MaxPeriodCell } from './MaxPeriodCell'
import { Link as RouterLink } from 'react-router-dom'
import { Breadcrumbs, Card, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material'
import { DailyCreditCell } from './DailyCreditCell'
import { PeriodCreditCell } from './PeriodCreditCell'
import { useQuery } from 'react-query'
import { FilterStoreMode } from '../../components/Table/filters/filterStore'
import { SortByElem } from '../../components/Table/config'
import CompanyCreditsChart from './CompanyCreditsChart'
import { ColumnDef, ColumnFilter } from '@tanstack/react-table'
import {
  SelectPrefilteredColumnFilter
} from '../../components/Table/filters/columns/SelectPrefilteredColumnFilter'
import { MobileBody } from '../../components/Table/MobileBody'
import { FiltrableClientTable } from '../../components/Table/FiltrableClientTable'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import  CreditMobileBox  from './CreditMobileBox'
import { NormalizedCurrencyCell, RenderedCell } from '../../components/Table/cells'


const columns:ColumnDef<Credit>[] = [
  {
    header: 'Codice cliente',
    accessorKey: 'customerCode',
    cell: RenderedCell,
    filterFn: 'equals',
    meta: {
      align: 'right',
      filter: SelectPrefilteredColumnFilter
    },
    enableColumnFilter: true,
    enableSorting: false
  },
  {
    header: 'Ragione sociale',
    accessorKey: 'businessName',
    cell: RenderedCell,
    meta: {
      filter: SelectPrefilteredColumnFilter
    },
    enableColumnFilter: true,
    enableSorting: false
  },
  {
    header: 'Pan',
    accessorKey: 'pan',
    cell: props => {
      const to = `/cards/${props.getValue()}`
      return (<Link
        color='secondary'
        to={to}

        component={RouterLink}>
        {props.getValue() as any}
      </Link>)
    },
    meta: {
      filter: SelectPrefilteredColumnFilter
    },
    enableColumnFilter: true,
    enableSorting: false
  },
  {
    header: 'Totale giornaliero',
    accessorKey: 'dailyTotalAmount',
    cell: DailyCreditCell,
    enableColumnFilter: false,
    enableSorting: true
  },
  {
    header: 'Max giornaliero',
    accessorKey: 'dailyMax',
    enableColumnFilter: false,
    enableSorting: false,
    cell: NormalizedCurrencyCell
  },
  {
    header: 'Totale periodo',
    accessorKey: 'periodTotalAmount',
    cell: PeriodCreditCell,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: 'Max periodo',
    accessorKey: 'periodMax',
    enableColumnFilter: false,
    enableSorting: false,
    cell: MaxPeriodCell
  },
  {
    header: 'Descrizione',
    accessorKey: 'description',
    cell: RenderedCell,
    meta: {
      filter: SelectPrefilteredColumnFilter
    },
    enableColumnFilter: true,
    enableSorting: false
  },
  {
    header: 'Targa',
    accessorKey: 'plate',
    cell: RenderedCell,
    meta: {
      filter: SelectPrefilteredColumnFilter
    },
    enableColumnFilter: true,
    enableSorting: false
  },
  {
    header: 'Veicolo',
    accessorKey: 'vehicle',
    cell: RenderedCell,
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    header: 'Utilizzatore',
    accessorKey: 'user',
    cell: RenderedCell,
    meta: {
      filter: SelectPrefilteredColumnFilter
    },
    enableColumnFilter: true,
    enableSorting:true
  }
]

const section = {
  marginTop: 2,
  marginBottom: 3
}

const Credits: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const { data: credits, isLoading, refetch, dataUpdatedAt } = useQuery('credits', () => getCredits(), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  const toolbarActions = useMemo<ToolbarAction<Credit>[]>(() => {
    return [() => {
      return <RefreshButton onRefresh={() => {
        refetch()
      }} />
    }, (table) => {
      return <ColumnsButton table={table} />
    }, (table) => {
      return <FilterButton table={table} />
    }]
  }, [refetch])

  return (<>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Fidi
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <Card>
            <CardHeader title={'Fidi aziendali'} />
            <CardContent>
              <CompanyCreditsChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sx={section}>
          <FiltrableClientTable<Credit>
            name={tableName}
            data={credits}
            columns={columns}
            updatedAt={dataUpdatedAt}
            actions={toolbarActions}
            filterStoreMode={FilterStoreMode.BACKUP}
            forceIsLoading={isLoading}
            initPageIndex={0}
            initFilter={initFilters}
            initHiddenColumns={newInitHidden}
            initSortBy={initSort}
            mobilePaginationBreakpoint={'sm'}
            id={'credits'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={true} boxMode={row => {
                return (<CreditMobileBox creditRow={row} />)
              }} breakpoint={'sm'} />}
          </FiltrableClientTable>
        </Grid>
      </Grid>
    </>
  )
}

const tableName = 'Fidi card'

const initFilters: ColumnFilter[] = []
const newInitHidden: Record<string, boolean> = {
  'customerCode': false,
}

const initSort: Array<SortByElem> = [{ id: 'dailyTotalAmount', desc: true }]
export default Credits