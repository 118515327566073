import React, { FunctionComponent } from 'react'
import { Grid, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import InputLabel from '@mui/material/InputLabel'
import { fillEmpty, formatDateTZ } from '../../../../utils/utils'
import { updateSnackbar } from '../../../../redux/snackbarStore'
import useAppDispatch from '../../../../hooks/useAppDispatch'
import { useQuery } from 'react-query'
import { textifyReturnType, textifyState } from '../../utils'
import { getReport } from '../../usecases/report'
import { Link as RouterLink } from 'react-router-dom'
import { ReportState } from '../ReportState'
import OperationsButton from '../OperationsButton'


type DataProps = {
  id: string
}
const ReportSummary: FunctionComponent<DataProps> =
  ({
     id
   }) => {
    const snackbarDispatch = useAppDispatch()

    const { data: report } = useQuery(['report', id], () => getReport(id), {
      onError: _ => {
        snackbarDispatch(updateSnackbar({
          message: 'Errore di rete',
          variant: 'error'
        }))
      }
    })

    return (
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item> <Typography variant={'h4'}>
            {`Report ${id === undefined || id === '' ? '-' : id}`}
          </Typography></Grid>
          <Grid item>
            <OperationsButton id={id} state={report?.reportStateID} />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card sx={{ width: '100%', height: '100%' }}>
            <CardHeader title={'Riepilogo'} />
            <CardContent>
              <Grid container spacing={2} alignItems={'flex-start'}>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Regola
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {report?.taskID != null && report.taskID !== 0? <Link
                      color='secondary'
                      to={`/reportistica-regole/${report.taskID}`}

                      component={RouterLink}>
                      {report.taskID}
                    </Link>: "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Ragione sociale
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(report?.businessName)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Da
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatDateTZ(report?.from, 'Europe/Rome','dd/MM/yyyy' ))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    A
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatDateTZ(report?.to, 'Europe/Rome','dd/MM/yyyy' ))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Tipo di file
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(textifyReturnType(report?.returnType))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Creata
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatDateTZ(report?.createdAt, 'Europe/Rome','dd/MM/yyyy HH:mm:ss' ))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Stato
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    <ReportState state={report?.reportStateID != null ? report.reportStateID: 0}>
                      {textifyState(report?.reportStateID)}
                    </ReportState>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Aggiornata
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(formatDateTZ(report?.updatedAt, 'Europe/Rome','dd/MM/yyyy HH:mm:ss' ))}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Inoltro
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(report?.sendingIsEnabled === true ? 'si': 'no')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputLabel shrink>
                    Email
                  </InputLabel>
                  <Typography variant='body1' color='textPrimary' component='span'>
                    {fillEmpty(report?.sendingEmail)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    )
  }

export default ReportSummary