import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material'
import { ReactElement, useContext } from 'react'
import { FilterStore } from './filterStore'
import { Table } from '@tanstack/react-table'

type ColumnsDialogProps<T extends object> = {
  table: Table<T>
  onClose: () => void
  show: boolean
}

export const ColumnsDialog = (
  {
    onClose,
    show,
    table
  }: ColumnsDialogProps<any>): ReactElement => {

  const { state, dispatch } = useContext(FilterStore)
  const hideableColumns = table.getAllColumns().filter((column) => column.id !== '_selector' && column.id !== '_actions')
  const onlyOneOptionLeft = state.tempHideCount + 1 === hideableColumns.length

  return (
    <Dialog onClose={() => {
      onClose()
      dispatch({ type: 'resetColumns' })
    }} open={show} maxWidth='md'>
      <DialogTitle>Seleziona colonne</DialogTitle>
      <DialogContent>
        {hideableColumns.length > 1 ?
          <FormGroup sx={{marginTop: 1}}>
            {hideableColumns.map((column) => {
              const label = column.columnDef.header as string
              return (
                <div key={column.id}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={onlyOneOptionLeft}
                        value={`${column.id}`}
                        size='small'
                        color='secondary'
                      />
                    }
                    checked={state.tempHideColumns[column.id] || state.tempHideColumns[column.id] === undefined }
                    onChange={() => {
                      dispatch({ type: 'toggleColumn', payload: column.id })
                    }}
                    label={label}
                  />
                </div>
              )
            })}
          </FormGroup> : null}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color={'inherit'} onClick={() => {
          onClose()
          dispatch({ type: 'resetColumns' })
        }}>
          Annulla
        </Button>
        <Button variant='contained' color={'secondary'} onClick={() => {
          onClose()
          dispatch({ type: 'confirmColumns' })
        }}>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  )
}
