import React, { FunctionComponent } from 'react'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { useQuery } from 'react-query'
import { getCompanyCredits } from '../usecases/credits'
import { updateSnackbar } from '../../../redux/snackbarStore'
import { Doughnut } from 'react-chartjs-2'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js'

import 'swiper/css'
import 'swiper/css/pagination'
import './CompanyCreditsChart.css'


ChartJS.register(ArcElement, Tooltip, Legend, Title)

const CompanyCreditsChart: FunctionComponent = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useAppDispatch()
  const { data: companyCredits } = useQuery('companyCredits', () => getCompanyCredits(), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  if (companyCredits === undefined) {
    return (<div></div>)
  }

  if (matches) {
    return (<Grid container spacing={8}>
      {
        companyCredits.map((value, i) => {
          return <Grid key={`company${i}`} item xs={6} md={4} lg={3} xl={2}>
            <Doughnut data={value.data} options={value.option} />
          </Grid>
        })
      }
    </Grid>)
  }

  if (companyCredits.length === 1) {
    return (
      <Box padding={theme.spacing(8)}>
        <Doughnut data={companyCredits[0].data} options={companyCredits[0].option} />
      </Box>)
  }

  return (
    <>
      <Swiper
        spaceBetween={30}
        modules={[Pagination]}
        pagination={{
          dynamicBullets: true,
          clickable: true
        }}
      >
        {companyCredits.map((value, i) =>
          <SwiperSlide key={`company${i}`}>
            <Doughnut data={value.data} options={value.option} />
          </SwiperSlide>
        )}
      </Swiper>
    </>)

}

export default CompanyCreditsChart