import React, { FunctionComponent, useCallback, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import useAppDispatch from '../../../../hooks/useAppDispatch'
import { updateSnackbar } from '../../../../redux/snackbarStore'
import { downloadReport } from '../../usecases/report'

type Props = {
  id: string
  state?: number
}

const OperationsButton: FunctionComponent<Props> = ({ id, state }) => {
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const ITEM_HEIGHT = 48


  const download = useCallback(async () => {
    setAnchorEl(null)
    try {
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Download in preparazione...`
      }))
      const response = await downloadReport(id)
      let filename = `${id}.zip`
      const url = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        filename
      )
      document.body.appendChild(link)
      link.click()
      link.remove()
      URL.revokeObjectURL(url)
    } catch (e) {
      dispatch(updateSnackbar({
        variant: 'error',
        message: 'Download fallito'
      }))
    }
  },[dispatch, id])

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Button
        variant={'outlined'}
        color={'inherit'}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }} endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}>Operazioni</Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6.5
          }
        }}
      >
        <MenuItem disabled={state == null || state === 1} onClick={download}>Download</MenuItem>
      </Menu>
    </div>
  )
}

export default OperationsButton