import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: GuiState = {
  mobileOpen: false
}

interface GuiState {
  mobileOpen: boolean
}

type UpdateMobileOpenPayload = {
  mobileOpen: boolean
}

export const gui = createSlice({
  name: 'gui',
  initialState,
  reducers: {
    updateMobileOpen: (state, action: PayloadAction<UpdateMobileOpenPayload>) => {
      const payload = action.payload
      state.mobileOpen = payload.mobileOpen
    }
  }
})

export const guiSelector = createSelector(
  (state: any) => state.gui as GuiState,
  (state) => {
    return {
      mobileOpen: state.mobileOpen
    }
  })

export const { updateMobileOpen } = gui.actions
export default gui.reducer