import { FunctionComponent } from 'react'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab'
import { useTheme } from '@mui/material/styles'
import { Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import cx from 'classnames'
import { formatDatetime } from '../../../../utils/utils'
import { faPause, faPlayCircle, faPowerOff, faStopCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getEvents } from '../../usecases/card'
import useAppDispatch from '../../../../hooks/useAppDispatch'
import { updateSnackbar } from '../../../../redux/snackbarStore'
import { useQuery } from 'react-query'
import './CardHistory.css'

type CardHistoryProps = {
  pan: string
}

const getHistoryTitle = (type: string) => {
  let description = ''
  switch (type) {
    case 'freeze':
      description = 'Carta congelata'
      break
    case 'activate':
      description = 'Carta attivata'
      break
    case 'unfreeze':
      description = 'Carta scongelata'
      break
    case 'lock':
      description = 'Carta bloccata'
      break
  }
  return description
}

const HistoryIcon: FunctionComponent<{ type: string }> = ({ type }) => {
  switch (type) {
    case 'activate':
      return (<FontAwesomeIcon icon={faPowerOff} className={'imgFa'} style={{
        width: '1.4em',
        height: '1.4em',
        fontSize: '1.2rem'
      }} />)
    case 'freeze':
      return (<FontAwesomeIcon icon={faPause} className={'imgFa'}  style={{
        width: '1.4em',
        height: '1.4em',
        fontSize: '1.2rem'
      }} />)
    case 'active':
      return (<FontAwesomeIcon icon={faPlayCircle}  className={'imgFa'}  style={{
        width: '1.4em',
        height: '1.4em',
        fontSize: '1.2rem'
      }} />)
    case 'unfreeze':
      return (<FontAwesomeIcon icon={faPlayCircle} className={'imgFa'}  style={{
        width: '1.4em',
        height: '1.4em',
        fontSize: '1.2rem'
      }} />)
    case 'lock':
      return (<FontAwesomeIcon icon={faStopCircle} className={'imgFa'}  style={{
        width: '1.4em',
        height: '1.4em',
        fontSize: '1.2rem'
      }} />)
    default:
      return null
  }
}

const CardHistory: FunctionComponent<CardHistoryProps> = ({ pan }) => {
  const theme = useTheme()
  const snackbarDispatch = useAppDispatch()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const { data } = useQuery(['cardEvents', pan], () => getEvents(pan), {
    onError: _ => {
      snackbarDispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    }
  })

  return (
    <Grid container justifyContent={'space-around'}>
      <Grid item xs={12} lg={8}>
        <Timeline position={matches ? 'alternate' : 'right'}>
          {matches ?
            data?.map((event, index) => {
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography color='textPrimary'>{formatDatetime(event.timestamp)}</Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color='secondary'>
                      <HistoryIcon type={event.type} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={'historyPaper'}>
                      <Typography variant='h6' component='div'>
                        {getHistoryTitle(event.type)}
                      </Typography>
                      <table className={cx('historyTable', {
                        'historyTableRight': index % 2 === 0,
                        'historyTableLeft': index % 2 === 1
                      })}>
                        <tbody>
                        <tr>
                          <th className={'historyLabel'}>Utente
                          </th>
                          <td className={'historyValue'}>{event.subject}</td>
                        </tr>
                        </tbody>
                      </table>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>)
            })
            : data?.map((event, index) => {
              return (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color='secondary'>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={'historyPaperMobile'}>
                      <Typography sx={{ marginBottom: '0.4rem' }}
                                  color='textPrimary'>{formatDatetime(event.timestamp)}</Typography>
                      <Typography variant='h6' component='div'>
                        {getHistoryTitle(event.type)}
                      </Typography>
                      <table className={cx('historyTable', 'historyTableRight', 'historyTableMobile')}>
                        <tbody>
                        <tr>
                          <th className={'historyLabel'}>Utente</th>
                          <td className={'historyValue'}>{event.subject}</td>
                        </tr>
                        </tbody>
                      </table>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>)
            })}
        </Timeline>
      </Grid>
    </Grid>)

}
export default CardHistory