import axios from 'axios'
import { Task } from '../models/Task'

const getCustomer = async (customerCode: number) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/me/customers/get`, {
        full:false,
        customerCode: customerCode
      })
    return response.data.businessName as string
  } catch (e) {
    throw e
  }
}

export const getTask = async (id: number) => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/tasks/get`, {
        id: id
      })

    const businessName = await getCustomer(response.data.customerCode as number)

    const task: Task = {
      id: response.data.id,
      createdAt : response.data.createdAt,
      frequency: response.data.frequency,
      description: response.data.description,
      customerCode: response.data.customerCode,
      businessName: businessName,
      returnType: response.data.returnType,
      sendingIsEnabled: response.data.sending.isEnabled,
      sendingIsOptEmail: response.data.sending.optEmail,
      isActive: response.data.isActive
    }

    return task
  } catch (e) {
    throw e
  }
}

