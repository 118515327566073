import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import React, { MouseEventHandler } from 'react'
import { styled } from '@mui/material/styles'


type ActionButtonProps = {
  icon?: JSX.Element
  onClick: MouseEventHandler
  enabled?: boolean
  label: string
  variant?: 'right' | 'left'
}


export const ActionButton =
  (({
      icon,
      onClick,
      label,
      enabled = true,
      variant
    }: ActionButtonProps) => {
    return (
      <Tooltip title={label} aria-label={label}>
        <Icon
          onClick={onClick}
          disabled={!enabled}
          variant={variant}
          size='large'>
          {icon}
        </Icon>
      </Tooltip>
    )
  })

const Icon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'variant'
})<IconButtonProps & { variant?: 'right' | 'left' }>(({ theme, variant }) => ({
  ...(variant === 'left' && {
    '&:first-of-type': {
      marginLeft: -12
    }
  }),
  ...(variant === 'right' && {
    color: theme.palette.secondary.light,
    padding: 12,
    marginTop: '-6px',
    width: 48,
    height: 48,
    '&:last-of-type': {
      marginRight: -12
    }
  })
}))