import { ChangeEvent, ReactNode, useContext, useState } from 'react'
import { TextField } from '@mui/material'
import { FilterStore } from '../filterStore'
import { FilterProps } from '@tanstack/react-table'


export type DefaultColumnFilterProps<T extends object> = FilterProps<T> & {
  adornment?: ReactNode,
  helperText?: ReactNode
}

const DefaultColumnFilter = <T extends object>(
  {
    column,
    adornment,
    helperText
  }: DefaultColumnFilterProps<T>) => {
  const { id, columnDef } = column

  const { state, dispatch } = useContext(FilterStore)
  const [value, setValue] = useState(state.tempFilters[id] !== undefined ? state.tempFilters[id] : '')
  const label = columnDef.header as string

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    dispatch({ type: 'updateFilter', payload: { id: id, value: event.target.value } })
  }

  return (
    <TextField
      name={id}
      label={label}
      value={value}
      margin={'dense'}
      autoFocus={column.getFilterIndex() === 0}
      variant={'outlined'}
      helperText={helperText}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        startAdornment: adornment
      }}
      color={'secondary'}
    />
  )
}

export default DefaultColumnFilter