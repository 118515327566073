import React, { FunctionComponent, useMemo } from 'react'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { downloadReport, getReports } from './usecases/reports'
import { Report } from './models/Report'
import ReportMobileBox from './ReportMobileBox'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import { ColumnDef, ColumnFilter, ColumnSort } from '@tanstack/react-table'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterStoreMode } from '../../components/Table/filters/filterStore'
import { MobileBody } from '../../components/Table/MobileBody'
import { FiltableServerTable } from '../../components/Table/FiltableServerTable'
import { BooleanCell, DateCell, DatetimeCell, RenderedCell } from '../../components/Table/cells'
import { DatesFilter } from '../../components/Table/filters/columns/DatesFilter'
import { getDatetimeChipContent } from '../../components/Table/filters/chipbar'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import { DateTime } from 'luxon'
import { ReportStateCell } from './ReportStateCell'
import CreateReportButton from './components/CreateReportButton'
import { RowAction, withActions } from '../../components/Table/helpers'
import useAppDispatch from '../../hooks/useAppDispatch'
import { updateSnackbar } from '../../redux/snackbarStore'
import { OnClick } from '../../components/Table/config'
import { ReportTaskIDCell } from './ReportTaskIDCell'


const toolbarActions: ToolbarAction<Report>[] = [(instance, onRefresh) => {
  return <RefreshButton onRefresh={onRefresh} />
}, (instance) => {
  return <ColumnsButton table={instance} />
}, (instance) => {
  return <FilterButton table={instance} />
}, (instance, onRefresh) => {
  return <CreateReportButton onRefresh={onRefresh} />
}]

const initSortBy: ColumnSort[] = [{ id: 'createdAt', desc: true }]
const initFilter: ColumnFilter[] = [{
  'id': 'createdAt', value: [DateTime.local().minus({ month: 6 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), DateTime.local().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0
  })]
}]

const Reports: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onClick = useMemo<OnClick<Report>>(() => {
    return row => {
      navigate(`/report/${row.original.id}`)
    }
  }, [navigate])

  const columns = useMemo<ColumnDef<Report>[]>(() => {
    return withActions([
        {
          disabled: _ => false,
          label: 'Apri',
          onClick: (row) => {
            navigate(`/report/${row.original.id}`)
          }
        } as RowAction<Report>
        ,
        {
          disabled: row => row.original.reportStateID < 2,
          label: 'Scarica',
          onClick: async (row, onRefresh) => {
            try {
              dispatch(updateSnackbar({
                variant: 'success',
                message: `Download in preparazione...`
              }))
              const response = await downloadReport(row.original.id)
              let filename = `${row.original.id}.zip`
              const url = URL.createObjectURL(response)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                filename
              )
              document.body.appendChild(link)
              link.click()
              link.remove()
              URL.revokeObjectURL(url)
            } catch (e) {
              dispatch(updateSnackbar({
                variant: 'error',
                message: 'Download fallito'
              }))
            }
          }
        }
      ] as RowAction<Report>[],
      [
        {
          header: 'ID',
          accessorKey: 'id',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false,
          size: 200
        },
        {
          header: 'Creato',
          accessorKey: 'createdAt',
          meta: {
            filter: DatesFilter,
            getChipContent: getDatetimeChipContent
          },
          enableColumnFilter: true,
          enableSorting: true,
          cell: DatetimeCell
        },
        {
          header: 'Task ID',
          accessorKey: 'taskID',
          cell: ReportTaskIDCell,
          meta: {
            align: 'right'
          },
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Da',
          accessorKey: 'from',
          enableColumnFilter: false,
          enableSorting: true,
          cell: DateCell
        },
        {
          header: 'A',
          accessorKey: 'to',
          enableColumnFilter: false,
          enableSorting: true,
          cell: DateCell
        },
        {
          header: 'Stato',
          accessorKey: 'reportStateID',
          cell: ReportStateCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Aggiornato',
          accessorKey: 'updatedAt',
          enableColumnFilter: false,
          enableSorting: true,
          cell: DatetimeCell
        },
        {
          header: 'Codice Cliente',
          accessorKey: 'customerCode',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Ragione sociale',
          accessorKey: 'businessName',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Tipo di file',
          accessorKey: 'returnType',
          cell: props => {
            let value = 'PDF/XLSX'
            switch (props.getValue() as number) {
              case 1:
                value = 'PDF'
                break
              case 2:
                value = 'XLSX'
                break
            }
            return value
          },
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Inoltro',
          accessorKey: 'sendingIsEnabled',
          cell: BooleanCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Email',
          accessorKey: 'sendingEmail',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false
        }
      ])

  }, [dispatch, navigate])

  return (
    <>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Report
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{
          marginTop: 2,
          marginBottom: 3
        }}>
          <FiltableServerTable<Report>
            name={'Report'}
            initHiddenColumns={initHide}
            columns={columns}
            handler={getReports}
            initPageIndex={0}
            actions={toolbarActions}
            initSortBy={initSortBy}
            initFilter={initFilter}
            filterStoreMode={FilterStoreMode.BACKUP}
            mobilePaginationBreakpoint={'sm'}
            id={'reports'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={true} initSortBy={initSortBy} onClick={onClick}
                          boxMode={row => {
                            return (<ReportMobileBox reportRow={row} />)
                          }} breakpoint={'sm'} />}
          </FiltableServerTable>
        </Grid>
      </Grid>
    </>
  )
}

const initHide = { 'customerCode': false, 'id': false }


export default Reports
