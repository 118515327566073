import { PropsWithChildren, ReactElement } from 'react'
import { ActionButton } from './actions/ActionButton'
import { DeleteSweep } from '@mui/icons-material'
import CardHeader from '@mui/material/CardHeader'
import { Row, RowData, Table } from '@tanstack/react-table'


export type ToolbarAction<T extends RowData> = (table: Table<T>, onRefresh: () => void) => ReactElement

type TableToolbarProps<T extends object> = {
  name?: string
  table: Table<T>
  actions?: Array<ToolbarAction<T>>
  onDelete?: (rows: Row<T>[]) => void
  onRefresh: () => void
}

export const TableToolbar = <T extends object>(
  {
    name,
    table,
    actions, onDelete, onRefresh
  }: PropsWithChildren<TableToolbarProps<T>>): ReactElement => {

  const {getSelectedRowModel} = table
  return (
    <CardHeader title={name} action={<div style={ {
      display: 'flex',
      padding: '0% 1.2%'
    }}>
      {onDelete && (
        <div>
          <ActionButton
            icon={<DeleteSweep />}
            onClick={() => {
              onDelete(getSelectedRowModel().flatRows)
            }}
            label='Cancella selezionate'
            variant='right'
            enabled={getSelectedRowModel().flatRows.length > 0}
          />
        </div>
      )}

      {actions !== undefined && actions.map((actionFunc, index) => <div
        key={index}>{actionFunc(table, onRefresh)}</div>)}
    </div>} />
  )
}
