import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import axios from 'axios'
import { RootState } from '../redux'
import { signOut, updateGroups } from '../redux/cognitoStore'
import { Store } from 'redux'

type RequestMiddleware = { onFulfilled?: (value: any) => any | Promise<any>, onRejected?: (error: any) => any }

export function useCognitoInterceptor(store: Store<RootState>) {
  useEffect(() => {
    const requestMiddleware = getRequestMiddleware(store)
    axios.interceptors.request.use(requestMiddleware.onFulfilled, requestMiddleware.onRejected)
    getSession(store).then(() => {
      const requestMiddleware = getRequestMiddleware(store)
      axios.interceptors.request.use(requestMiddleware.onFulfilled, requestMiddleware.onRejected)
    }).catch(e => {
      console.log(e)
    })
  }, [store])
}

const expSelector = (state: any) => {
  return state.cognito.exp as number
}

function getRequestMiddleware(store: Store<RootState>): RequestMiddleware {
  return {
    onFulfilled: async (config) => {
      try {
        const session = await Auth.currentSession()
        const token = session.getAccessToken().getJwtToken()
        if (!token) {
          store.dispatch(signOut())
          return Promise.reject('token missing')
        }
        const idToken = session.getIdToken().decodePayload()
        const exp = expSelector(store.getState())
        if (exp !== idToken.exp) {
          const groups: Array<string> = idToken['cognito:groups']
          const groupsSet = new Set<string>()
          for (let group of groups) {
            groupsSet.add(group)
          }
          store.dispatch(updateGroups({
            groups: groupsSet,
            exp: idToken.exp as number
          }))
        }
        config.headers['Authorization'] = 'Bearer ' + token

        return config
      } catch (e) {
        store.dispatch(signOut())
        return Promise.reject(e)
      }
    },
    onRejected: error => {
      store.dispatch(signOut())
      return Promise.reject(error)
    }
  }
}

const getSession = async (store: Store<RootState>) => {
  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    if (!token) {
      store.dispatch(signOut())
      return Promise.reject('token missing')
    }
    const idToken = session.getIdToken().decodePayload()
    const exp = expSelector(store.getState())
    if (exp !== idToken.exp) {
      const groups: Array<string> = idToken['cognito:groups']
      const groupsSet = new Set<string>()
      for (let group of groups) {
        groupsSet.add(group)
      }
      store.dispatch(updateGroups({
        groups: groupsSet,
        exp: idToken.exp as number
      }))
    }
  } catch (e) {
    store.dispatch(signOut())
    return Promise.reject(e)
  }
}