import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useRef
} from 'react'
import { ColumnFilter } from '@tanstack/react-table'


export interface FiltersState {
  tempFilters: MapObject
  currentFilters: MapObject
  originalFilters: MapObject

  //hide columns fields
  tempHideColumns: Record<string, boolean>
  tempHideCount: number
  currentHideColumns: Record<string, boolean>
  currentHideCount: number
}

export interface CachedFiltersState {
  filters: MapObject
  hiddenColumns: Record<string, boolean>
  originalFilters: MapObject
}

type ResetFilters = {
  type: 'resetFilters'
}

type DropFilter = {
  type: 'dropFilter'
  payload: {
    id: string
  }
}

type ConfirmFilters = {
  type: 'confirmFilters'
}

type ResetColumns = {
  type: 'resetColumns'
}

type ConfirmColumns = {
  type: 'confirmColumns'
}

type UpdateFilters = {
  type: 'updateFilter'
  payload: {
    id: string
    value: any
  }
}

type ToggleColumn = {
  type: 'toggleColumn'
  payload: string
}

interface IContextProps {
  state: FiltersState;
  dispatch: Dispatch<FiltersAction>
}


export type FiltersAction = ResetFilters | ConfirmFilters | UpdateFilters | ToggleColumn
  | ConfirmColumns | ResetColumns | DropFilter

const FilterStore = createContext({} as IContextProps)

export enum FilterStoreMode {
  DEFAULT,
  BACKUP
}

export const filtersReducer = (state: FiltersState, action: FiltersAction) => {
  switch (action.type) {
    case 'confirmFilters': {
      return {
        ...state,
        currentFilters: {
          ...state.tempFilters
        }
      }
    }
    case 'resetFilters': {
      return {
        ...state,
        tempFilters: {
          ...state.currentFilters
        }
      }
    }
    case 'confirmColumns': {
      return {
        ...state,
        currentHideColumns: {
          ...state.tempHideColumns
        },
        currentHideCount: state.tempHideCount
      }
    }
    case 'resetColumns': {
      return {
        ...state,
        tempHideColumns: {
          ...state.currentHideColumns
        },
        tempHideCount: state.currentHideCount
      }
    }
    case 'updateFilter': {

      const payload = action.payload

      if (payload.value != null) {
        return {
          ...state,
          tempFilters: {
            ...state.tempFilters,
            [action.payload.id]: action.payload.value
          }
        }
      }

      delete state.tempFilters[payload.id]
      return {
        ...state,
        tempFilters: {
          ...state.tempFilters,
        }
      }
    }
    case 'dropFilter': {
      const id = action.payload.id
      const originalFilter = state.originalFilters[id]
      if (originalFilter) {
        return {
          ...state,
          tempFilters: {
            ...state.tempFilters,
            [id]: state.originalFilters[id]
          },
          currentFilters: {
            ...state.currentFilters,
            [id]: state.originalFilters[id]
          }
        }
      }
      delete state.tempFilters[id]
      delete state.currentFilters[id]

      return {
        ...state,
        tempFilters: {
          ...state.tempFilters,
        },
        currentFilters: {
          ...state.currentFilters,
        }
      }
    }
    case 'toggleColumn': {
      let newTemp = (state.tempHideColumns[action.payload] === undefined ? true : !state.tempHideColumns[action.payload])
      let newTempCount = state.tempHideCount
      if (newTemp) {
        newTempCount++
      } else {
        newTempCount--
      }
      return {
        ...state,
        tempHideCount: newTempCount,
        tempHideColumns: {
          ...state.tempHideColumns,
          [action.payload]: newTemp
        }
      }
    }
    default:
      throw new Error()
  }
}

type FilterStoreProviderProps = {
  children: ReactNode
  initFilters: Array<ColumnFilter>
  initHide: Record<string, boolean>
  //for side effect
  initializedFilters: boolean
  setInitializedFilters: Dispatch<SetStateAction<boolean>>
  setFilterColumn: (filters: MapObject, hiddenColumns: MapObject) => void
  filterStoreMode: FilterStoreMode
  tableAfterMiddleware: (state: FiltersState, action: FiltersAction) => void,
  storeKey: string
}

type MapObject = {
  [key: string]: any
}

const buildDefaultState = (ColumnFilters: Array<ColumnFilter>, initHide: Record<string, boolean>) => {
  const initState: MapObject = {}
  for (const elem of ColumnFilters) {
    initState[elem.id] = elem.value
  }

  return {
    currentFilters: { ...initState },
    tempFilters: { ...initState },
    originalFilters: { ...initState },
    tempHideColumns: initHide ,
    currentHideColumns: initHide,
    currentHideCount: ColumnFilters.length,
    tempHideCount: Object.keys(initHide).length
  } as FiltersState
}

const initializer = (mode: FilterStoreMode, initColumnFilters: Array<ColumnFilter>,
                     initHiddenColumns: Record<string, boolean>, key: string) => () => {
  if (mode === FilterStoreMode.DEFAULT) {
    return buildDefaultState(initColumnFilters, initHiddenColumns)
  }

  const item = window.sessionStorage.getItem(key)
  if (!item) {
    return buildDefaultState(initColumnFilters, initHiddenColumns)
  }
  const backup = JSON.parse(item) as CachedFiltersState

  //restore backup
  const initFilters = backup.originalFilters
  const initHide = backup.hiddenColumns
  const filters = backup.filters

  return {
    currentFilters: { ...filters },
    tempFilters: { ...filters },
    originalFilters: { ...initFilters }, //already set by initializer
    tempHideColumns: initHide,
    currentHideColumns: initHide,
    currentHideCount: initFilters.length,
    tempHideCount: Object.keys(initHide).length
  } as FiltersState
}

const FilterStoreProvider: FunctionComponent<FilterStoreProviderProps> = (
  {
    children,
    initFilters,
    setFilterColumn,
    initHide,
    initializedFilters,
    setInitializedFilters,
    filterStoreMode,
    storeKey,
    tableAfterMiddleware
  }) => {

  const [state, dispatch] = useReducer(filtersReducer, {
    currentFilters: {},
    tempFilters: {},
    originalFilters: {},
    tempHideColumns: {},
    currentHideColumns: {},
    currentHideCount: 0,
    tempHideCount: 0,
    isBackedUp: false
  }, initializer(filterStoreMode, initFilters, initHide, storeKey))

  const actionRef = useRef<FiltersAction | null>(null)
  const decoDispatch = useCallback((action: FiltersAction) => {
    dispatch(action)
    actionRef.current = action
  }, [])

  //set table filters with context ones, with session backup restored in initializer
  useEffect(() => {
    if (!initializedFilters) {
      setInitializedFilters(true)
      setFilterColumn(state.currentFilters, state.currentHideColumns)
    }
  }, [initializedFilters, setFilterColumn, setInitializedFilters, state])

  //sync with session storage
  const doStore = filterStoreMode !== FilterStoreMode.DEFAULT && storeKey !== undefined
  useEffect(() => {
    if (doStore) {
      // state.isBackedUp = true
      window.sessionStorage.setItem(storeKey, JSON.stringify({
        filters: state.currentFilters,
        hiddenColumns: state.currentHideColumns,
        originalFilters: state.originalFilters
      } as CachedFiltersState))
    }
  }, [doStore, state, storeKey])


  useEffect(() => {
    if (!actionRef.current)
      return
    tableAfterMiddleware(state, actionRef.current)
    actionRef.current = null
  }, [actionRef, state, tableAfterMiddleware])

  return <FilterStore.Provider value={{ state, dispatch: decoDispatch }}>{children}</FilterStore.Provider>
}

export { FilterStore, FilterStoreProvider }
