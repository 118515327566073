import { styled } from '@mui/material/styles'
import {
  TableCell as MuiCell,
  TableCellProps as MuiCellProps,
  TableRow as MuiRow,
  TableRowProps as MuiRowProps,
  TableSortLabel as MuiSortLable,
  TableSortLabelProps
} from '@mui/material'

export const HeaderRow = styled(MuiRow)<MuiRowProps>((
  {
    theme
  }) => ({
  outline: 0,
  verticalAlign: 'middle',
  color: theme.palette.secondary.main,
  backgroundColor: '#FAFAFA',
  borderTop: '1.2px solid rgba(224, 224, 224, 1)',
  borderBottom: '1.2px solid rgba(224, 224, 224, 1)'
}))

interface HeadCellProps extends MuiCellProps {
  isActions: boolean,
  hasRowActions: boolean
}

export const HeadCell = styled(MuiCell,
  {
    shouldForwardProp: (prop) => prop !== 'hasRowActions' && prop !== 'isActions'
  })<HeadCellProps>((
  {
    theme,
    isActions,
    align,
    hasRowActions
  }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  ...(align === 'right' && {
    textAlign: 'right'
  }),
  ...(align === 'center' && {
    textAlign: 'center'
  }),
  ...(!hasRowActions && {
    margin: 0,
    padding: '0.5rem 0.7rem',
    fontWeight: 'bold',
    minWidth: '1%',
    borderBottom: '0',
    '&:not(:last-child)::after': {
      content: '""',
      backgroundColor: theme.palette.secondary.light,
      position: 'absolute',
      top: '30%',
      right: '0%',
      width: '2px',
      height: '36%'
    }
  }),
  ...(hasRowActions && {
    margin: 0,
    padding: '0.5rem 0.7rem',
    fontWeight: 'bold',
    minWidth: '1%',
    borderBottom: '0',
    backgroundColor: '#fafafa',
    '&:not(:nth-last-of-type(1)):not(:nth-last-of-type(2)):after': {
      content: '""',
      backgroundColor: theme.palette.secondary.light,
      position: 'absolute',
      top: '30%',
      right: '0%',
      width: '2px',
      height: '36%'
    }
  }),
  ...(isActions && {
    right: 0,
    position: 'sticky !important' as 'sticky'
  })
}))

export const TableSortLabel = styled(MuiSortLable)<TableSortLabelProps>(
  {
    '& svg': {
      width: 16,
      height: 16,
      marginTop: 0,
      marginLeft: 2
    }
  }
)

interface TableRowProps extends MuiRowProps {
  isStriped: boolean
}

export const TableRow = styled(MuiRow,
  {
    shouldForwardProp: (prop) => prop !== 'isStriped'
  })<TableRowProps>((
  {
    isStriped
  }) => ({
  outline: 0,
  display: 'flex',
  flex: '1 0 auto',
  minWidth: '334px',
  verticalAlign: 'middle',
  ...(isStriped && {
    backgroundColor: '#f2f2f2'
  })
}))

interface TableCellProps extends MuiCellProps {
  isStriped: boolean,
  isActions: boolean
}

export const TableCell = styled(MuiCell,{
  shouldForwardProp: (prop) => prop !== 'isStriped' && prop !== 'isActions'
})<TableCellProps>((
  {
    isStriped,
    isActions
  }) => ({
  margin: 0,
  padding: '0.5rem 0.7rem',
  width: '1%',
  fontSize: '0.875rem',
  boxSizing: 'border-box',
  ...(isStriped  && {
    backgroundColor: '#f2f2f2'
  }),
  ...(!isStriped  && {
    backgroundColor: '#ffffff'
  }),
  ...(isActions && {
    right: 0,
    position: 'sticky !important' as 'sticky',
    textAlign: 'right',
    flex: '0 0 auto',
    padding: '0.5rem 0.2rem'

  })
}))

export const MobileRow = styled('div',{
  shouldForwardProp: (prop) => prop !== 'isStriped' && prop !== 'variant'
})<{
  isStriped: boolean
  variant: 'clickable' | 'standard'
}>((
  {
    isStriped,
    variant
  }) => ({
  margin: 0,
  fontSize: '0.875rem',
  ...(variant === 'clickable'  && {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
      cursor: 'pointer'
    }
  }),
  ...(isStriped  && {
    backgroundColor: '#f2f2f2'
  }),
  ...(!isStriped  && {
    backgroundColor: '#ffffff'
  }),
}))

export const MobileCell = styled('div')({
  margin: 0,
  boxSizing: 'border-box',
  padding: '0.5rem',
  fontSize: '0.96rem',
  borderBottom: '1px solid  rgba(224, 224, 224, 1)'
})

