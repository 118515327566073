import React, { FunctionComponent, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Grid } from '@mui/material'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { updateSnackbar } from '../../../redux/snackbarStore'
import { Link as RouterLink } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getCustomers } from '../usecases/customer'
import Customer from '../models/Customer'
import RefreshButton from '../../../components/Table/actions/RefreshButton'
import CustomerMobileBox from './CustomerMobileBox'
import { FilterStoreMode } from '../../../components/Table/filters/filterStore'
import { MobileBody } from '../../../components/Table/MobileBody'
import { FiltrableClientTable } from '../../../components/Table/FiltrableClientTable'
import { ColumnDef, ColumnFilter, ColumnSort } from '@tanstack/react-table'
import DefaultColumnFilter from '../../../components/Table/filters/columns/DefaultColumnFilter'
import { ToolbarAction } from '../../../components/Table/TableToolbar'
import { ColumnsButton } from '../../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../../components/Table/actions/FilterButton'
import { fuzzyFilter, fuzzySort } from '../../../components/Table/filters/fns'
import { RenderedCell } from '../../../components/Table/cells'


const section = {
  marginTop: 2,
  marginBottom: 3
}

const Organization: FunctionComponent = () => {

  const dispatch = useAppDispatch()
  const { data, isLoading, refetch, dataUpdatedAt } = useQuery('organizationCustomers', () => getCustomers(), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  const toolbarActions = useMemo<ToolbarAction<Customer>[]>(() => {
    return [() => {
      return <RefreshButton onRefresh={() => {
        refetch()
      }} />
    }, (instance) => {
      return <ColumnsButton table={instance} />
    }, (instance) => {
      return <FilterButton table={instance} />
    }]
  }, [refetch])


  return (
    <>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='secondary'
          to='/'
          underline="hover"
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Profilo
        </Typography>
        <Typography color='textPrimary'>
          Organizzazione
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <FiltrableClientTable<Customer>
            name={'Aziende'}
            data={data}
            columns={columns}
            updatedAt={dataUpdatedAt}
            actions={toolbarActions}
            filterStoreMode={FilterStoreMode.BACKUP}
            forceIsLoading={isLoading}
            initPageIndex={0}
            initFilter={initFilter}
            initHiddenColumns={initHidden}
            initSortBy={initSort}
            mobilePaginationBreakpoint={'sm'}
            id={'credits'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={false} boxMode={row => {
                return (<CustomerMobileBox customerRow={row} />)
              }} breakpoint={'sm'} />}
          </FiltrableClientTable>
        </Grid>
      </Grid>
    </>
  )
}

const columns: ColumnDef<Customer>[] = [
  {
    header: 'Codice cliente',
    accessorKey: 'customerCode',
    enableColumnFilter: false,
    enableSorting: true,
  },
  {
    header: 'Ragione sociale',
    accessorKey: 'businessName',
    enableColumnFilter: true,
    cell: RenderedCell,
    filterFn: fuzzyFilter,
    sortingFn: fuzzySort,
    meta: {
      filter: DefaultColumnFilter
    },
    enableSorting: true,
  },
  {
    header: 'Indirizzo',
    accessorKey: 'address',
    cell: RenderedCell,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: 'Città',
    accessorKey: 'city',
    cell: RenderedCell,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: 'Provincia',
    accessorKey: 'provincia',
    cell: RenderedCell,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: props => {
      const splits = (props.getValue() as string).split(",")
      return(<>
        {splits.map( value => <div>{value.toLowerCase()}</div>)}
      </>)
    },
  }
]

const initSort: ColumnSort[] = [{ id: 'customerCode', desc: false }]
const initFilter: ColumnFilter[] = []
const initHidden: Record<string, boolean> = {}

export default Organization
