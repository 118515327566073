import axios, { AxiosError } from 'axios'
import { Collection } from '../../../components/Table/config'
import { Task } from '../models/Task'
import { Filters, SortingRule } from '@tanstack/react-table'
import { CreateTask } from '../models/CreateTask'
import { getCustomers } from './customer'

export const getTasks = async (pageIndex: number, pageSize: number, sortBy: SortingRule<Task>[], filters: Filters<Task>) => {
  try {
    let q = {}

    let url = `${process.env.REACT_APP_MYPAGOCO_HOST}/tasks/list`

    q = {
      ...q,
      fields: 'id,type,frequency,createdAt,description,createdAt,customerCode,returnType,sending,isActive',
      limit: pageSize,
      offset: pageIndex * pageSize
    }

    let bodyFilters = {}
    for (let filter of filters) {
      switch (filter.id) {
        case 'returnType': {
          bodyFilters = {
            ...bodyFilters,
            'returnType': filter.value,
          }
          break
        }
        case 'frequency': {
          bodyFilters = {
            ...bodyFilters,
            'frequency': filter.value,
          }
          break
        }
      }
    }

    q = {
      ...q,
      filters: bodyFilters
    }
    if (sortBy.length > 0) {
      q = {
        ...q,
        sort: `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`
      }
    }

    const response = await
      axios.post(url, q)
    const tasks: Array<Task> = []

    if (response.data.tasks == null) {
      return {
        list: [],
        size: 0
      } as Collection<Task>
    }

    const customers = await getCustomers()
    const customerMap = new Map<number,string>()

    for (let customer of customers) {
      customerMap.set(customer.customerCode, customer.businessName)
    }

    for (let tmp of response.data.tasks) {
      const task: Task = {
        id: tmp.id,
        type: tmp.type,
        createdAt : tmp.createdAt,
        frequency: tmp.frequency,
        description: tmp.description,
        customerCode: tmp.customerCode,
        businessName: customerMap.get(tmp.customerCode) || '',
        returnType: tmp.returnType,
        sendingIsEnabled: tmp.sending.isEnabled,
        sendingIsOptEmail: tmp.sending.optEmail,
        isActive: tmp.isActive
      }
      tasks.push(task)
    }

    return {
      list: tasks,
      size: response.data.size as number
    } as Collection<Task>
  } catch (e) {
    let axiosError = e as AxiosError
    if (axiosError.response?.status === 404) {
      return {
        list: [],
        size: 0
      } as Collection<Task>
    }
    throw e
  }
}

export const createTask = async (req: CreateTask) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/tasks/create`, req)
  } catch (e) {
    throw e
  }
}