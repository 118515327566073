import {FunctionComponent} from "react";
import {ActionButton} from "./ActionButton";
import RefreshIcon from "@mui/icons-material/Refresh";


type RefreshButtonProps = {
    onRefresh: () => void
}

export const RefreshButton: FunctionComponent<RefreshButtonProps> =
    ({
         onRefresh
     }) => {
        return (<ActionButton
            icon={<RefreshIcon/>}
            onClick={onRefresh}
            label='Ricarica'
            variant='right'
        />)
    }

export default RefreshButton