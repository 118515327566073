import { CellContext, RowData } from '@tanstack/table-core'
import { styled } from '@mui/material/styles'
import { formatCurrency, normalizeCurrency } from '../../utils/utils'
import { DateTime } from 'luxon'

export const SimpleDivCell = styled('div', {
  shouldForwardProp: (prop) => prop !== 'align'
})<{
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}>(({ align }) => ({
  textAlign: align ?? 'inherit'
}))

export const DivCell = styled('div', {
  shouldForwardProp: (prop) => prop !== 'align'
})<{
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}>(({ align }) => ({
  textAlign: align ?? 'inherit',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}))

export const BooleanCell = <TData extends RowData>({ getValue, column }: CellContext<TData, unknown>) => {
  return (
    <DivCell align={column.columnDef.meta?.align}>
      {getValue() === true ? 'si' : 'no'}
    </DivCell>)
}

export const RenderedCell = <TData extends RowData>({ getValue, column }: CellContext<TData, unknown>) => {
  return (
    <DivCell align={column.columnDef.meta?.align}>
      {getValue() as any}
    </DivCell>)
}

export const NormalizedCurrencyCell = <TData extends RowData>(
  {
    getValue,
    column
  }: CellContext<TData, unknown>) => {
  return (
    <DivCell align={column.columnDef.meta?.align}>
      {formatCurrency(normalizeCurrency(getValue() as number))}
    </DivCell>)
}

export const DatetimeCell = <TData extends RowData>({ getValue, column }: CellContext<TData, unknown>) => {
  return (
    <SimpleDivCell align={column.columnDef.meta?.align}>
      {DateTime.fromISO(getValue() as string).setZone('Europe/Rome').toFormat('dd/MM/yyyy, HH:mm:ss')}
    </SimpleDivCell>)
}

export const CurrencyCell = <TData extends RowData>({ getValue, column }: CellContext<TData, unknown>) => {
  return (
    <DivCell align={column.columnDef.meta?.align}>
      {formatCurrency(getValue() as number)}
    </DivCell>)
}

export const DateCell = <TData extends RowData>({ getValue, column }: CellContext<TData, unknown>) => {
  return (
    <div style={{ textAlign: column.columnDef.meta?.align }}>
      {DateTime.fromISO(getValue() as string).setZone('Europe/Rome').toFormat('dd/MM/yyyy')}
    </div>)
}