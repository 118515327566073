import { CellContext, RowData } from '@tanstack/table-core'
import { DivCell } from '../../components/Table/cells'
import { ReportState } from './ReportState'

const textifyState = (state: number) => {
  let value = 'IN LAVORAZIONE'
  switch (state) {
    case 3:
      value = 'INVIATO'
      break
    case 2:
      value = 'CREATO'
      break
  }
  return value
}

export const ReportStateCell = <TData extends RowData>({ getValue, column: {columnDef} }: CellContext<TData, unknown>) => {
  const value = getValue() as number
  return (<DivCell align={columnDef.meta?.align}>
    <ReportState state={value}>
      {textifyState(value)}
    </ReportState>
  </DivCell>)
}