import { CognitoUserAmplify } from '../../../custom'
import Device from '../models/Device'

const getCognitoDevices = async (ar: CognitoUserAmplify, paginationToken: string | undefined): Promise<CognitoDevicesResponse> => {
  return new Promise<any>((resolve, reject) => {
    ar.listDevices(60, paginationToken === undefined ? null : paginationToken, {
      onSuccess: data => {
        resolve(data)
      },
      onFailure: err => {
        reject(err)
        //console.log(err)
      }
    })
  })
}

export const getDevices = async (ar: CognitoUserAmplify) => {
  let pagination: string | undefined = undefined
  let stop = false
  let cognitoDevs: CognitoDevice[] = []
  let devs: Device[] = []
  while (!stop) {
    let resp: CognitoDevicesResponse = await getCognitoDevices(ar, pagination)
    for (let dev of resp.Devices) {
      let newDev: Device = {
        creatingDate: new Date(dev.DeviceCreateDate * 1000).toISOString(),
        key: dev.DeviceKey,
        lastAuthenticatedDate: new Date(dev.DeviceLastAuthenticatedDate * 1000).toISOString(),
        lastModifiedDate: new Date(dev.DeviceLastModifiedDate * 1000).toISOString(),
        lastIp: '',
        name: '',
        status: false
      }
      if (dev.DeviceAttributes.length === 3) {
        newDev.status = (dev.DeviceAttributes[0].Value === 'valid')
        newDev.name = dev.DeviceAttributes[1].Value
        newDev.lastIp = dev.DeviceAttributes[2].Value
      }
      devs.push(newDev)
    }
    cognitoDevs = cognitoDevs.concat(resp.Devices)
    if (resp.PaginationToken === undefined) {
      stop = true
      return devs
    } else {
      pagination = resp.PaginationToken
    }
  }
}

interface DeviceAttribute {
  Name: string;
  Value: string;
}

interface CognitoDevice {
  DeviceAttributes: DeviceAttribute[];
  DeviceCreateDate: number;
  DeviceKey: string;
  DeviceLastAuthenticatedDate: number;
  DeviceLastModifiedDate: number;
}

interface CognitoDevicesResponse {
  Devices: CognitoDevice[];
  PaginationToken: string | undefined;
}
