import React, { FunctionComponent, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Grid } from '@mui/material'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { updateSnackbar } from '../../../redux/snackbarStore'
import { getDevices } from '../usecases/devices'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Link as RouterLink } from 'react-router-dom'
import Device from '../models/Device'
import { useQuery } from 'react-query'
import RefreshButton from '../../../components/Table/actions/RefreshButton'
import { FilterStoreMode } from '../../../components/Table/filters/filterStore'
import { MobileBody } from '../../../components/Table/MobileBody'
import { FiltrableClientTable } from '../../../components/Table/FiltrableClientTable'
import { ToolbarAction } from '../../../components/Table/TableToolbar'
import { ColumnsButton } from '../../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../../components/Table/actions/FilterButton'
import { ColumnDef, ColumnFilter, ColumnSort } from '@tanstack/react-table'
import { BooleanFilter } from '../../../components/Table/filters/columns/BooleanFilter'
import PowerDeviceMobileBox from './DeviceMobileBox'
import DefaultColumnFilter from '../../../components/Table/filters/columns/DefaultColumnFilter'
import { fuzzyFilter, fuzzySort } from '../../../components/Table/filters/fns'
import { BooleanCell, DatetimeCell, RenderedCell } from '../../../components/Table/cells'
import { getBooleanChipContent } from '../../../components/Table/filters/chipbar'


const Devices: FunctionComponent = () => {
  const { user } = useAuthenticator(context => [context.user])
  const dispatch = useAppDispatch()

  const { data: devices, isLoading, refetch, dataUpdatedAt } = useQuery(['devices', user], () => getDevices(user), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  const toolbarActions = useMemo<ToolbarAction<Device>[]>(() => {
    return [() => {
      return <RefreshButton onRefresh={() => {
        refetch()
      }} />
    }, (table) => {
      return <ColumnsButton table={table} />
    }, (table) => {
      return <FilterButton table={table} />
    }]
  }, [refetch])

  return (
    <div>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='secondary'
          to='/'
          underline="hover"
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Profilo
        </Typography>
        <Typography color='textPrimary'>
          Dispositivi
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{
          marginTop: 2,
          marginBottom: 3
        }}>
          <FiltrableClientTable<Device>
            name={'Dispositivi'}
            data={devices}
            columns={columns}
            updatedAt={dataUpdatedAt}
            actions={toolbarActions}
            filterStoreMode={FilterStoreMode.BACKUP}
            forceIsLoading={isLoading}
            initPageIndex={0}
            initFilter={initFilter}
            initHiddenColumns={initHidden}
            initSortBy={initSort}
            mobilePaginationBreakpoint={'sm'}
            id={'credits'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={false} boxMode={row => {
                return (<PowerDeviceMobileBox deviceRow={row} />)
              }} breakpoint={'sm'} />}
          </FiltrableClientTable>
        </Grid>
      </Grid>
    </div>
  )
}

const columns: ColumnDef<Device>[] = [
  {
    header: 'Nome',
    accessorKey: 'name',
    enableColumnFilter: false,
    enableSorting: true,
    cell: RenderedCell,
  },
  {
    header: 'Ultimo IP',
    accessorKey: 'lastIp',
    cell: RenderedCell,
    size: 240,
    filterFn: fuzzyFilter,
    sortingFn: fuzzySort,
    meta: {
      filter: DefaultColumnFilter
    }
  },
  {
    header: 'Ultima autenticazione',
    accessorKey: 'lastAuthenticatedDate',
    enableColumnFilter: false,
    enableSorting: true,
    cell: DatetimeCell
  },
  {
    header: 'Valido',
    accessorKey: 'status',
    size: 80,
    meta: {
      filter: BooleanFilter,
      getChipContent: getBooleanChipContent,
    },
    cell: BooleanCell
  }
]

const initSort: ColumnSort[] = [{ id: 'lastAuthenticatedDate', desc: true }]
const initFilter: ColumnFilter[] = []
const initHidden: Record<string, boolean> = {}

export default Devices
