import React from 'react'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Amplify, I18n } from 'aws-amplify'
import { Provider } from 'react-redux'
import { store } from './redux'
import { Authenticator, Heading, View } from '@aws-amplify/ui-react'
import pagoco from './assets/images/pagoCO.svg'
import AmplifyConfigure from './amplify-configure'
import { ThemeProvider } from '@mui/material'
import theme from './theme'
import CssBaseline from '@mui/material/CssBaseline'
import { Translations } from '@aws-amplify/ui-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import '@aws-amplify/ui-react/styles.css'
import './index.css'
import { createRoot } from 'react-dom/client'


Amplify.configure(AmplifyConfigure)
I18n.setLanguage('it')
I18n.putVocabulariesForLanguage('it', {
  [Translations.SIGN_IN_ACTION]: 'Accedi',
  [Translations.SIGN_IN_TEXT]: 'Accedi',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Resetta la tua password',
  [Translations.RESET_YOUR_PASSWORD]: 'Resetta la tua password',
  'Send code': 'Inviami il codice',
  'Enter your username': 'Username',
  'Back to Sign In': 'Torna indietro',
  'Signing in': 'In autenticazione'
})


const components = {
  Header() {
    return (
      <View textAlign='center' padding={'1.5rem'}>
        <img src={pagoco} style={{
          width: '180px',
          margin: '0.4em'
        }} alt='logo' />
      </View>
    )
  },
  SignIn: {
    Header() {
      return (
        <Heading
          padding={`1.2rem 0 0 1.2rem`}
          level={3}
        >
          Accedi
        </Heading>
      )
    },

  }
}

const container = document.getElementById('root');
const root = createRoot(container!)

root.render(<React.StrictMode>
  <Authenticator initialState={'signIn'}
                 components={components}
                 hideSignUp={true}
                 formFields={{
                   signIn: {
                   }
                 }}
                 variation={'default'} loginMechanisms={['username', 'email']} signUpAttributes={[]} >

    {() => (
      <QueryClientProvider client={new QueryClient()}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <CssBaseline />
            <App />
          </Provider>
        </ThemeProvider>
      </QueryClientProvider>

    )}
  </Authenticator>
</React.StrictMode>);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
