import { RowData } from '@tanstack/table-core'
import { ColumnDef, Row } from '@tanstack/react-table'
import { RowActionsMenu } from './actions/RowActionsMenu'
import { HeaderCheckbox, RowCheckbox } from './TableStyles'

export const accessorFnHelper = <T extends RowData>(fn: (originalRow: T, index: number) => unknown) => {
  return <TData extends RowData>(tData: TData, i: number) => {
    return fn(tData as  unknown as T, i)
  }
}

export interface RowAction<T> {
  label: string
  onClick: (row: Row<T>, onRefresh?: () => void) => void
  disabled?: (row: Row<T>) => boolean;
}

export const withActions = <T extends RowData>(actions: RowAction<T>[], columns: ColumnDef<T>[]) => {
  return [...columns, {
    id: '_actions',
    cell: ({ row, table }) => (
      <RowActionsMenu row={row} actions={actions}
                      onRefresh={table.options.meta?.onRefresh}/>
    ),
    minSize: 34,
    size: 34,
    enableResizing: false
  } as ColumnDef<T>]
}
export const withSelection = <T extends RowData>(columns: ColumnDef<T>[]) => {
  return [{
    id: '_selector',
    header: ({ table }) => (
      <HeaderCheckbox
        color={'secondary'}
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        title={'Seleziona tutte le righe'} />
    ),
    cell: ({ row }) => (
      <RowCheckbox
        color={'secondary'}
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
        title={'Seleziona la riga'} />
    ),
    minSize: 45,
    size: 45,
    enableResizing: false
  } as ColumnDef<T>, ...columns]
}
export const withAsyncSelection = <T extends RowData>(columns: ColumnDef<T>[]) => {
  return [{
    id: '_selector',
    header: ({ table }) => (
      <HeaderCheckbox
        color={'secondary'}
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        title={'Seleziona tutte le righe visualizzate'} />
    ),
    cell: ({ row }) => (
      <RowCheckbox
        color={'secondary'}
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
        title={'Seleziona la riga'} />
    ),
    minSize: 45,
    size: 45
  } as ColumnDef<T>, ...columns]
}