import React, { FunctionComponent } from 'react'
import { Invoice } from '../models/Invoice'
import { formatCurrency, formatDate } from '../../../utils/utils'
import './InvoiceMobileBox.css'
import { Row } from '@tanstack/react-table'

const InvoiceMobileBox: FunctionComponent<{ rowInvoice: Row<Invoice> }> = ({ rowInvoice }) => {
  return (
    <div className="invoiceMobileBox">
      <div className="mobileDescriptions">
        <div className="invoice">
          <span className='space'>{rowInvoice.original.number}</span> {rowInvoice.original.description}
        </div>
        <div className="subRow">
          <span className='space'>
          {rowInvoice.original.accountingYear}</span> {formatDate(rowInvoice.original.date)}
        </div>
        <div className="subRow">
          {rowInvoice.original.businessName}
        </div>
        {rowInvoice.original.businessName !== rowInvoice.original.costCenter.description ?
          <div className="subRow">
            {rowInvoice.original.costCenter.description}
          </div> : null
        }
        <div className="subRow">
          <span className='space'>
          {rowInvoice.original.paymentType}
          </span> {formatDate(rowInvoice.original.expireAt)}
        </div>
      </div>
      <div className='amount'>
        <div>{formatCurrency(rowInvoice.original.total)}</div>
      </div>
    </div>
  )
}

export default InvoiceMobileBox