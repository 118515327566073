import './App.css'
import { styled } from '@mui/material/styles'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import { useCognitoInterceptor } from './hooks/useCognitoInterceptor'
import { RootState } from './redux'
import { useStore } from 'react-redux'
import { Store } from 'redux'
import '@aws-amplify/ui-react/styles.css'
import React from 'react'
import NotifySnackbar from './components/NotifySnackbar/NotifySnackbar'
import NavBar from './components/NavBar'
import Cards from './views/Cards/Cards'
import Transactions from './views/Transactions/Transactions'
import Card from './views/Card/Card'
import Invoices from './views/Invoices/Invoices'
import Tasks from './views/Tasks/Tasks'
import Credits from './views/Credits/Credits'
import Invoice from './views/Invoice/Invoice'
import Task from './views/Task/Task'
import Reports from './views/Reports/Reports'
import Report from './views/Report/Report'
import Devices from './views/Profile/components/Devices'
import Info from './views/Profile/components/Info'
import Organization from './views/Profile/components/Organization'

const Main = styled('main')(({ theme: { spacing } }) => ({
  width: `100%`,
  paddingTop: 58,
  overflowY: 'auto',
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
  paddingBottom: spacing(2),
  scrollbarColor: 'rgba(0,50,96,.7)',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,50,96,.7)',
    border: '3px solid white',
    borderRadius: '10px'
  }
}))

const App = () => {
  const store: Store<RootState> = useStore()
  useCognitoInterceptor(store)

  return (
    <Router>
      <div style={{
        display: 'flex'
      }}>
        <Header />
        <NavBar />
        <Main>
          <Routes>
            <Route
              path='/'
              element={<Cards />}
            />
            <Route
              path='/cards/:pan'
              element={<Card />}
            />
            <Route
              path='/transazioni'
              element={<Transactions />}
            />
            <Route
              path='/fatture'
              element={<Invoices />}
            />
            <Route
              path='/fatture/:id'
              element={<Invoice />}
            />
            <Route
              path='fidi/*'
              element={<Credits />}
            />
            <Route
              path='/reportistica-regole'
              element={<Tasks />}
            />
            <Route
              path='/reportistica-regole/:id'
              element={<Task />}
            />
            <Route
              path='/report'
              element={<Reports />}
            />
            <Route
              path='/report/:id'
              element={<Report />}
            />
            <Route
              path='/aziende'
              element={<Organization />}
            />
            <Route
              path='/profilo'
            >
              <Route index path={`/profilo/informazioni-generali`} element={<Info />} />
              <Route path={`/profilo/dispositivi`} element={<Devices />} />
              <Route path={`/profilo/organizzazione`} element={<Organization />} />
            </Route>
          </Routes>
        </Main>
        <NotifySnackbar />
      </div>
    </Router>
  )
}


export default App