import clsx from 'clsx'
import { CellContext, RowData } from '@tanstack/table-core'
import { DivCell } from '../../components/Table/cells'

export const CardState = <TData extends RowData>({ getValue, column: {columnDef} }: CellContext<TData, unknown>) => {
  const value = getValue() as number
  return (<DivCell align={columnDef.meta?.align}>
    <div className={clsx('root', {
      'off': value === undefined || value === 0 || value === 4,
      'on': value === 2,
      'onRun': value === 5 || value === 6,
      'freeze': value === 3 || value === 1
    })}>{columnDef.meta?.keyValues === undefined ? '' : columnDef.meta.keyValues[value.toString()]}</div>

  </DivCell>)
}