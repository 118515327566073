import { useCallback, useState } from 'react'
import { ActionButton } from './ActionButton'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import { Table } from '@tanstack/react-table'
import { ColumnsDialog } from '../filters/ColumnsDialog'

type Props<T extends object> = {
  table: Table<T>
}

export const ColumnsButton = <T extends object>(
  {
    table
  }: Props<T>)  => {
  const [filterOpen, setFilterOpen] = useState(false)
  const handleClose = useCallback(() => {
    setFilterOpen(false)
  }, [])
  const handleFilterClick = useCallback(
    () => {
      setFilterOpen(true)
    },
    [setFilterOpen]
  )
  return (<>
    <ActionButton
      icon={<ViewColumnIcon />}
      onClick={handleFilterClick}
      label='Seleziona colonne'
      variant='right'
    />
    <ColumnsDialog onClose={handleClose} show={filterOpen} table={table}/>
  </>)
}