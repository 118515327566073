import React, { FunctionComponent, useMemo } from 'react'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { Invoice } from './models/Invoice'
import { FilterStoreMode } from '../../components/Table/filters/filterStore'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { DateTime } from 'luxon'
import { getInvoices } from './usecases/invoices'
import { OnClick } from '../../components/Table/config'
import { ColumnDef, ColumnFilter } from '@tanstack/react-table'
import { DatesFilter } from '../../components/Table/filters/columns/DatesFilter'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import { FiltableServerTable } from '../../components/Table/FiltableServerTable'
import { MobileBody } from '../../components/Table/MobileBody'
import InvoiceMobileBox from './InvoiceMobileBox'
import { CurrencyCell, DateCell, RenderedCell } from '../../components/Table/cells'
import { getDatetimeChipContent } from '../../components/Table/filters/chipbar'
import { ColumnSort } from '@tanstack/table-core'
import { accessorFnHelper, RowAction, withActions } from '../../components/Table/helpers'

const toolbarActions: ToolbarAction<Invoice>[] = [(instance, onRefresh) => {
  return <RefreshButton onRefresh={onRefresh} />
}, (instance) => {
  return <ColumnsButton table={instance} />
}, (instance) => {
  return <FilterButton table={instance} />
}]

const Invoices: FunctionComponent = () => {
  const navigate = useNavigate()

  const onClick = useMemo<OnClick<Invoice>>(() => {
    return row => {
      navigate(`/fatture/${row.original.id}?customerCode=${row.original.customerCode}&number=${row.original.number}`)
    }
  }, [navigate])

  const columns = useMemo<ColumnDef<Invoice>[]>(() => {
      return withActions([
        {
          disabled: _ => false,
          label: 'Apri',
          onClick: (row) => {
            navigate(`/fatture/${row.original.id}?customerCode=${row.original.customerCode}&number=${row.original.number}`)
          }
        } as RowAction<Invoice>], [
        {
          header: 'Periodo contabile',
          accessorKey: 'accountingYear',
          meta: {
            align: 'right'
          },
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Ragione sociale',
          accessorKey: 'businessName',
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Tipo documento',
          accessorKey: 'description',
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Numero',
          accessorKey: 'number',
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Data',
          accessorKey: 'date',
          meta: {
            align: 'left',
            getChipContent: getDatetimeChipContent,
            filter: DatesFilter
          },
          cell: DateCell,
          enableColumnFilter: true,
          enableSorting: false
        },
        {
          header: 'Codice cliente',
          accessorKey: 'customerCode',
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Totale',
          accessorKey: 'total',
          cell: CurrencyCell,
          meta: {
            align: 'right'
          },
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Base Imponibile',
          accessorKey: 'taxBase',
          cell: CurrencyCell,
          meta: {
            align: 'right'
          },
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Totale IVA',
          accessorKey: 'totalVATAmount',
          cell: CurrencyCell,
          meta: {
            align: 'right'
          },
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Scadenza',
          accessorKey: 'expireAt',
          cell: DateCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Tipo pagamento',
          accessorKey: 'paymentType',
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'ID SDI',
          accessorFn: accessorFnHelper((originalRow: Invoice) => {
            return originalRow.sdi.id
          }),
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Data trasmissione SDI',
          accessorFn: accessorFnHelper((originalRow: Invoice) => {
            return originalRow.sdi.sentAt
          }),
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'ID Centro di costo',
          accessorFn: accessorFnHelper((originalRow: Invoice) => {
            return originalRow.costCenter.id
          }),
          meta: {
            align: 'right'
          },
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        },
        {
          header: 'Centro di costo Descr.',
          accessorFn: accessorFnHelper((originalRow: Invoice) => {
            return originalRow.costCenter.description
          }),
          cell: RenderedCell,
          enableColumnFilter: false,
          enableSorting: false
        }
      ])
    }
    , [navigate])

  return (<>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Fatture
        </Typography>
      </Breadcrumbs>
      <FiltableServerTable<Invoice>
        name={tableName}
        initHiddenColumns={{}}
        columns={columns}
        handler={getInvoices}
        initPageIndex={0}
        filterStoreMode={FilterStoreMode.BACKUP}
        actions={toolbarActions}
        initSortBy={initSortBy}
        initFilter={initFilters}
        mobilePaginationBreakpoint={'sm'}
        id={'invoices'}>
        {instance =>
          <MobileBody table={instance} hasRowActions={true} onClick={onClick} boxMode={row => {
            return (<InvoiceMobileBox rowInvoice={row} />)
          }} breakpoint={'sm'} />}
      </FiltableServerTable>
    </>
  )
}

const tableName = 'Fatture'
const initSortBy: ColumnSort[] = [{ id: 'date', desc: true }]
const initFilters: ColumnFilter[] = [{
  'id': 'date', value: [DateTime.local().minus({ month: 6 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), DateTime.local().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0
  })]
}]

export default Invoices
