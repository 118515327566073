import axios from 'axios'
import { CompanyCredit, Credit } from '../models/credit'

export const getCredits = async () => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cardCredits/list`)
    return response.data.credits as Credit[]
  } catch (e) {
    throw e
  }
}

type CompanyCreditData = {
  data: any
  option: any
}

export const getCompanyCredits = async () => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/companyCredits/list`)
    const result = response.data.credits as CompanyCredit[]
    let newDatas: Array<CompanyCreditData> = []
    for (let companyCredit of result) {
      let available = (companyCredit.periodMax - companyCredit.periodTotalAmount) / 100
      newDatas.push({
        data: {
          labels: ['Disponibile', 'Erogato'],
          datasets: [
            {
              data: [available, companyCredit.periodTotalAmount / 100],
              backgroundColor: [
                '#239840',
                '#FE6D2A'
              ],
              borderWidth: 1
            }
          ]
        },
        option: {
          circumference: 180,
          rotation: -90,
          plugins: {
            title: {
              display: true,
              text: [companyCredit.businessName, `Fatturazione ${companyCredit.billingPeriod} gg`]
            },
            legend: {
              position: 'bottom',
              labels: {
                font: {
                  size: 14
                }
              }
            }
          }
        }

      } as CompanyCreditData)
    }
    return newDatas
  } catch (e) {
    throw e
  }
}