import React, { FunctionComponent, useMemo } from 'react'
import { ColumnsButton } from '../../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../../components/Table/actions/FilterButton'
import RefreshButton from '../../../components/Table/actions/RefreshButton'
import { ToolbarAction } from '../../../components/Table/TableToolbar'
import { Report } from '../models/Report'
import { ColumnSort } from '@tanstack/table-core'
import { ColumnDef, ColumnFilter } from '@tanstack/react-table'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { RowAction, withActions } from '../../../components/Table/helpers'
import { updateSnackbar } from '../../../redux/snackbarStore'
import { downloadReport, getReports } from '../usecases/reports'
import { BooleanCell, DateCell, DatetimeCell, RenderedCell } from '../../../components/Table/cells'
import { ReportStateCell } from './ReportStateCell'
import { FiltableServerTable } from '../../../components/Table/FiltableServerTable'
import { MobileBody } from '../../../components/Table/MobileBody'
import ReportMobileBox from './ReportMobileBox'
import { SelectKeyValueFilter } from '../../../components/Table/filters/columns/SelectKeyValueFilter'


const toolbarActions: ToolbarAction<Report>[] = [(instance, onRefresh) => {
  return <RefreshButton onRefresh={onRefresh} />
}, (instance) => {
  return <ColumnsButton table={instance} />
}, (instance) => {
  return <FilterButton table={instance} />
}]

const initSortBy: ColumnSort[] = [{ id: 'createdAt', desc: true }]
const initFilter: ColumnFilter[] = []

type Props = {
  id: number
}

const Reports: FunctionComponent<Props> = ({ id }) => {

  const dispatch = useAppDispatch()
  const columns = useMemo<ColumnDef<Report>[]>(() => {
    return withActions([
        {
          disabled: row => row.original.reportStateID < 2,
          label: 'Scarica',
          onClick: async (row, onRefresh) => {
            try {
              dispatch(updateSnackbar({
                variant: 'success',
                message: `Download in preparazione...`
              }))
              const response = await downloadReport(row.original.id)
              let filename = `${row.original.id}.zip`
              const url = URL.createObjectURL(response)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                filename
              )
              document.body.appendChild(link)
              link.click()
              link.remove()
              URL.revokeObjectURL(url)
            } catch (e) {
              dispatch(updateSnackbar({
                variant: 'error',
                message: 'Download fallito'
              }))
            }
          }
        }
      ] as RowAction<Report>[],
      [
        {
          header: 'ID',
          accessorKey: 'id',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false,
          size: 200
        },
        {
          header: 'Creato',
          accessorKey: 'createdAt',
          enableColumnFilter: false,
          enableSorting: true,
          cell: DatetimeCell
        },
        {
          header: 'Da',
          accessorKey: 'from',
          enableColumnFilter: false,
          enableSorting: true,
          cell: DateCell
        },
        {
          header: 'A',
          accessorKey: 'to',
          enableColumnFilter: false,
          enableSorting: true,
          cell: DateCell
        },
        {
          header: 'Stato',
          accessorKey: 'reportStateID',
          cell: ReportStateCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Aggiornato',
          accessorKey: 'updatedAt',
          enableColumnFilter: false,
          enableSorting: true,
          cell: DatetimeCell
        },
        {
          header: 'Codice Cliente',
          accessorKey: 'customerCode',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Ragione sociale',
          accessorKey: 'businessName',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Tipo di file',
          accessorKey: 'returnType',
          cell: props => {
            let value = 'PDF/XLSX'
            switch (props.getValue() as number) {
              case 1:
                value = 'PDF'
                break
              case 2:
                value = 'XLSX'
                break
            }
            return value
          },
          filterFn: 'equals',
          meta: {
            keyValues: {
              0: 'PDF/XLSX',
              1: 'PDF',
              2: 'XLSX'
            },
           filter: SelectKeyValueFilter,
            isKeyIndexNumeric: true
          },
          enableSorting: false,
          enableColumnFilter: true
        },
        {
          header: 'Inoltro',
          accessorKey: 'sendingIsEnabled',
          cell: BooleanCell,
          enableSorting: false,
          enableColumnFilter: false
        },
        {
          header: 'Email',
          accessorKey: 'sendingEmail',
          cell: RenderedCell,
          enableSorting: false,
          enableColumnFilter: false
        }
      ])

  }, [dispatch])

  const source = useMemo(() => {
    return getReports(id)
  }, [id])

  return (
    <FiltableServerTable<Report>
      name={'Report'}
      initHiddenColumns={initHide}
      columns={columns}
      handler={source}
      initPageIndex={0}
      actions={toolbarActions}
      initSortBy={initSortBy}
      initFilter={initFilter}
      mobilePaginationBreakpoint={'sm'}
      id={`taskReports:${id}`}>
      {instance =>
        <MobileBody table={instance} hasRowActions={true} initSortBy={initSortBy} boxMode={row => {
          return (<ReportMobileBox reportRow={row} />)
        }} breakpoint={'sm'} />}
    </FiltableServerTable>
  )
}

const initHide = { 'customerCode': false, 'id': false }


export default Reports
