import { MouseEvent, useState } from 'react'
import { RowActionButton } from '../TableStyles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Menu, MenuItem } from '@mui/material'
import { Row, RowData } from '@tanstack/react-table'
import { RowAction } from '../helpers'

interface RowActionsMenuProps<T>  {
  row: Row<T>
  actions: RowAction<T>[]
  onRefresh?: () => void
}

const isDisabled = (action: RowAction<any>, row: Row<any>) =>
  action.disabled === undefined ? false : action.disabled(row);

export const RowActionsMenu = <T extends RowData>({actions, row, onRefresh}: RowActionsMenuProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (action: RowAction<any>) => () => {
    setAnchorEl(null);
    action.onClick(row, onRefresh)
  };

  return (
    <div>
      <RowActionButton
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </RowActionButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        onClose={handleClose}
      >
        {actions.map((option, index) => (
          <MenuItem key={`menu-${index}`} disabled={isDisabled(option, row)}
                    onClick={handleItemClick(option)}>
            <span>{option.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}