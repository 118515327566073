import React, { FunctionComponent, useCallback, useState } from 'react'
import { CreateReport } from '../models/CreateReport'
import useAppDispatch from '../../../hooks/useAppDispatch'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { updateSnackbar } from '../../../redux/snackbarStore'
import Customer from '../models/Customer'
import { ActionButton } from '../../../components/Table/actions/ActionButton'
import AddIcon from '@mui/icons-material/Add'
import { StepLabelProps } from '@mui/material/StepLabel/StepLabel'
import { AxiosError } from 'axios'
import { createReport } from '../usecases/reports'
import { getCustomers } from '../usecases/customer'
import { DateTime } from 'luxon'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { textifyReturnType } from '../../Task/utils'

interface DialogProps {
  open: boolean
  handleClose: () => void
  onRefresh: () => void
}

const steps = ['Scegli la ragione sociale e il vincolo di date', 'Scegli tipo di file e inoltro','Riepilogo']

const initialState: CreateReport = {
  customerCode: 0,
  from: DateTime.now().setZone('Europe/Rome').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  to: DateTime.now().setZone('Europe/Rome').set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0
  }),
  sending: {
    isEnabled: false,
    email: ''
  },
  returnType: 0
}

const MyStepLabel = styled(StepLabel)<StepLabelProps>(({ theme: { palette } }) => ({
  '&.MuiStepLabel-root': {
    '.MuiStepIcon-root.Mui-active': {
      color: palette.secondary.light
    },
    '.MuiStepIcon-root.Mui-completed': {
      color: palette.secondary.light
    },
    '.MuiStepLabel-iconContainer.Mui-active .MuiStepIcon-text': {
      color: '#ffffff',
      fill: 'rgba(255, 255, 255, 0.87)',
      fontWeight: 'bold'
    }
  }
}))

const CreateOrderDialog: FunctionComponent<DialogProps> = (
  {
    open,
    handleClose,
    onRefresh
  }) => {
  const [state, setState] = useState<CreateReport>(initialState)
  const [activeStep, setActiveStep] = useState(0)
  const [openSelect, setOpenSelect] = useState(false)
  const [isChoiceDid, setIsChoiceDid] = useState(false)
  const [customer, setCustomer] = useState<Customer | null>(null)

  const dispatch = useAppDispatch()

  const {
    data: customers
  } = useQuery('liteOrganizationCustomers', () => getCustomers(), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  const createMutation = useMutation<void, AxiosError, CreateReport>(createReport, {
    onError: () => {
      dispatch(updateSnackbar({
        variant: 'error',
        message: 'Errore di rete'
      }))
    },
    onSuccess: async () => {
      closeAndClean()
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Regola creata`
      }))
      onRefresh()
    }
  })

  const handleNext = () => {
    if (isChoiceDid) {
      if (activeStep === steps.length - 1) {
        createMutation.mutate(state)
        return
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const closeAndClean = () => {
    setState(initialState)
    setActiveStep(0)
    setCustomer(null)
    handleClose()
    return
  }

  const handleSelectClose = () => {
    setOpenSelect(false)
  }

  const handleSelectOpen = () => {
    setOpenSelect(true)
  }

  const invalidEmail = (state.sending.isEnabled && state.sending.email === "")
  const disableNext = !isChoiceDid || invalidEmail
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeAndClean}
        sx={{
          overflowY: 'visible'
        }}
        maxWidth='xs' fullWidth={true}
      >
        <DialogTitle>Crea report manuale</DialogTitle>
        <DialogContent sx={{
          overflowY: 'visible'
        }}>
          <div style={{ width: '100%' }}>
            <Stepper activeStep={activeStep} orientation='vertical'>
              <Step key={steps[0]}>
                <MyStepLabel>{steps[0]}</MyStepLabel>
                <StepContent sx={{marginTop: 1}}>
                  <Autocomplete
                    options={customers ? customers as Customer[] : []}
                    getOptionLabel={option => option.businessName}
                    sx={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}
                    onChange={(event, value) => {
                      if (value !== null && value !== undefined) {
                        setState({
                          ...state,
                          customerCode: value.customerCode
                        })
                        setIsChoiceDid(true)
                        setCustomer(value)
                      }
                    }}
                    value={customer}
                    renderInput={(params) => <TextField {...params} color={'secondary'} label={'Ragione sociale'}
                                                        variant='outlined' />}
                  />
                  <div style={{marginTop: '0.8rem', marginBottom: '0.6rem'}}>
                    <InputLabel shrink>
                      Vincolo di date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                      <Grid container justifyContent={'space-between'} alignItems={'baseline'} spacing={2}>
                        <Grid item sm={12} xs={12} >
                          <DatePicker
                            renderInput={(params) => <TextField {...params} color={'secondary'}  />}
                            label='Da'
                            inputFormat='dd/MM/yyyy'
                            value={state.from}
                            minDate={state.to.minus({ month: 12 })}
                            maxDate={state.to}
                            onChange={(date) => {
                              setState({
                                ...state,
                                from: date as DateTime
                              })
                            }} />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <DatePicker
                            renderInput={(params) => <TextField {...params} />}
                            label='a'
                            inputFormat='dd/MM/yyyy'
                            value={state.to}
                            maxDate={state.from.plus({ month: 12 })}
                            minDate={state.from}
                            onChange={(date) => {
                              setState({
                                ...state,
                                to: (date as DateTime).set({
                                  hour: 23,
                                  minute: 59,
                                  second: 59,
                                  millisecond: 0
                                })
                              })
                            }} />
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  </div>
                </StepContent>
              </Step>
              <Step key={steps[1]}>
                <MyStepLabel>{steps[1]}</MyStepLabel>
                <StepContent sx={{ marginTop: 1 }}>
                  <FormControl color={'secondary'} variant='outlined' fullWidth sx={({ spacing }) => ({})}>
                    <InputLabel>Tipo di ritorno</InputLabel>
                    <Select
                      open={openSelect}
                      onClose={handleSelectClose}
                      onOpen={handleSelectOpen}
                      value={state.returnType}
                      label='Tipo di ritorno'
                      onChange={event => {
                        setState({
                          ...state,
                          returnType: event.target.value as number
                        })
                      }}
                    >
                      <MenuItem value={0}>Tutti i file</MenuItem>
                      <MenuItem value={1}>PDF</MenuItem>
                      <MenuItem value={2}>XLSX</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel sx={{ marginTop: 2 }} control={
                    <Switch checked={state.sending.isEnabled}
                            color={'secondary'} onChange={(event, checked) => {
                      const newState = state
                      newState.sending.isEnabled = checked
                      if (!checked) {
                        newState.sending.email = ''
                      }
                      setState({
                        ...newState
                      })
                    }} />} label='Abilità invio email' />
                  <TextField sx={{ marginTop: 1 }}
                             color={'secondary'}
                             value={state.sending.email}
                             label='Email'
                             onChange={event => {
                               const newState = state
                               newState.sending.email = event.target.value as string
                               setState({
                                 ...newState
                               })
                             }}
                             disabled={!state.sending.isEnabled}
                             error={invalidEmail}
                             helperText={invalidEmail ? 'email vuota':  undefined}
                             variant='outlined' />
                </StepContent>
              </Step>
              <Step key={steps[2]}>
                <MyStepLabel>{steps[2]}</MyStepLabel>
                <StepContent>
                  <Table size='small'>
                    <TableBody>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Ragione sociale'}
                        </TableCell>
                        <TableCell
                          align='right'>{customer?.businessName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Da'}
                        </TableCell>
                        <TableCell
                          align='right'>{state.from.toFormat('dd/MM/yyyy')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'a'}
                        </TableCell>
                        <TableCell
                          align='right'>{state.to.toFormat('dd/MM/yyyy')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Tipo di file'}
                        </TableCell>
                        <TableCell
                          align='right'>{textifyReturnType(state.returnType)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Invio email'}
                        </TableCell>
                        <TableCell
                          align='right'>{ state.sending.isEnabled ? 'si' : 'no'}</TableCell>
                      </TableRow>
                      { state.sending.email.length > 0 ?  <TableRow>
                        <TableCell component='th' scope='row'>
                          {'Indirizzo email'}
                        </TableCell>
                        <TableCell
                          align='right'>{ state.sending.email}</TableCell>
                      </TableRow>: null}
                    </TableBody>
                  </Table>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color={'inherit'} onClick={() => {
            if (activeStep === 0) {
              closeAndClean()
            }
            setActiveStep((prevActiveStep) => prevActiveStep -1)
          }}>
            {activeStep === 0 ? 'Annulla' : 'Indietro'}
          </Button>
          <Button variant='contained' onClick={handleNext} color='secondary' disabled={disableNext}>
            {activeStep === steps.length - 1 ? 'Crea' : 'Avanti'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export type CreateOrderButtonProps = {
  onRefresh: () => void
}

export const CreateReportButton: FunctionComponent<CreateOrderButtonProps> = (
  {
    onRefresh
  }) => {
  const [open, setOpen] = useState(false)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const handleClick = useCallback(
    () => {
      setOpen(true)
    },
    [setOpen]
  )
  return (<>
    <ActionButton
      icon={<AddIcon />}
      onClick={handleClick}
      label='Crea regola'
      variant='right'
    />
    <CreateOrderDialog handleClose={handleClose} open={open} onRefresh={onRefresh} />
  </>)
}

export default CreateReportButton