import React from 'react'
import { formatCurrency, normalizeCurrency } from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { CellContext, RowData } from '@tanstack/table-core'
import { Credit } from './models/credit'
import { DivCell } from '../../components/Table/cells'

export const DailyCreditCell  = <TData extends RowData>({ getValue, column,row }: CellContext<TData, unknown>) => {
    const original = row.original as Credit
    const alert = original.dailyMax > 0 && original.dailyTotalAmount > 0 && (original.dailyTotalAmount / original.dailyMax) >= 0.75
    return (
      <DivCell align={column.columnDef.meta?.align}>
        {alert &&  <FontAwesomeIcon icon={faTriangleExclamation} className="imgFa" style={{
            fontSize: '1.0rem',
            marginRight: '0.24rem',
            color: '#C31916'
        }} />}
        {formatCurrency(normalizeCurrency(getValue() as unknown as number | undefined))}
    </DivCell>)
}