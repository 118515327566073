import React from 'react'
import { CellContext, RowData } from '@tanstack/table-core'
import { DivCell } from '../../components/Table/cells'

export const MaxPeriodCell  = <TData extends RowData>({ getValue, column,row }: CellContext<TData, unknown>) => {
  const value = getValue() as number | undefined
  return (
    <DivCell align={column.columnDef.meta?.align}>
      {value === 0 ? 'illimitato': value}
    </DivCell>)
}