import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { Invoice } from '../models/Invoice'
import { Collection } from '../../../components/Table/config'
import { Transaction } from '../../Transactions/models/Transaction'
import { Filters, SortingRule } from '@tanstack/react-table'

export const getInvoices = async (
  pageIndex: number,
  pageSize: number,
  sortBy: SortingRule<Invoice>[],
  filters: Filters<Transaction>
) => {
  try {
    const url = `${process.env.REACT_APP_MYPAGOCO_HOST}/invoices/list`

    const filter = filters[0];
    const gdate = DateTime.fromISO(filter.value[1]).toUTC()
    const ldate = DateTime.fromISO(filter.value[0]).toUTC()

    const body = JSON.stringify({
      sort: sortBy.length > 0 ? `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}` : null,
      limit: pageSize,
      offset: pageIndex * pageSize,
      from: ldate.toISO({ suppressMilliseconds: true }),
      to: gdate.toISO({ suppressMilliseconds: true }),
    })
    const response = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = response.data as {
      invoices: Invoice[]
      size: number
    }

    if (data.size === 0) {
      return {
        list: [],
        size: 0,
      } as Collection<Invoice>
    }

    return {
      list: data.invoices,
      size: data.size,
    } as Collection<Invoice>
  } catch (e) {
    const axiosError = e as AxiosError;
    if (axiosError.response?.status === 404) {
      return {
        list: [],
        size: 0,
      } as Collection<Invoice>
    }
    throw e
  }
}
