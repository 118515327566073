import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { Collection } from '../../../components/Table/config'
import { Transaction } from '../models/Transaction'
import { Filters, SortingRule } from '@tanstack/react-table'

export const getTransactions = async (pageIndex: number, pageSize: number, sortBy: SortingRule<Transaction>[], filters: Filters<Transaction>) => {
  try {
    let q = {}

    let url = `${process.env.REACT_APP_MYPAGOCO_HOST}/transactions/list`

    q = {
      ...q,
      fields: 'id,pan,customerCode,insertDatetime,requestDatetime,transactionNumber,cardTypeCode,datetime,amount,unitPrice,unit,km,residualValue,cardTypeCode,extraCode,terminalId,shop,product',
      limit: pageSize,
      offset: pageIndex * pageSize
    }

    let bodyFilters = {}
    for (let filter of filters) {
      switch (filter.id) {
        case 'pan': {
          let pans = []
          for (const toTrimPan of (filter.value as string).split(',')) {
            pans.push(toTrimPan.trim())
          }
          bodyFilters = {
            ...bodyFilters,
            'pans': pans
          }
          break
        }
        case 'insertDatetime': {
          const gdate = DateTime.fromISO(filter.value[1]).toUTC()
          const ldate = DateTime.fromISO(filter.value[0]).toUTC()

          bodyFilters = {
            ...bodyFilters,
            'from': ldate.toISO({ suppressMilliseconds: true }),
            'to': gdate.toISO({ suppressMilliseconds: true })
          }
          break
        }
      }
    }

    q = {
      ...q,
      filters: bodyFilters
    }
    if (sortBy.length > 0) {
      q = {
        ...q,
        sort: `${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`
      }
    }

    const response = await
      axios.post(url, q)
    const transactions: Array<Transaction> = []

    if (response.data.transactions == null) {
      return {
        list: [],
        size: 0
      } as Collection<Transaction>
    }

    for (let tmp of response.data.transactions) {
      const transaction: Transaction = {
        amount: tmp.amount,
        km: tmp.km,
        pan: tmp.pan,
        insertDatetime: tmp.insertDatetime,
        productCode: tmp.product.code,
        productDescription: tmp.product.description,
        shopCode: tmp.shop.code,
        shopDescription: tmp.shop.description,
        transactionNumber: tmp.transactionNumber,
        unit: tmp.unit,
        unitPrice: tmp.unitPrice
      }
      transactions.push(transaction)
    }

    return {
      list: transactions,
      size: response.data.size as number
    } as Collection<Transaction>
  } catch (e) {
    let axiosError = e as AxiosError
    if (axiosError.response?.status === 404) {
      return {
        list: [],
        size: 0
      } as Collection<Transaction>
    }
    throw e
  }
}