import { styled } from '@mui/material/styles'
import { FunctionComponent } from 'react'

const getStateLabel = (state: number | undefined) => {
  if (state === undefined) {
    return '-'
  }
  let label = 'bloccata'
  switch (state) {
    case  1:
      label = 'da attivare'
      break
    case  2:
      label = 'attiva'
      break
    case 3:
      label = 'congelata'
      break
    case 4:
      label = 'scaduta'
      break
    case 5:
      label = 'in lavorazione'
      break
    case 6:
      label = 'in aggiornamento'
      break
  }
  return label
}
export const CardState: FunctionComponent<{
  state: number | undefined
}> = ({ state }) => {

  return (
    <State state={state}>
      {getStateLabel(state)}
    </State>)
}

const State = styled('div', {
  shouldForwardProp: (prop) => prop !== 'state',
})<{
  state: number | undefined
}>(({ state }) => ({
  borderRadius: '5px',
  display: 'inline-block',
  padding: '0px 10px',
  fontWeight: 600,
  textTransform: 'uppercase',
  fontSize: '0.88rem',
  ...((!state === undefined || state === 0 || state === 4) && { //off
    color: '#D3000C',
    border: '1px solid #D3000C'
  }),
  ...(state === 2 && { //on
    color: '#019938',
    border: '1px solid #019938'
  }),
  ...((state === 5 || state === 6) && { //onRun
    color: '#C67943',
    border: '1px solid #C67943'
  }),
  ...((state === 3 || state === 1) && { //freeze
    color: '#939599',
    border: '1px solid #939599'
  })
}))