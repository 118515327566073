import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  useMediaQuery
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import React, { FunctionComponent, memo, ReactElement, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { guiSelector, updateMobileOpen } from '../../redux/guiStore'
import useAppDispatch from '../../hooks/useAppDispatch'
import { Link, useLocation } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import { CreditCard, Description, Devices as DevicesIcon, ExpandLess, ExpandMore, HomeWork } from '@mui/icons-material'

const drawerWidth = 240

const ListItemIcon= styled(MuiListItemIcon)(({theme}) =>({
  color: theme.palette.secondary.main
}))

const AccoListItemIcon= styled(MuiListItemIcon)(({theme}) =>({
  color: theme.palette.secondary.main,
  minWidth: theme.spacing(4)
}))

const DrawerContent = styled('div')(({theme}) => ({
  marginTop: 10,
  [theme.breakpoints.up('md')]: {
    marginTop: 58
  }
}))

const put = (story: string, against: string) => {
  return story === against
}

type ProfileRoute = {
  path: string
  label: string
  icon: ReactElement
}

const profileRoutes: Array<ProfileRoute> = [
  {
    path: '/profilo/informazioni-generali',
    label: 'Informazioni generali',
    icon: <InfoIcon />
  },
  {
    path: '/profilo/organizzazione',
    label: 'Organizzazione',
    icon: <HomeWork />
  },
  {
    path: '/profilo/dispositivi',
    label: 'Dispositivi',
    icon: <DevicesIcon />
  }
]

const routes: Array<Route> = [
  {
    path: '/',
    label: 'Cards'
  },
  {
    path: '/transazioni',
    label: 'Transazioni'
  },
  {
    path: '/fatture',
    label: 'Fatture'
  },
  {
    path: '/fidi',
    label: 'Fidi'
  }
]

const oroutes: Array<Route> = [
  {
    path: '/aziende',
    label: 'Aziende'
  }
]

const croutes: Array<{
  path: string
  label: string
}> = [
  {
    path: '/reportistica-regole',
    label: 'Regole'
  },
  {
    path: '/report',
    label: 'Report'
  }
]

const Bar = styled('nav')((({theme}) => ({
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    flexShrink: 0
  }
})))

const NavBar: FunctionComponent = () => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useAppDispatch()


  useEffect(() => {
    if (matches) {
      dispatch(updateMobileOpen({ mobileOpen: false }))
    }
  }, [matches, dispatch])

  return (
    <Bar>
      {
        matches ?
          <DesktopNav pathname={pathname} /> :
          <MobileNav pathname={pathname} />
      }
    </Bar>)
}

const DesktopNav = memo<{ pathname: string }>(({ pathname }) => {

  return (
    <Drawer
      sx={(theme) => ({
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          backgroundColor: '#fafafa',
          paddingRight: theme.spacing(1)
        },
      })}
      variant='permanent'
      open
    >
      {
        <DrawerContent>
          {pathname.includes('/profilo') ?
            <List>
              {profileRoutes.map((route, index) =>
                <RootNavItemButton key={index} selected={put(pathname, route.path)}
                                component={Link}
                                to={route.path}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.label} />
                </RootNavItemButton>)}
            </List> :
            <List>
              <SubNav groupName={'Cards'} icon={<CreditCard/>} routes={routes} pathname={pathname} />
              <SubNav groupName={'Reportistica'} icon={<Description/>} routes={croutes} pathname={pathname} />
              <SubNav groupName={'Organizzazione'} icon={<HomeWork/>} routes={oroutes} pathname={pathname} />
            </List>}

        </DrawerContent>}
    </Drawer>)
})

type Route = {
  path: string
  label: string
}

const SubNav: FunctionComponent<{ groupName: string, routes: Route[], pathname: string, icon: ReactElement }> =
  ({
     groupName, routes, pathname, icon
   }) => {

    const [open, setOpen] = React.useState(true)
    const handleClick = () => {
      setOpen(!open)
    }

    return (
      <>
        <SubNavButton disableGutters={true} onClick={handleClick}>
          <AccoListItemIcon>
            {icon}
          </AccoListItemIcon>
          <ListItemText primary={groupName} disableTypography={true} primaryTypographyProps={{
            sx: {
              fontSize: '1rem',
              color: '#003260',
              fontWeight: 700
            }
          }} />
          <AccoListItemIcon>
            {open ? <ExpandLess /> : <ExpandMore />}
          </AccoListItemIcon>
        </SubNavButton>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {routes.map((route, index) =>
              <SubNavItemButton key={index} disableGutters={true}
                          selected={put(pathname, route.path)}
                          component={Link}
                          to={route.path}>
                <ListItemText primary={route.label} disableTypography={true} />
              </SubNavItemButton>)}
          </List>
        </Collapse>
      </>
    )
}

const RootNavItemButton = styled(ListItemButton)({
    borderRadius: '0 50px 50px 0',
    paddingTop: '4px',
    paddingBottom: '4px',
    fontSize: '0.9rem',
    fontWeight: 500,
    '&.Mui-selected': {
      color: '#1967d2',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    color: 'rgb(84,91,100)',
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiListItemIcon-root': {
        color: '#1967d2'
      },
      '& .MuiListItemText-root': {
        color: '#1967d2',
        fontWeight: 700
      }
    }
  }
) as typeof ListItemButton

const SubNavButton = styled(ListItemButton)(({ theme: { spacing } }) => ({
  borderRadius: '0 50px 50px 0',
  padding: '4px 0',
  fontSize: '1rem',
  color: '#003260',
  fontWeight: 700,
  '&.Mui-selected': {
    color: '#1967d2'
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& .MuiListItemIcon-root': {
      color: '#1967d2'
    },
    '& .MuiListItemText-root': {
      color: '#1967d2'
    }
  },
  paddingLeft: spacing(2)

}))

const SubNavItemButton = styled(ListItemButton)(({ theme: { spacing } }) => ({
  borderRadius: '0 50px 50px 0',
  padding: '4px 0',
  fontSize: '0.9rem',
  fontWeight: 500,
  color: 'rgb(84,91,100)',
  '&.Mui-selected': {
    color: '#1967d2',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& .MuiListItemIcon-root': {
      color: '#1967d2'
    },
    '& .MuiListItemText-root': {
      color: '#1967d2',
      fontWeight: 700
    }
  },
  paddingLeft: spacing(6)
})) as typeof ListItemButton

const MobileNav = memo<{ pathname: string }>(({ pathname }) => {
  const theme = useTheme()
  const { mobileOpen } = useSelector(guiSelector)
  const dispatch = useAppDispatch()

  const handleDrawerToggle = useCallback(() => {
    dispatch(updateMobileOpen({ mobileOpen: false }))
  }, [dispatch])


  return (
    <Drawer
      variant='temporary'
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={(theme) => ({
        position: 'absolute',
        marginTop: '48px',
        '& .MuiBackdrop-root': {
          marginTop: '48px'
        },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          marginTop: '48px',
          paddingRight: theme.spacing(1)
        },
      })}
      ModalProps={{
        keepMounted: true
      }}
    >
      <DrawerContent>
        {pathname.includes('/profilo') ?
          <List>
            {profileRoutes.map((route, index) =>
              <RootNavItemButton key={index} selected={put(pathname, route.path)}
                              onClick={handleDrawerToggle}
                              component={Link}
                              to={route.path}>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.label} />
                <ListItemButton component={Link} to={""}></ListItemButton>
              </RootNavItemButton>)}
          </List> :
          <List>
            <SubNav groupName={'Cards'} icon={<CreditCard/>} routes={routes} pathname={pathname} />
            <SubNav groupName={'Reportistica'} icon={<Description/>} routes={croutes} pathname={pathname} />
            <SubNav groupName={'Organizzazione'} icon={<HomeWork/>} routes={oroutes} pathname={pathname} />
          </List>}

      </DrawerContent>
    </Drawer>)
})

export default NavBar