import React, { useCallback, useContext, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FilterStore } from '../filterStore'
import { FilterProps } from '@tanstack/react-table'

export const SelectPrefilteredColumnFilter = <T extends object>(
  {
    column: {
      columnDef,
      id,
      getFacetedRowModel
    }
  }: FilterProps<T>) => {
  const { state, dispatch } = useContext(FilterStore)
  const [value, setValue] = useState(state.tempFilters[id] !== undefined ? state.tempFilters[id] : '')

  const options = React.useMemo(() => {
    const options = new Set<any>()
    getFacetedRowModel().rows.forEach((row) => {
      options.add(row.getValue(id))
    })
    return [...Array.from(options.values())]
  }, [getFacetedRowModel, id])

  const onChange = useCallback((event: SelectChangeEvent<{ value: any }>) => {
    setValue(event.target.value)
    dispatch({ type: 'updateFilter', payload: { id: id, value: event.target.value } })
  }, [dispatch, id])
  const label = columnDef.header as string


  return (
    <FormControl variant='filled' color={'secondary'} style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
      <InputLabel id={`label-${id}-filter`}>{label}</InputLabel>
      <Select
        id={`${id}-filter`}
        value={value}
        onChange={onChange}
      >
        <MenuItem key={'all'} value={''}>Tutti</MenuItem>
        {options.length > 0 ? options.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        )) : null}
      </Select>
    </FormControl>
  )
}