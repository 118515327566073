import React, { FunctionComponent, useMemo, useState } from 'react'
import useAppDispatch from '../../hooks/useAppDispatch'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { Card } from './models/Card'
import { FilterStoreMode } from '../../components/Table/filters/filterStore'
import { activate, freeze, getCards, lock, unfreeze } from './usecases/cards'
import { updateSnackbar } from '../../redux/snackbarStore'
import ConfirmChangeStatusDialog from '../../components/ConfirmChangeStatusDialog'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { OnClick, SortByElem } from '../../components/Table/config'
import './Cards.css'
import { FiltrableClientTable } from '../../components/Table/FiltrableClientTable'
import { MobileBody } from '../../components/Table/MobileBody'
import { ColumnDef, ColumnFilter } from '@tanstack/react-table'
import {
  AutocompletePrefilteredColumnFilter
} from '../../components/Table/filters/columns/AutocompletePrefilteredColumnFilter'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import { SelectKeyValueFilter } from '../../components/Table/filters/columns/SelectKeyValueFilter'
import { CardState } from './CardState'
import { MaxPeriodCell } from './MaxPeriodCell'
import CardMobileBox from './CardMobileBox'
import { DateCell, RenderedCell } from '../../components/Table/cells'
import { withActions } from '../../components/Table/helpers'


type CardAction = {
  open: boolean,
  pan: string
  originalIndex: number
}

const getErrorLabel = (response: any) => {
  const errorCode = response.errorCode
  let message = 'Errore di rete'
  switch (errorCode) {
    case 100 :
      message = 'Carta già congelata'
      break
    case 101 :
      message = 'Carta già abilitata'
      break
    case 102 :
      message = 'Carta già attiva'
      break
    case 103 :
      message = 'Carta non configurata. Contattare l\'assistenza'
      break
    case 104 :
      message = 'Carta non bloccabile'
      break
    case 1 :
      message = 'Carta non trovata'
      break
    default:
      break
  }

  return message

}


const Cards: FunctionComponent = () => {
  const navigate = useNavigate()

  const [pageIndex, setPageIndex] = useState(0)
  const dispatch = useAppDispatch()

  const queryClient = useQueryClient()

  const [openFreeze, setOpenFreeze] = useState<CardAction>({
    open: false,
    pan: '',
    originalIndex: 0
  })
  const [openUnfreeze, setOpenUnfreeze] = useState<CardAction>({
    open: false,
    pan: '',
    originalIndex: 0
  })
  const [openActivate, setOpenActivate] = useState<CardAction>({
    open: false,
    pan: '',
    originalIndex: 0
  })
  const [openLock, setOpenLock] = useState<CardAction>({
    open: false,
    pan: '',
    originalIndex: 0
  })

  const { data, isLoading, refetch, dataUpdatedAt } = useQuery('cards', () => getCards(), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  //toolbar actions
  const toolbarActions = useMemo<ToolbarAction<Card>[]>(() => {
    return [() => {
      return <RefreshButton onRefresh={() => {
        refetch()
      }} />
    }, (table) => {
      return <ColumnsButton table={table} />
    }, (table) => {
      return <FilterButton table={table} />
    }]
  }, [refetch])

  const columns = useMemo(() => {
    const a: ColumnDef<Card>[] = withActions([
      {
        disabled: _ => false,
        label: 'Apri',
        onClick: (row) => {
          navigate(`/cards/${row.original.pan}`)
        }
      },
      {
        disabled: (row) => (row.original.state !== 1 || row.original.isActive),
        onClick: (row) => {
          setOpenActivate({
            pan: row.original.pan,
            open: true,
            originalIndex: row.index
          })
        },
        label: 'Attiva'
      },
      {
        disabled: (row) => row.original.state !== 3,
        onClick: (row) => {
          setOpenUnfreeze({
            pan: row.original.pan,
            open: true,
            originalIndex: row.index
          })
        },
        label: 'Scongela'
      },
      {
        disabled: (row) => row.original.state !== 2,
        onClick: (row) => {
          setOpenFreeze({
            pan: row.original.pan,
            open: true,
            originalIndex: row.index
          })
        },
        label: 'Congela'
      },
      {
        disabled: (row) => row.original.state === 0 || row.original.state > 3,
        onClick: (row) => {
          setOpenLock({
            pan: row.original.pan,
            open: true,
            originalIndex: row.index
          })
        },
        label: 'Blocca'
      }], [
      {
        header: 'Pan',
        accessorKey: 'pan',
        cell: RenderedCell,
        meta: {
          align: 'left',
          filter: AutocompletePrefilteredColumnFilter
        },
        enableColumnFilter: true, //True,
        enableSorting: true
      },
      {
        header: 'Codice cliente',
        accessorKey: 'customerCode',
        meta: {
          align: 'right'
        },
        minSize: 30,
        cell: RenderedCell,
        enableSorting: false
      },
      {
        header: 'Centro di costo',
        accessorKey: 'destinID',
        meta: {
          align: 'right'
        },
        minSize: 30,
        cell: RenderedCell,
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        header: 'Stato',
        accessorKey: 'state',
        cell: CardState,
        filterFn: 'equals',
        meta: {
          keyValues: {
            0: 'bloccata',
            1: 'da attivare',
            2: 'attiva',
            3: 'congelata',
            4: 'scaduta',
            5: 'in lavorazione',
            6: 'in aggiornamento'
          },
          filter: SelectKeyValueFilter,
          isKeyIndexNumeric: true
        },
        // size: 160,
        enableColumnFilter: true,
        enableSorting: true
      },
      {
        header: 'Ragione sociale',
        accessorKey: 'businessName',
        cell: RenderedCell,
        meta: {
          filter: AutocompletePrefilteredColumnFilter
        },
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: 'Descrizione',
        accessorKey: 'description',
        cell: RenderedCell,
        meta: {
          filter: AutocompletePrefilteredColumnFilter
        },
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: 'Targa',
        accessorKey: 'plate',
        cell: RenderedCell,
        meta: {
          filter: AutocompletePrefilteredColumnFilter
        },
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: 'Utilizzatore',
        cell: RenderedCell,
        meta: {
          filter: AutocompletePrefilteredColumnFilter
        },
        accessorKey: 'user',
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: 'Veicolo',
        cell: RenderedCell,
        accessorKey: 'vehicle',
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        header: 'Max giornaliero',
        accessorKey: 'dailyMax',
        cell: RenderedCell,
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        header: 'Max periodo',
        accessorKey: 'periodMax',
        enableColumnFilter: false,
        enableSorting: false,
        cell: MaxPeriodCell
      },
      {
        header: 'Scadenza',
        accessorKey: 'expireAt',
        enableColumnFilter: false,
        enableSorting: true,
        cell: DateCell
      }
    ] as ColumnDef<Card>[])
    return a
  }, [navigate])

  const freezeMutation = useMutation<void, AxiosError, {
    pan: string
    rowIndex: number
  }>(freeze, {
    onError: (error) => {
      let message = 'Errore di rete'

      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan, rowIndex }) => {
      await queryClient.cancelQueries('cards')
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} congelata`
      }))
      const item = window.sessionStorage.getItem('CardsIndex')
      if (!item) {
        return
      }

      queryClient.setQueryData('cards', old => {
        const cards = old as Card[]
        cards[rowIndex].state = 3
        return [...cards]
      })
      //setRerender(prevState => prevState + 1)
      setPageIndex(parseInt(item, 10))

    }
  })


  const unfreezeMutation = useMutation<void, AxiosError, {
    pan: string
    rowIndex: number
  }>(unfreeze, {
    onError: (error, { pan }, context) => {
      let message = 'Errore di rete'
      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan, rowIndex }) => {
      await queryClient.cancelQueries('cards')
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} scongelata`
      }))
      const item = window.sessionStorage.getItem('CardsIndex')
      if (!item) {
        return
      }
      queryClient.setQueryData('cards', old => {
        const cards = old as Card[]
        cards[rowIndex].state = 2
        return cards
      })
      setPageIndex(parseInt(item, 10))
    }
  })

  const activateMutation = useMutation<void, AxiosError, {
    pan: string
    rowIndex: number
  }>(activate, {
    onMutate: () => {
      setOpenActivate({
        open: false,
        pan: '',
        originalIndex: 0
      })
    },
    onError: (error) => {
      let message = 'Errore di rete'
      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan }) => {
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} attivata`
      }))
      const item = window.sessionStorage.getItem('CardsIndex')
      if (!item) {
        return
      }
      await queryClient.invalidateQueries(['cards'])
      setPageIndex(parseInt(item, 10))
    }
  })

  const lockMutation = useMutation<void, AxiosError, {
    pan: string
    rowIndex: number
  }>(lock, {
    onMutate: () => {
      setOpenLock({
        open: false,
        pan: '',
        originalIndex: 0
      })
    },
    onError: (error) => {
      let message = 'Errore di rete'
      if (error.response !== undefined) {
        message = getErrorLabel(error.response.data)
      }
      dispatch(updateSnackbar({
        variant: 'error',
        message: message
      }))
    },
    onSuccess: async (data, { pan, rowIndex }) => {
      await queryClient.cancelQueries('cards')
      dispatch(updateSnackbar({
        variant: 'success',
        message: `Carta ${pan} bloccata`
      }))
      const item = window.sessionStorage.getItem('CardsIndex')
      if (!item) {
        return
      }

      queryClient.setQueryData('cards', old => {
        const cards = old as Card[]
        cards[rowIndex].state = 0
        return cards
      })
      setPageIndex(parseInt(item, 10))
    }
  })

  const onClick = useMemo<OnClick<Card>>(() => {
    return row => {
      navigate(`/cards/${row.original.pan}?customerCode=${row.original.customerCode}`)
    }
  }, [navigate])

  return (<>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Cards
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{
          marginTop: 2,
          marginBottom: 3
        }}>
          <FiltrableClientTable<Card>
            name={cardName}
            data={data}
            columns={columns}
            updatedAt={dataUpdatedAt}
            actions={toolbarActions}
            filterStoreMode={FilterStoreMode.BACKUP}
            forceIsLoading={isLoading}
            initPageIndex={pageIndex}
            initFilter={initFilters}
            initHiddenColumns={newInitHidden}
            initSortBy={initSort}
            mobilePaginationBreakpoint={'sm'}
            id={'cards'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={true} onClick={onClick} boxMode={row => {
                return (<CardMobileBox rowCard={row} />)
              }} breakpoint={'sm'} />}
          </FiltrableClientTable>
        </Grid>
      </Grid>
      <ConfirmChangeStatusDialog
        title={`Congela carta ${openFreeze.pan}`}
        open={openFreeze.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Congela'}
        handleLaunch={() => {
          setOpenFreeze({
            open: false,
            pan: '',
            originalIndex: 0
          })
          freezeMutation.mutate({
            pan: openFreeze.pan,
            rowIndex: openFreeze.originalIndex
          })
        }}
        handleClose={() => {
          setOpenFreeze({
            open: false,
            pan: '',
            originalIndex: 0
          })
        }}>
        Se non riesci a trovare la tua Carta oppure vuoi renderla inutilizzabile senza bloccarla, puoi congelarla per
        impedire che venga utilizzata per ulteriori acquisti. <br />
        Quando è congelata, la tua Carta non può essere utilizzata per transazioni nei punti vendita aderenti al
        circuito pagoCO. <br />
        Se ritieni che la tua Carta possa essere stata rubata o sospetti eventuali addebiti non autorizzati, ti
        preghiamo di procedere al blocco.
      </ConfirmChangeStatusDialog>
      <ConfirmChangeStatusDialog
        title={`Scongela carta ${openUnfreeze.pan}`}
        open={openUnfreeze.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Scongela'}
        handleLaunch={() => {
          setOpenUnfreeze({
            open: false,
            pan: '',
            originalIndex: 0
          })
          unfreezeMutation.mutate({
            pan: openUnfreeze.pan,
            rowIndex: openUnfreeze.originalIndex
          })
        }}
        handleClose={() => {
          setOpenUnfreeze({
            open: false,
            pan: '',
            originalIndex: 0
          })
        }}>
        Per poter tornare ad utilizzare la tua Carta precedemente congelata, puoi procedere a riabilitarla. <br />
        Quando abilitata, la tua Carta può essere utilizzata per transazioni nei punti vendita aderenti al
        circuito pagoCO.
      </ConfirmChangeStatusDialog>
      <ConfirmChangeStatusDialog
        title={`Attiva carta ${openActivate.pan}`}
        open={openActivate.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Attiva'}
        handleLaunch={() => {
          activateMutation.mutate({
            pan: openActivate.pan,
            rowIndex: openActivate.originalIndex
          })
        }}
        handleClose={() => {
          setOpenActivate({
            open: false,
            pan: '',
            originalIndex: 0
          })
        }}>
        La tua Carta è in attesa di essere attivata, se ti è già stata consegnata puoi procedere ad attivarla; invieremo
        il codice PIN via SMS o EMAIL ai tuoi recapiti precedentemente registrati.
      </ConfirmChangeStatusDialog>
      <ConfirmChangeStatusDialog
        title={`Blocca carta ${openLock.pan}`}
        open={openLock.open}
        cancelLabel={'Annulla'}
        confirmLabel={'Blocca'}
        handleLaunch={() => {
          lockMutation.mutate({
            pan: openLock.pan,
            rowIndex: openLock.originalIndex
          })
        }}
        handleClose={() => {
          setOpenLock({
            open: false,
            pan: '',
            originalIndex: 0
          })
        }}>
        Se vuoi rendere permanentemente inutilizzabile la tua Carta, puoi procedere a bloccarla. <br />
        Quando è bloccata, la tua Carta non potrà essere utilizzata per transazioni nei punti vendita aderenti al
        circuito pagoCO, ne successivamente riattivata.
      </ConfirmChangeStatusDialog>
    </>
  )
}

const cardName = 'Cards'

const initFilters: ColumnFilter[] = []
const newInitHidden: Record<string, boolean> = {
  'customerCode': false,
  'destinID': false,
  'cardNumber': false
}


const initSort: Array<SortByElem> = [{ id: 'expireAt', desc: true }]
export default Cards
