import { FunctionComponent } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogContent, DialogContentText } from '@mui/material'


type Props = {
  title: string
  open: boolean
  cancelLabel: string
  confirmLabel: string
  handleLaunch: () => void
  handleClose: () => void
  children?: React.ReactNode
}

const ConfirmChangeStatusDialog: FunctionComponent<Props> = (
  {
    cancelLabel,
    confirmLabel,
    title,
    open,
    handleClose,
    handleLaunch,
    children
  }) => {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        overflowY: 'visible'
      }}
      maxWidth='md'
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose}>
          {cancelLabel}
        </Button>
        <Button variant='contained' onClick={handleLaunch} color='secondary'>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmChangeStatusDialog