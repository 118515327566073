import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { FunctionComponent, ReactElement, useContext } from 'react'
import { FilterStore } from './filterStore'
import { Table } from '@tanstack/react-table'
import { FilterProps } from '@tanstack/table-core'

type FilterDialogProps<T extends object> = {
  onClose: () => void
  show: boolean
  table: Table<T>
}

export function FilterDialog(
  {
    onClose,
    show,
    table
  }: FilterDialogProps<any>): ReactElement {

  const {getAllColumns} = table
  const { dispatch } = useContext(FilterStore)
  return (
    <Dialog onClose={() => {
      onClose()
      dispatch({ type: 'resetFilters' })
    }} open={show} maxWidth='xs' fullWidth={true}>
      <DialogTitle>Preferenze</DialogTitle>
      <DialogContent>
        <Grid container direction='column' sx={{marginTop:1}} spacing={1} justifyContent='center'>
          {
            getAllColumns()
              .filter((it) =>
                it.getCanFilter() && it.columnDef.meta?.hideFilter !== true && it.columnDef.meta?.filter !== undefined)
              .map(column => {
                const Filter = column.columnDef.meta?.filter as FunctionComponent<FilterProps<any>>
                return(
                  <Grid item key={`filter_${column.id}`} sx={{
                    width: '100%',
                    display: 'inline-flex',
                    flexDirection: 'column'
                  }}>
                    <Filter column={column}/>
                  </Grid>
                )
              })
           }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color={'inherit'} onClick={() => {
          onClose()
          dispatch({ type: 'resetFilters' })
        }}>
          Annulla
        </Button>
        <Button variant='contained' color={'secondary'} onClick={() => {
          onClose()
          dispatch({ type: 'confirmFilters' })
        }}>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  )
}
