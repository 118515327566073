import { LinearProgress, Paper } from '@mui/material'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { FilterStoreMode, FilterStoreProvider } from './filters/filterStore'
import { useTableAfterMiddleware } from './hooks/useTableAfterMiddleware'
import { Breakpoint } from '@mui/material/styles'
import {
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  Table,
  useReactTable
} from '@tanstack/react-table'
import { TableToolbar, ToolbarAction } from './TableToolbar'
import { ColumnSort, VisibilityState } from '@tanstack/table-core'
import { MobileTablePagination, TablePagination } from './paginations'
import { FilterChipBar } from './filters/chipbar'


interface Props<T extends object = {}> {
  id: string
  name?: string
  columns: ColumnDef<T>[]
  data?: T[]
  updatedAt: number
  initPageIndex: number
  filterStoreMode?: FilterStoreMode
  initFilter?: Array<ColumnFilter>
  initSortBy?: Array<ColumnSort>
  initHiddenColumns?: Record<string, boolean>
  actions?: Array<ToolbarAction<T>>
  onDelete?: (rows: Row<T>[]) => void
  forceIsLoading?: boolean,
  mobilePaginationBreakpoint?: Breakpoint | number,
  children: (table: Table<T>) => ReactElement
}

const fakeOnRefresh = () => {
}
export const FiltrableClientTable = <T extends object>(
  {
    columns,
    onDelete,
    name,
    actions,
    data,
    updatedAt,
    initSortBy = [],
    children,
    initFilter = [],
    initHiddenColumns = {},
    filterStoreMode = FilterStoreMode.DEFAULT,
    forceIsLoading = false,
    initPageIndex,
    mobilePaginationBreakpoint,
    id
  }: Props<T>): ReactElement => {

  const [initializedFilters, setInitializedFilters] = useState(false)

  const [filters, setFilters] = useState<ColumnFiltersState>(initFilter)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initHiddenColumns)
  const defaultData = useMemo(() => [], [])

  const table = useReactTable({
    data: data ?? defaultData,
    columns: columns,
    initialState: {
      sorting: initSortBy,
      pagination: {
        pageIndex: initPageIndex,
        pageSize: 10
      }
    },
    state: {
      columnFilters: filters,
      columnVisibility,
      rowSelection
    },
    //selection
    onRowSelectionChange: setRowSelection,
    // pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    //filtering
    enableFilters: true,
    onColumnFiltersChange: setFilters,
    //sorting
    enableSorting: true,
    meta: {
      onRefresh: fakeOnRefresh
    },
    //hiding
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })


  const tableAfterMiddleware = useTableAfterMiddleware({
    setHiddenColumns: table.setColumnVisibility,
    setAllFilters: table.setColumnFilters
  })

  useEffect(() => {
    if (data !== undefined) {
      setInitializedFilters( updatedAt === -1)
    }

  },[data, updatedAt])

  return (
    <Paper style={{ maxWidth: '100%' }}>
      <FilterStoreProvider storeKey={id}
                           initFilters={initFilter}
                           filterStoreMode={filterStoreMode}
                           initHide={initHiddenColumns}
                           initializedFilters={initializedFilters}
                           setFilterColumn={tableAfterMiddleware.setFilterColumns}
                           setInitializedFilters={setInitializedFilters}
                           tableAfterMiddleware={tableAfterMiddleware.tableAfterMiddleware}>
        <TableToolbar table={table} {...{ onDelete }} actions={actions} name={name} onRefresh={fakeOnRefresh} />
        <FilterChipBar<T> table={table} />
      </FilterStoreProvider>
      {children(table)}
      {forceIsLoading === true && <LinearProgress color='secondary' />}
      {mobilePaginationBreakpoint === undefined ?
        <TablePagination table={table} rowCount={table.getPrePaginationRowModel().rows.length} /> :
        <MobileTablePagination id={id} table={table} breakpoint={mobilePaginationBreakpoint}
                               rowCount={table.getPrePaginationRowModel().rows.length} />}
    </Paper>)
}