import { styled } from '@mui/material/styles'

export const ReportState = styled('div', {
  shouldForwardProp: (prop) => prop !== 'state',
})<{
  state: number
}>(({ state }) => ({
  borderRadius: '5px',
  display: 'inline-block',
  padding: '0px 10px',
  fontWeight: 600,
  textTransform: 'uppercase',
  fontSize: '0.88rem',
  ...(state === 1 && {
    color: '#C67943',
    border: '1px solid #C67943'
  }),
  ...((state === 3 || state === 2) && {
    color: '#019938',
    border: '1px solid #019938'
  })
}))