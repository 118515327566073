import axios from 'axios'
import { Card } from '../models/Card'
import { DateTime } from 'luxon'

export const getCards = async () => {
  try {
    const response = await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/list`)
    let list = response.data.cards as Card[]

    for (let i = 0; i < list.length; i++) {
      const expireDate = DateTime.fromFormat(`${list[i].expireAt} 23:59:59`, 'yyyy-MM-dd HH:mm:ss', { zone: 'Europe/Rome' })
      if (list[i].state === 2 && list[i].isState === 1) {
        list[i].state = 6
      } else if (list[i].printStatus === 1) {
        list[i].state = 5
      } else if (DateTime.now() > expireDate) {
        list[i].state = 4
      }
    }
    return list
  } catch (e) {
    throw e
  }
}
export const freeze = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/freeze`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}
export const unfreeze = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/unfreeze`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}
export const activate = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/activate`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}
export const lock = async ({ pan }: {
  pan: string
}) => {
  try {
    await
      axios.post(`${process.env.REACT_APP_MYPAGOCO_HOST}/cards/lock`, {
        pan: pan
      })
  } catch (e) {
    throw e
  }
}