import {AuthState} from "@aws-amplify/ui-components";
import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./index";

const initialState: CognitoState = {
    authState: undefined,
    exp: 0,
    groups: new Set<string>()
}

export interface CognitoState {
    authState?: AuthState
    exp: number
    groups: Set<string>
}

type UpdateGroupsPaylod = {
    groups: Set<string>
    exp: number
}

export const cognitoSlice = createSlice({
    name: 'cognito',
    initialState,
    reducers: {
        signOut: () => {
            return initialState
        },
        updateGroups: (state, action: PayloadAction<UpdateGroupsPaylod>) => {
            const payload = action.payload
            if (state.exp !== payload.exp) {
                state.exp = payload.exp
                state.groups = payload.groups
            }
        },
        updateAll: (state, action: PayloadAction<CognitoState>) => {
            const payload = action.payload
            state.authState = payload.authState
            state.exp = payload.exp
            state.groups = payload.groups
        }
    }
})

export type SessionPermissions = {
    hasMyPagoCO: boolean
}

export const permissionSelector = createSelector(
    (state: RootState) => state.cognito.groups,
    (state) => {
        return {
            hasMyPagoCO: state.has("mypagoco:*")
        } as SessionPermissions
    })

export const { updateGroups, signOut, updateAll} = cognitoSlice.actions

export default cognitoSlice.reducer
