import React, { FunctionComponent } from 'react'
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { getTransactions } from './usecases/transaction'
import { WithHelperText } from '../../components/Table/filters/columns/withHelperText'
import { Transaction } from './models/Transaction'
import { Link as RouterLink } from 'react-router-dom'
import { FiltableServerTable } from '../../components/Table/FiltableServerTable'
import { MobileBody } from '../../components/Table/MobileBody'
import TransactionMobileBox from './TransactionMobileBox'
import { ColumnDef, ColumnFilter, ColumnSort } from '@tanstack/react-table'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import { DatesFilter } from '../../components/Table/filters/columns/DatesFilter'
import { FilterStoreMode } from '../../components/Table/filters/filterStore'
import { CurrencyCell, DatetimeCell, RenderedCell } from '../../components/Table/cells'
import { getDatetimeChipContent } from '../../components/Table/filters/chipbar'

const  columns: ColumnDef<Transaction>[] = [
  {
    header: 'Data e ora',
    accessorKey: 'insertDatetime',
    meta: {
      align: 'left',
      filter: DatesFilter,
      getChipContent: getDatetimeChipContent
    },
    cell: DatetimeCell,
    enableColumnFilter: true, //true,
    enableSorting: true
  },
  {
    header: 'Pan',
    accessorKey: 'pan',
    cell: props => {
      const to = `/cards/${props.getValue()}`
      return (<Link
        color='secondary'
        to={to}

        component={RouterLink}>
        {props.getValue() as any}
      </Link>)
    },
    meta: {
      align: 'left',
      filter: WithHelperText('Per filtrare più pan, inseriscili separandoli con una virgola')

    },
    enableColumnFilter: true, //True,
    enableSorting: false
  },
  {
    header: 'Numero transazione',
    accessorKey: 'transactionNumber',
    meta: {
      align: 'right'
    },
    minSize: 30,
    cell: RenderedCell,
    enableSorting: false

  },
  {
    header: 'Prezzo',
    meta: {
      align: 'right'
    },
    accessorKey: 'unitPrice',
    cell: CurrencyCell,
    enableSorting: false,
  },
  {
    header: 'Litri',
    meta: {
      align: 'right'
    },
    accessorKey: 'unit',
    enableSorting: false,
    cell: RenderedCell,
  },
  {
    header: 'Importo',
    accessorKey: 'amount',
    meta: {
      align: 'right'
    },
    cell: CurrencyCell,
    enableSorting: false

  },
  {
    header: 'Prodotto',
    accessorKey: 'productDescription',
    meta: {
      align: 'left'
    },
    cell: RenderedCell,
    enableSorting: false

  },
  {
    header: 'Punto vendita',
    accessorKey: 'shopDescription',
    meta: {
      align: 'left'
    },
    cell: RenderedCell,
    enableSorting: false

  },
  {
    header: 'Km',
    accessorKey: 'km',
    meta: {
      align: 'right'
    },
    cell: RenderedCell,
    enableSorting: false

  }
] as ColumnDef<Transaction>[]


const toolbarActions: ToolbarAction<Transaction>[] = [(instance, onRefresh) => {
  return <RefreshButton onRefresh={onRefresh} />
}, (instance) => {
  return <ColumnsButton table={instance} />
}, (instance) => {
  return <FilterButton table={instance} />
}]

const initSortBy: ColumnSort[] = [{ id: 'insertDatetime', desc: true }]
const initFilter: ColumnFilter[] = [{
  'id': 'insertDatetime', value: [DateTime.local().minus({ month: 6 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), DateTime.local().set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0
  })]
}]

const section = {
  marginTop: 2,
  marginBottom: 3
}

const Transactions: FunctionComponent = () => {

  return (
    <>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Transazioni
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <FiltableServerTable<Transaction>
            name={'Transazioni'}
            initHiddenColumns={{}}
            columns={ columns}
            handler={getTransactions}
            initPageIndex={0}
            actions={toolbarActions}
            initSortBy={initSortBy}
            initFilter={initFilter}
            filterStoreMode={FilterStoreMode.BACKUP}
            mobilePaginationBreakpoint={'sm'}
            id={'transactions'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={false} initSortBy={initSortBy} boxMode={row => {
                return (<TransactionMobileBox transactionRow={row} />)
              }} breakpoint={'sm'} />}
          </FiltableServerTable>
        </Grid>
      </Grid>
    </>
  )
}


export default Transactions
