import React, { FunctionComponent } from 'react'
import { Report } from './models/Report'
import { DateTime } from 'luxon'
import { ReportState } from './ReportState'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from '@tanstack/react-table'

const textifyReturnType = (returnType: number) => {
  let value = 'PDF/XLSX'
  switch (returnType) {
    case 1:
      value = 'PDF'
      break
    case 2:
      value = 'XLSX'
      break
  }
  return value
}

const textifyState = (state: number) => {
  let value = 'IN LAVORAZIONE'
  switch (state) {
    case 3:
      value = 'INVIATO'
      break
    case 2:
      value = 'CREATO'
      break
  }
  return value
}

const ReportMobileBox: FunctionComponent<{ reportRow: Row<Report> }> = ({ reportRow }) => {
  return (
    <div className='mobileBox'>
      <div className='mobileDescriptions'>
        <div className={'mobileTitle'}>
          <span style={{ marginRight: '0.3rem' }}>{reportRow.original.taskID === 0? 'manuale': 'schedulato'}</span>
          <span style={{ marginRight: '0.3rem' }}>{textifyReturnType(reportRow.original.returnType)}</span>
          {reportRow.original.sendingIsEnabled && reportRow.original.sendingEmail ?
            <span style={ { marginLeft: '0.3rem' }}>
              <FontAwesomeIcon icon={faEnvelope} className={'imgFa'}  style={{
              fontSize: '1rem'
            }} /></span>
            : null}
        </div>
        <div className='subRow'>
          <span style={{ marginRight: '0.3rem' }}>{DateTime.fromISO(reportRow.original.createdAt).setZone('Europe/Rome')
            .toFormat('dd/MM/yyyy, HH:mm:ss')} </span>
        </div>
        <div className='subRow'>
          <span style={{ marginRight: '0.3rem' }}>{DateTime.fromISO(reportRow.original.from).setZone('Europe/Rome')
            .toFormat('dd/MM/yyyy')} </span>
          {DateTime.fromISO(reportRow.original.to).setZone('Europe/Rome')
            .toFormat('dd/MM/yyyy')}
        </div>
        <div className='subRow'>
          {reportRow.original.businessName}
        </div>
        {reportRow.original.sendingIsEnabled && reportRow.original.sendingEmail ?
          <div className='subRow'>
              <span style={{ marginRight: '0.3rem' }}>
                {reportRow.original.sendingEmail}
              </span>
          </div>
          : null}
      </div>
      <ReportState state={reportRow.original.reportStateID}>
        {textifyState(reportRow.original.reportStateID)}
      </ReportState>
    </div>)
}

export default ReportMobileBox