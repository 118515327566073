import React, { FunctionComponent } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import Typography from '@mui/material/Typography'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { getDevices } from '../usecases/devices'
import { getCookies } from '../../../utils/cookies'
import { fillEmpty } from '../../../utils/utils'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { getUserAttributes, globalSignout } from '../usecases/userAttributes'
import { useQuery } from 'react-query'
import { updateSnackbar } from '../../../redux/snackbarStore'
import useAppDispatch from '../../../hooks/useAppDispatch'

const section = {
  marginTop: 2,
  marginBottom: 3
}

export const Info: FunctionComponent = () => {
  const { user } = useAuthenticator(context => [context.user])
  const navigate = useNavigate()

  const forgetSignOut = async () => {
    try {
      let result = await getDevices(user)
      if (result !== undefined) {
        for (let tr of result) {
          user.forgetSpecificDevice(tr.key, {
            onSuccess: success => {
              console.log(success)
            },
            onFailure: err => {
              console.log(err)
            }
          })
        }
      }
      await globalSignout(user)
      for (let cookie of getCookies('CognitoIdentityServiceProvider.')) {
        cookie.expire()
      }
      for (let cookie of getCookies('amplify')) {
        cookie.expire()
      }
      for (let cookie of getCookies('_ga')) {
        cookie.expire()
      }
      navigate(0)
    } catch (e) {

    }
  }
  const dispatch = useAppDispatch()

  const { data : attributes} = useQuery(['userAttribute', user], () => getUserAttributes(user), {
    onError: _ => {
      dispatch(updateSnackbar({
        message: 'Errore di rete',
        variant: 'error'
      }))
    },
    refetchOnWindowFocus: false
  })

  return (
    <div>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color={'secondary'}
          component={RouterLink}
          underline="hover"
          to={'/'}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Profilo
        </Typography>
        <Typography color='textPrimary'>
          Informazioni generali
        </Typography>
      </Breadcrumbs>
      <Typography variant={'h4'}>
        Informazioni generali
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <Typography variant={'h5'}>
            Dati utente
          </Typography>
          <TableContainer component={Paper} style={{ maxWidth: '700px', marginTop: '16px' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    <Typography variant={'body2'}>
                      ID
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography variant={'body1'}>
                      {fillEmpty(attributes?.sub)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    <Typography variant={'body2'}>
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography variant={'body1'}>
                      {fillEmpty(attributes?.email)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    <Typography variant={'body2'}>
                      Nome
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography variant={'body1'}>
                      {fillEmpty(attributes?.name)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    <Typography variant={'body2'}>
                      Cognome
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography variant={'body1'}>
                      {fillEmpty(attributes?.familyName)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sx={section}>
          <Typography variant={'h4'}>
            Scollega ovunque
          </Typography>
          <Typography variant={'body1'}>
            In questo modo esci da ogni applicazione pagoCO in cui tu abbia acceduto.
          </Typography>
          <Button onClick={forgetSignOut} variant={'outlined'} color={'secondary'}>Scollegati</Button>
        </Grid>
      </Grid>
    </div>)

}

export default Info