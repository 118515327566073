import { ReactElement, useContext } from 'react'
import { DateTime } from 'luxon'
import { FilterStore } from './filterStore'
import { styled } from '@mui/material/styles'
import { Chip as MuiChip, ChipProps } from '@mui/material'
import { ColumnDef, Table } from '@tanstack/react-table'

export const getBooleanChipContent: (filterValue: any) => string | ReactElement = (filterValue: any) => {
  return filterValue === 'true' ? 'si' : 'no'
}

export const getDatetimeChipContent: (filterValue: any) => string | ReactElement = (filterValue: any) => {
  const min = DateTime.fromISO(filterValue[0])
  const max = DateTime.fromISO(filterValue[1])
  return min ? (max ? `${min.setZone('Europe/Rome')
    .toFormat('dd/MM/yyyy')} - ${max.setZone('Europe/Rome')
    .toFormat('dd/MM/yyyy')}` : `>=${min.setZone('Europe/Rome')
    .toFormat('dd/MM/yyyy')}`) : `<=${max!.setZone('Europe/Rome')
    .toFormat('dd/MM/yyyy')}`
}

type FilterChipBarProps<T extends object> = {
  table: Table<T>
}

const getFilterValue = (columnDef: ColumnDef<any>, id: any) => {
  if (columnDef.meta?.getChipContent !== undefined) {
    return columnDef.meta.getChipContent(id)
  }
  return columnDef.meta?.keyValues === undefined ? id : columnDef.meta.keyValues[id]
}

export const FilterChipBar = <T extends object>({ table }: FilterChipBarProps<T>) => {

  const { getAllColumns } = table
  const { state: { currentFilters, originalFilters }, dispatch } = useContext(FilterStore)

  return Object.keys(currentFilters).length > 0 ? (
      <div style={{
        margin: '0.875rem 1rem'
      }}>
        {getAllColumns().filter(value => currentFilters[value.id] && value.getCanFilter()).map((column) => {
          return (
            <Chip
              key={column.id}
              color={'secondary'}
              label={
                <>
                  <ChipLabel>{column.columnDef.header as string}: </ChipLabel>
                  <ChipContent>{getFilterValue(column.columnDef, currentFilters[column.id])}</ChipContent>
                </>
              }
              onDelete={column.columnDef.meta?.hideFilter === true ? undefined :
                () => {
                  dispatch({ type: 'dropFilter', payload: { id: column.id } })
                  column.setFilterValue(originalFilters[column.id])
                }}
              variant='outlined'
            />
          )
        })}
      </div>
    ) :
    <div style={{
      margin: '0.875rem 1rem'
    }}>
      <div style={{ height: '32px' }} />
    </div>
}

const Chip = styled(MuiChip)<ChipProps>({
  marginRight: 4
})
const ChipLabel = styled('span')({
  marginRight: 5
})
const ChipContent = styled('span')({
  fontWeight: 600
})