import { applyMiddleware, combineReducers, createStore, Middleware } from '@reduxjs/toolkit'
import ccstore from './cognitoStore'
import snackbarStore from './snackbarStore'
import { Auth } from 'aws-amplify'
import guiStore from './guiStore'

const reducer = combineReducers({
  cognito: ccstore,
  snackbar: snackbarStore,
  gui: guiStore
})

export const signOutMiddleware: Middleware<{}, // Most middleware do not modify the dispatch return value
  Reducer> = storeApi => next => action => {
  const state = storeApi.getState()
  if (state.cognito.authState !== undefined && action.type === 'cognito/signOut') {
    Auth.signOut().catch(err => {
      console.log(err)
    })
  }
  next(action)
}

export const store = createStore(reducer, applyMiddleware(signOutMiddleware))
type Reducer = ReturnType<typeof reducer>
export type RootState = ReturnType<typeof store.getState>


export type AppDispatch = typeof store.dispatch

