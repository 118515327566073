import React, { FunctionComponent, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Link as RouterLink, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import ReportSummary from './components/ReportSummary'
import Transactions from './components/Transactions'


const useReportParams = () => {
  let { id } = useParams() as { id: string }
  return useMemo<{
    id: string
  }>(() => {
    return {
      id: id
    }
  }, [id])
}

const section = {
  marginTop: 2,
  marginBottom: 3
}

const Report: FunctionComponent = () => {
  let { id } = useReportParams()

  return (
    <div>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline='hover'
          component={RouterLink}>
          Home
        </Link>
        <Link
          color='inherit'
          to='/report'
          underline='hover'
          component={RouterLink}>
          Report
        </Link>
        <Typography color='textPrimary'>
          {id}
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={section}>
          <ReportSummary id={id} />
        </Grid>
        <Grid item xs={12} sx={section}>
          <Transactions id={id} ></Transactions>
        </Grid>
      </Grid>
    </div>
  )
}


export default Report
