import React, { FunctionComponent, useMemo } from 'react'
import RefreshButton from '../../components/Table/actions/RefreshButton'
import { getTasks } from './usecases/tasks'
import { Task } from './models/Task'
import TaskMobileBox from './TaskMobileBox'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import { ColumnDef, ColumnFilter, ColumnSort } from '@tanstack/react-table'
import { ToolbarAction } from '../../components/Table/TableToolbar'
import { ColumnsButton } from '../../components/Table/actions/ColumnsButton'
import { FilterStoreMode } from '../../components/Table/filters/filterStore'
import { MobileBody } from '../../components/Table/MobileBody'
import { FiltableServerTable } from '../../components/Table/FiltableServerTable'
import { BooleanCell, DatetimeCell, RenderedCell } from '../../components/Table/cells'
import CreateTaskButton from './components/CreateTaskButton'
import { FilterButton } from '../../components/Table/actions/FilterButton'
import { withActions } from '../../components/Table/helpers'
import { SelectKeyValueFilter } from '../../components/Table/filters/columns/SelectKeyValueFilter'


const toolbarActions: ToolbarAction<Task>[] = [(instance, onRefresh) => {
  return <RefreshButton onRefresh={onRefresh} />
}, (instance) => {
  return <ColumnsButton table={instance} />
}, (instance) => {
  return <FilterButton table={instance} />
},(instance, onRefresh) => {
  return <CreateTaskButton onRefresh={onRefresh}/>
}]

const initSortBy: ColumnSort[] = [{ id: 'createdAt', desc: true }]
const initFilter: ColumnFilter[] = []

const Tasks: FunctionComponent = () => {
  const navigate = useNavigate()

  const columns =useMemo(() => {
    const col:  ColumnDef<Task>[] = [
      {
        header: 'Frequenza',
        accessorKey: 'frequency',
        cell: props => {
          let value = "giornaliero"
          switch (props.getValue() as string) {
            case "week":
              value = "settimanale"
              break
            case "month":
              value = "mensile"
              break
          }
          return value
        },
        filterFn: 'equals',
        meta: {
          keyValues: {
            'day': 'giornaliero',
            'week': 'settimanale',
            'month': 'mensile'
          },
          filter: SelectKeyValueFilter,
          isKeyIndexNumeric: false
        },
        enableSorting: false,
        enableColumnFilter: true
      },
      {
        header: 'Creata',
        accessorKey: 'createdAt',
        enableColumnFilter: false,
        cell: DatetimeCell
      },
      {
        header: 'Descrizione',
        cell: RenderedCell,
        accessorKey: 'description',
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        header: 'Codice Cliente',
        accessorKey: 'customerCode',
        cell: RenderedCell,
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        header: 'Ragione sociale',
        accessorKey: 'businessName',
        cell: RenderedCell,
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        header: 'Tipo di file',
        accessorKey: 'returnType',
        cell: props => {
          let value = "PDF/XLSX"
          switch (props.getValue() as number) {
            case 1:
              value = "PDF"
              break
            case 2:
              value = "XLSX"
              break
          }
          return value
        },
        filterFn: 'equals',
        meta: {
          keyValues: {
            0: 'PDF/XLSX',
            1: 'PDF',
            2: 'XLSX'
          },
          filter: SelectKeyValueFilter,
          isKeyIndexNumeric: true
        },
        enableSorting: false,
        enableColumnFilter: true
      },
      {
        header: 'Inoltro',
        accessorKey: 'sendingIsEnabled',
        cell: BooleanCell,
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        header: 'Email Opzionale',
        accessorKey: 'sendingIsOptEmail',
        cell: RenderedCell,
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        header: 'Attivo',
        accessorKey: 'isActive',
        cell: BooleanCell,
        enableSorting: false,
        enableColumnFilter: false
      }
    ]
   const columnsWithAction: ColumnDef<Task>[] = withActions([
      {
        disabled: _ => false,
        label: 'Apri',
        onClick: (row) => {
          navigate(`/reportistica-regole/${row.original.id}`)
        }
      }],col)
    return columnsWithAction
  }, [navigate])

  return (
    <>
      <Breadcrumbs sx={{ marginBottom: 3, marginBlock: '0.83em' }}>
        <Link
          color='inherit'
          to='/'
          underline="hover"
          component={RouterLink}>
          Home
        </Link>
        <Typography color='textPrimary'>
          Regole reportistica
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{
          marginTop: 2,
          marginBottom: 3
        }}>
          <FiltableServerTable<Task>
            name={'Regole'}
            initHiddenColumns={initHide}
            columns={ columns}
            handler={getTasks}
            initPageIndex={0}
            actions={toolbarActions}
            initSortBy={initSortBy}
            initFilter={initFilter}
            filterStoreMode={FilterStoreMode.BACKUP}
            mobilePaginationBreakpoint={'sm'}
            id={'tasks'}>
            {instance =>
              <MobileBody table={instance} hasRowActions={true} initSortBy={initSortBy} boxMode={row => {
                return (<TaskMobileBox taskRow={row} />)
              }} breakpoint={'sm'} />}
          </FiltableServerTable>
        </Grid>
      </Grid>
    </>
  )
}

const initHide: Record<string, boolean> = {
  'customerCode': false
}



export default Tasks
